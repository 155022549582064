import styled from "styled-components";

export const Styles = styled.div`
  footer {
    padding: 22px 40px 22px 40px;
    margin-top: 60px;
    @media screen and (min-width: 768px) {
      position: relative;
      right: 0;
      left: 0;
      bottom: 0;
      margin-top: 0;
      padding: 22px 40px 22px 40px;
    }
    div.link-item {
      float: left;
      padding: 0px 40px 0px 0px;
      p {
        font-size: 14px;
        text-align: left;
        color: black;
      }
    }
    div.copyright {
      float: right;
      padding: 0px 0px 0px 0px;
      p {
        font-size: 14px;
        text-align: right;
        color: black;
      }
    }
  }
`;