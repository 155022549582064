import { apiGetToken, apiPostToken, apiPutToken } from './index'

async function get(merchantId) {
  try {
    const res = await apiGetToken(`merchants/${merchantId}`);
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

async function getMerchant(merchantId) {
  try {
    const res = await apiPostToken("merchants/get", {merchant_id: merchantId})
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}


async function create(data, email=null, token=null) {
  try {
    const res = await apiPostToken("merchants", data, email, token);
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

async function update(merchantId, data) {
  try {
    const res = await apiPutToken(`merchants/${merchantId}`, data);
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

async function generateWallet(data) {
  try {
    const res = await apiPostToken("merchants/generate_wallet", data);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getUserMerchants(user_id) {
  try {
    const res = await apiGetToken(`/users/${user_id}/user_merchants`);
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

async function selectMerchant(session_id, merchant_id) {
  try {
    let params = { merchant_select: { session_id: session_id, merchant_id: merchant_id } }
    const res = await apiPostToken(`/user_merchants/select`, params);
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

async function createMerchantAccess(id, merchant_code) {
  try {
    let params = { id: id, merchant_code: merchant_code }
    const res = await apiPostToken(`/v1/access/${id}`, params);
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

export {
  get,
  getMerchant,
  create,
  update,
  generateWallet,
  createMerchantAccess,
  getUserMerchants,
  selectMerchant,
}
