import { apiGet } from './index'

async function getAll() {
  try {
    const res = await apiGet("stores");
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

export {
  getAll,
}
