import { apiPost } from './index'

async function paymentIntent(data) {
  try {

    return Promise.resolve({})
  } catch(error) {
    return Promise.reject(error)
  }
}

export {
  paymentIntent,
}
