import React from 'react';
import {Link, useLocation} from "react-router-dom";

const CheckinSidebar = () => {
  const location = useLocation()

  return (
    <div className="side-nav-wrap">
      <div className="side-nav">
        <nav>
          <li>
            <Link
              className={location.pathname === "/bank/store/schedule" ? "active" : ""}
              to="/bank/store/schedule">
              Schedule
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname === "/bank/store/events" ? "active" : ""}
              to="/bank/store/events">
              Events
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname === "/bank/store/rsvp" ? "active" : ""}
              to="/bank/store/rsvp">
              Reservations
            </Link>
          </li>
          {/* <li>
            <Link
              className={location.pathname === "/bank/store/identity" ? "active" : ""}
              to="/bank/store/identity">
              ID Verification
            </Link>
          </li> */}
          {/* <li>
            <Link
              className={location.pathname === "/bank/store/providers" ? "active" : ""}
              to="/bank/store/providers">
              Providers
            </Link>
          </li> */}
        </nav>
      </div>
    </div>
  );
};

export default CheckinSidebar;
