import {uploadFile} from 'react-s3';
import siteConfig from "../../config/site.config";
import AWS from "aws-sdk";


export const uploadFileApi = async (file, filename, {onProgress, onSuccess, onError}) => {
  AWS.config.update({
    accessKeyId: siteConfig.s3Config.accessKeyId,
    secretAccessKey: siteConfig.s3Config.secretAccessKey,
    region: siteConfig.s3Config.region,
    bucketName: siteConfig.s3Config.bucketName
  });

  const S3 = new AWS.S3();

  const objParams = {
    Bucket: siteConfig.s3Config.bucketName,
    ACL: "public-read",
    Key: "uploaded_event_image/" +filename,
    Body: file,
    ContentType: file.type
  }

  S3.upload(objParams)
    .on("httpUploadProgress", function({ loaded, total }) {
      onProgress(
        {
          percent: Math.round((loaded / total) * 100)
        },
        file
      );
    })
    .send(function(err, data) {
      if (err) {
        onError(err);
      } else {
        onSuccess(data);
      }
    });

}
