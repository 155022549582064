import React, {useEffect, useState} from 'react';
import StoreLayout from "../../../../layouts/store";
import {Input} from "antd";
// import ProfileSidebar from "../profile/sidebar";
import ItemsSidebar from "./sidebar";
import {useSetting} from "../../../../provider/setting";
import siteConfig from "../../../../config/site.config";
import {merchantCreateAccessApi} from "../../../../api/api";

const StoreQrPage = () => {
  const [setting] = useSetting();
  const [qrcode, setQrcode] = useState('')
  const [accessQrCode, setAccessQrCode] = useState('')

  const generateAccess = async () => {
    const mid = setting?.auth?.signin_merchant_id
    const code = setting?.auth?.signin_merchant_code
    const { data } = merchantCreateAccessApi(mid, code);
    const data_str = `${siteConfig.checkoutUrl}/${data?.access?.['access_token']}`;
    const qr_src = "https://chart.googleapis.com/chart?cht=qr&chs=200x200&chld=H7C0&chl=" + data_str;
    setAccessQrCode(qr_src)
  }

  useEffect(()=>{
    if (setting?.auth?.signin_merchant_name) {
      generateAccess();
      const data = [
        "VEEV-Order",
        1,
        setting?.auth?.signin_merchant_name
      ]
      const data_str = data.join("|||");
      const qr_src = "https://chart.googleapis.com/chart?cht=qr&chs=200x200&chld=H7C0&chl=" + data_str;
      setQrcode(qr_src)
    }
  },[setting?.auth])

  return (
    <StoreLayout>
      <div className="row">
        <div className="col col-lg-3">
          <ItemsSidebar/>
        </div>
        <div className='col col-lg-9'>
        <h4>StoreLink QR</h4>
          <div className="card card-body mb-5">
            <img
              id='client-barcode'
              width={200}
              height={200}
              src={qrcode}
              alt="generated QR Code" />
          </div>
          <div>
          <h4>StoreLink URL</h4>
            <p id="storelink url" name="storelink url" label="storelink url">
              <Input
                style={{margin: 0}}
                disabled
                placeholder="StoreLink URL"
                size="large"/>
            </p>
            Copy and paste StoreLink URL in TikTok, Instagram, YouTube, Twitter, X, Facebook, Pinterest, Blogs etc
          </div>
          {/* <h4>Access Portal</h4>
          <div className="card card-body mb-5">
            <div style={{display: "flex", gridGap: 32, alignItems: "center"}}>
              <div style={{width: 200, height: 200, background: "#f0f0f0"}}>
                {
                  accessQrCode && (
                    <img
                      id='client-barcode'
                      width={200}
                      height={200}
                      src={accessQrCode}
                      alt="generated QR Code" />
                  )
                }
              </div>
              <button onClick={generateAccess} className="btn btn-primary mt-3">
                Generate New Access Code
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </StoreLayout>
  );
};

export default StoreQrPage;