import { apiPostToken, apiGetToken } from './index'

async function getItemsAll() {
  try {
    const res = await apiPostToken("apiws/get_items_all", {});
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

async function getCrmTrxItemList(data) {
  try {
    const res = await apiPostToken("apiws/get_crm_trx_item_list", data);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getMerchantGroups(merchantId) {
  try {
    const res = await apiGetToken(`merchants/${merchantId}/groups`);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}


async function createBusinessLocation(merchant_id, data) {
  try {
      // email = getCookie("current_user_email");
      // token = getCookie("current_ident_token");
      // const res = await fetch(`merchants/${merchantId}/business_locations`, data);
      // return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}
    

export {
  getItemsAll,
  getCrmTrxItemList,
  getMerchantGroups,
  createBusinessLocation,
}
