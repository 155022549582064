import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetting } from "../../provider/setting";
import { setCookie } from "../../service/cookie";

const Logout = () => {
  const [, dispatch] = useSetting();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("logout");
    dispatch({ type: "SET", settingName: "auth", settingData: null });
    setCookie("signin_session_id", '', 0);
    setCookie("signin_merchant_code", '', 0);
    setCookie("signin_merchant_id", '', 0);
    setCookie("signin_merchant_user_id", '', 0);
    setCookie("signin_current_user_id", '', 0);
    setCookie("signin_merchant_name", '', 0);
    setCookie("current_ident_token", '', 0);
    setCookie("current_user_email", '', 0);
    setCookie("selected_merchant_address", '', 0);
    setCookie("current_merchant_wallet_address", '', 0);
    setCookie("selected_merchant_bool_cannabis_merchant", '', 0);
    setCookie("veev_session_id", '', 0);
    setCookie("veev_current_user_id", '', 0);
    setCookie("veev_current_user_email", '', 0);
    setCookie("veev_current_user_ident_token", '', 0);
    navigate("/signin");
  }, []);
  return <div></div>;
};
export default Logout;
