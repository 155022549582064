import React, {useEffect, useState} from 'react';
import StoreLayout from "../../../../layouts/store";
import ItemsSidebar from "./sidebar";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useSetting} from "../../../../provider/setting";
import {getCategoriesApi, updateCategoryApi} from "../../../../api/api";
import {NotificationManager} from "react-notifications";
import SubmitButton from "../components/SubmitButton";

const StoreCategoryEditPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [setting] = useSetting()
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");

  const onsubmit = () => {
    console.log(setting?.auth?.signin_merchant_code)
    updateCategoryApi(setting?.auth?.signin_merchant_code, id, {name, description})
      .then(()=>{
        NotificationManager.success("Successfully created a category!", "Success", 3000)
        navigate('/bank/store/categories')
      })
      .catch((e)=>{
        NotificationManager.error("An error was created. try again later!", "Error", 3000)
      })
  }


  useEffect(()=>{
    console.log()
    const i = searchParams.get("id");
    setId(i);
    if(!!i && setting?.auth?.signin_merchant_code) {
      getCategoriesApi(setting?.auth?.signin_merchant_code)
        .then(({data}) => {
          data.forEach((item) => {
            if (item.id.toString() === i) {
              setName(item.name);
              setDescription(item.description)
            }
          })
        })
        .catch((e)=>console.warn(e))
    }
  }, [setting])


  return (
    <StoreLayout>
      <div className="row">
        <div className="col col-lg-3">
          <ItemsSidebar/>
        </div>
        <div className="col col-lg-9">
          <h4 className="mb-4">Edit Category</h4>

          <div className="row">
            <div className="col col-lg-12">
              <label htmlFor="name">Category Name*</label>
              <input
                value={name}
                onChange={(e)=>{setName(e.target.value)}}
                name="name"
                id="name"
                className="form-control"
                placeholder="Enter Category Name"
                type="text"/>
            </div>
            <div className="col col-lg-12">
              <label htmlFor="name">Category Description</label>
              <textarea
                value={description}
                onChange={(e)=>{setDescription(e.target.value)}}
                name="description"
                id="description"
                className="form-control"
                rows={4}
                placeholder="Enter Category Description"
                type="text"/>
            </div>
          </div>

          <div className="mt-5">
            <SubmitButton
              disabled={!name}
              onClick={onsubmit}
              type="primary"
              size="large">
              Create Category
            </SubmitButton>
          </div>
        </div>
      </div>
    </StoreLayout>
  );
};

export default StoreCategoryEditPage;
