import axios from "axios";
import siteConfig from "../../config/site.config";
import {getCookie} from "../../service/cookie";

export const api = axios.create({
  baseURL: siteConfig.apiUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
})

export const apiGetHeaders = () => {
  try {
    const email = getCookie("current_user_email");
    const token = getCookie("current_ident_token");

    return {
      headers: {
        'X-User-Email': email,
        'X-User-Token': token
      }
    }
  } catch (e) {
    return {
      headers: {
        'X-User-Email': "",
        'X-User-Token': ""
      }
    }
  }
}


// USERS
export const getUsersApi = async () =>
  api.get(`/api/profiles/all`)


// MERCHANT
export const merchantCreateAccessApi = async (id, merchant_code) =>
  api.post(`/v1/access/${id}`, {id, merchant_code}, apiGetHeaders())
export const getMerchantApi = async (id) =>
  api.get(`merchants/${id}`, apiGetHeaders())
export const updateMerchantApi = async (id, data) =>
  api.put(`merchants/${id}`, data, apiGetHeaders())

// ITEMS
export const getItemsApi = async (merchantCode) =>
  api.get(`merchants/${merchantCode}/items`, apiGetHeaders())
export const createItemApi = async (merchantCode, data) =>
  api.post(`merchants/${merchantCode}/items`, data, apiGetHeaders())
export const deleteItemApi = async (merchantCode, id) =>
  api.delete(`merchants/${merchantCode}/items/${id}`, apiGetHeaders())
export const updateItemApi = async (merchantCode, id, data) =>
  api.put(`merchants/${merchantCode}/items/${id}`, data, apiGetHeaders())

// CATEGORIES
export const getCategoriesApi = async (merchantCode) =>
  api.get(`merchants/${merchantCode}/categories`, apiGetHeaders())
export const deleteCategoryApi = async (merchantCode, id) =>
  api.delete(`merchants/${merchantCode}/categories/${id}`, apiGetHeaders())
export const createCategoryApi = async (merchantCode, data) =>
  api.post(`merchants/${merchantCode}/categories`, data, apiGetHeaders())
export const updateCategoryApi = async (merchantCode, id, data) =>
  api.put(`merchants/${merchantCode}/categories/${id}`, data, apiGetHeaders())

// ADDONS, EXTRAS
export const getAddonsApi = async (merchantCode) =>
  api.get(`merchants/${merchantCode}/menu_extras`, apiGetHeaders())
export const createAddonApi = async (merchantCode, data) =>
  api.post(`merchants/${merchantCode}/menu_extras`, data, apiGetHeaders())
export const deleteAddonApi = async (merchantCode, id) =>
  api.delete(`merchants/${merchantCode}/menu_extras/${id}`, apiGetHeaders())
export const updateAddonApi = async (merchantCode, id, data) =>
  api.put(`merchants/${merchantCode}/menu_extras/${id}`, data, apiGetHeaders())


// EVENTS
export const getEventsApi = async (merchantId) =>
  api.post('/events_all', {param: {merchant_id: merchantId}}, apiGetHeaders())
export const getEventApi = async (params, id) =>
  api.get(`/events/${id}`, {params, ...apiGetHeaders()})
export const createEventApi = async (data) =>
  api.post(`/events`, data, apiGetHeaders())
export const updateEventApi = async (id, data) =>
  api.post(`/events/${id}`, data, apiGetHeaders())
export const deleteEventApi = async (params, id) =>
  api.delete(`/events/${id}`, {params: params, ...apiGetHeaders()} )
