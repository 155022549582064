import React from 'react';
import {Link, useLocation} from "react-router-dom";

const ProfileSidebar = () => {
  const location = useLocation()

  return (
    <div className="side-nav-wrap">
      <div className="side-nav">
        <nav>
          <li>
            <Link
              className={location.pathname === "/bank/transactions/checkout" ? "active" : ""}
              to="/bank/transactions/checkout">
              Recent Checkouts
            </Link>
          </li>
        </nav>
      </div>
    </div>
  );
};

export default ProfileSidebar;
