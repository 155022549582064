import Routes from './router/index'
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import {ConfigProvider} from "antd";
import { SettingProvider } from './provider/setting'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#cc0000',
        },
      }}>
      <NotificationContainer />
      <ToastContainer />
      <SettingProvider>
        <Routes />
      </SettingProvider>
    </ConfigProvider>
  );
}

export default App;
