import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom';
import {NotificationManager} from "react-notifications";
import ActionCable from 'actioncable'
import Header from "../header/headerEmptyPage";
import Footer from "../footer/footerEmptyPage";
import siteConfig from '../../config/site.config';
import {setCookie} from '../../service/cookie';
import {useSetting} from '../../provider/setting';
import {loginWithEmail} from '../../api/auth';
import {getUserMerchants, selectMerchant} from '../../api/merchant';

const Login = () => {
    const [, dispatch] = useSetting()
    const navigate = useNavigate()
    const [qrcode, setQrcode] = useState('')
    const [sessionId, setSessionId] = useState('')
    const [email, setEmail] = useState('')
    const [pwd, setPwd] = useState('')
    const [identToken, setIdentToken] = useState('')
    const [userMerchants, setUserMerchants] = useState([])

    const handleReceivedAuth = (data) => {
        console.log("Received authentication data." + JSON.stringify(data));
        //update the QR code by using new session_Id.
        const session_id = data.session_id;
        const _qrcode = {
            type: "login",
            version: 1,
            properties: {session_id},
        };
        const qr_src =
            "https://chart.googleapis.com/chart?chl=" +
            JSON.stringify(_qrcode) +
            "&chs=200x200&cht=qr&chld=H 7C0";
        setQrcode(qr_src)
        setSessionId(session_id)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const _email = event.target.email.value
        const _pwd = event.target.pwd.value
        login(_email, _pwd)
    }

    const handleMerchantSelect = (merchant_id) => {
        setCookie("current_ident_token", identToken, siteConfig.expired);
        setCookie("current_user_email", email, siteConfig.expired);

        selectMerchant(sessionId, merchant_id)
        //navigate('/bank/transactions/checkout');
    }

    const getMerchants = (user_id) => {
        getUserMerchants(user_id)
            .then((res) => {
                console.log("user merchants =>", res);
                setUserMerchants(res || [])
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                //dispatch({ type: "SET", settingName: "auth", settingData: null });
                setCookie("signin_session_id", '', 0);
                setCookie("signin_merchant_code", '', 0);
                setCookie("signin_merchant_id", '', 0);
                setCookie("signin_merchant_user_id", '', 0);
                setCookie("signin_current_user_id", '', 0);
                setCookie("signin_merchant_name", '', 0);
                setCookie("current_ident_token", '', 0);
                setCookie("current_user_email", '', 0);
                setCookie("selected_merchant_address", '', 0);
                setCookie("current_merchant_wallet_address", '', 0);
                setCookie("selected_merchant_bool_cannabis_merchant", '', 0);
                setCookie("veev_session_id", '', 0);
                setCookie("veev_current_user_id", '', 0);
                setCookie("veev_current_user_email", '', 0);
                setCookie("veev_current_user_ident_token", '', 0);
            });
    }

    const login = (email, pwd) => {
        if (email === "" && email === undefined) {
            return
        }
        if (pwd === "" && pwd === undefined) {
            return
        }
        loginWithEmail(email, pwd)
            .then((res) => {
                console.log("logging in ...", res);
                setEmail(res.email)
                setIdentToken(res.ident_token)
                setCookie("current_ident_token", res.ident_token, siteConfig.expired);
                setCookie("current_user_email", res.email, siteConfig.expired);
                document.getElementById('user-merchants-container').hidden = false;
                document.getElementById('login-container').hidden = true;
                getMerchants(res.id);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        const cable = ActionCable.createConsumer(siteConfig.webSocketUrl);
        const _authChanel = cable.subscriptions.create(
            {
                channel: 'SessionChannel'
            },
            {
                connected: () => {
                    console.log('Connected authentication_request')
                    _authChanel.send({
                        type: "authentication_request",
                        session_id: true,
                    });
                },
                received: data => {
                    const session_id = data.session_id
                    handleReceivedAuth(data)
                    cable.subscriptions.create(
                        {
                            channel: "MerchantChannel",
                            session_id,
                        },
                        {
                            received: (data) => {
                                // `data` will contain a `merchant_select_response` message.
                                console.log("Received merchant data." + JSON.stringify(data));
                                /* Do whatever with merchant data here. : save sessionid and merchant code., user_id on the session */
                                if (data.type === "merchant_select_response") {
                                    setCookie("signin_session_id", session_id, siteConfig.expired);
                                    setCookie("signin_merchant_code", data.merchant_information.merchant_code, siteConfig.expired);
                                    setCookie("signin_merchant_id", data.selected_merchant_id, siteConfig.expired);
                                    setCookie("signin_merchant_user_id", data.merchant_user_id, siteConfig.expired);
                                    setCookie("signin_current_user_id", data.current_user_id, siteConfig.expired);
                                    setCookie("signin_merchant_name", data.merchant_information.legal_name, siteConfig.expired);
                                    setCookie("current_ident_token", data.current_user.ident_token, siteConfig.expired);
                                    setCookie("current_user_email", data.current_user.email, siteConfig.expired);
                                    const selected_merchant_address =
                                        data.merchant_information.street +
                                        " " +
                                        data.merchant_information.city +
                                        " " +
                                        data.merchant_information.state +
                                        " " +
                                        data.merchant_information.zip;
                                    setCookie("selected_merchant_address", selected_merchant_address, siteConfig.expired);
                                    setCookie("current_merchant_wallet_address", data.merchant_information.address, siteConfig.expired);
                                    setCookie("selected_merchant_bool_cannabis_merchant", data.merchant_information.cannabis_merchant === 'true', siteConfig.expired);
                                    const auth = {
                                        signin_session_id: session_id,
                                        signin_merchant_code: data.merchant_information.merchant_code,
                                        signin_merchant_id: data.selected_merchant_id,
                                        //signin_merchant_user_id : data.merchant_information.wallet.user_id,
                                        signin_merchant_user_id: data.merchant_wallet_user_id,
                                        signin_current_user_id: data.current_user_id,
                                        signin_merchant_name: data.merchant_information.legal_name,
                                        current_ident_token: data.current_user.ident_token,
                                        current_user_email: data.current_user.email,
                                        selected_merchant_address: selected_merchant_address,
                                        current_merchant_wallet_address: data.merchant_information.wallet_address,
                                        selected_merchant_bool_cannabis_merchant: data.merchant_information.cannabis_merchant === 'true',
                                    };
                                    dispatch({type: 'SET', settingName: 'auth', settingData: auth})
                                    NotificationManager.success('VEEV ID Authenticated', 'Success', 3000);
                                    navigate('/bank/transactions/checkout');
                                }
                            },
                        }
                    );
                    cable.subscriptions.create(
                        {
                            channel: "SessionChannel",
                            session_id,
                        },
                        {
                            received: (data) => {
                                // `data` will contain a `merchant_select_response` message.
                                console.log("Received Session Channel." + JSON.stringify(data));
                                /* Do whatever with merchant data here. : save sessionid and merchant code., user_id on the session */

                                if (data.type === "authentication_linking") {
                                    // get the current user info.
                                    var current_user = data.current_user;
                                    console.log("current user info" + current_user);

                                    //set the current user info on the local storage: session_id, user_id,
                                    if (session_id !== undefined) {
                                        setCookie("veev_session_id", session_id, siteConfig.expired);
                                        setCookie("veev_current_user_id", current_user.id, siteConfig.expired);
                                        setCookie("veev_current_user_email", current_user.email, siteConfig.expired);
                                        setCookie("veev_current_user_ident_token", current_user.ident_token, siteConfig.expired);
                                        const auth = {
                                            signin_session_id: session_id,
                                            signin_current_user_id: current_user.id,
                                            current_user_email: current_user.email,
                                            current_ident_token: current_user.ident_token,
                                        }
                                        dispatch({type: 'SET', settingName: 'auth', settingData: auth})
                                    }
                                }

                                if (data.type === "merchant_create_init") {
                                    /* go to the sign up page. */
                                    console.log("create new merchant button: " + JSON.stringify(data));
                                    if (session_id !== undefined) {
                                        navigate('/signup')
                                    }
                                }
                            },
                        }
                    );
                }
            }
        )
    }, [])
    return (
        <>
            <Header/>
            <div className="container" style={{height: 'calc(100vh - 65px)'}}>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div id="login-container" className="col-md-6 my-auto">
                        <div className="header-content2 text-center mx-auto">
                            {/* <h2 className="mb-5"><br /></h2> */}
                            <form className="form-horizontal text-left" onSubmit={handleSubmit}>
                                <div className="col-md-12 form-group" style={{textAlign: 'center', paddingTop: '15vh'}}>
                                    <div className="">
                                        <span><h4>Business Account Signup & Login</h4></span>
                                        <span>Download VEEV App and scan QR to create Business account.</span>
                                        <div className="text-center">
                                            <a className="badge-link" style={{textAlign: 'center', display: 'block'}}
                                               href="https://apps.apple.com/app/veev-app/id6444875499">
                                                <img className="wallet"
                                                     src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png"
                                                     style={{marginTop: 30, height: 38}} alt="VEEV Wallet"/></a>
                                        </div>
                                    </div>
                                    {qrcode !== '' &&
                                        <img id='barcode' src={qrcode} alt="generated QR Code"/>
                                    }
                                </div>
                                <div className="text-center mx-auto">
                                    Already have an account? Login with QR or email.<br></br>
                                </div>
                                <div id="email_login_form" className="col-md-12 form-group"
                                     style={{display: 'none', textAlign: 'center'}}>
                                    <input type="text" id="email" name="email" placeholder="Email"/>
                                    <input type="password" id="pwd" name="pwd" placeholder="Password" required/>
                                    <button type="submit" className="text-center btn-success btn-sm">
                                        Login
                                    </button>
                                    <button
                                        type="button"
                                        className="text-center btn-sm"
                                        onClick={() => {
                                            document.getElementById('barcode').style.display = 'inline-block';
                                            document.getElementById('login_with_email').style.display = 'inline-block';
                                            document.getElementById('email_login_form').style.display = 'none';
                                        }}>
                                        Cancel
                                    </button>
                                </div>
                            </form>
                            <button
                                id="login_with_email"
                                className="text-center btn-success btn-sm"
                                style={{marginTop: '16px'}}
                                onClick={() => {
                                    document.getElementById('barcode').style.display = 'none';
                                    document.getElementById('login_with_email').style.display = 'none';
                                    document.getElementById('email_login_form').style.display = 'block';
                                }}>
                                Login Using Email
                            </button>
                        </div>
                    </div>

                    <div id="user-merchants-container"
                         className="col-md-6 my-auto header-content2 text-center"
                         style={{top: "100px"}}
                    >
                        <div className="list-group" hidden>
                            <h4>Merchant Accounts</h4>
                            {userMerchants.map((o, i) => {
                                return (
                                    <a key={i} href="javascript:void(0)"
                                       onClick={() => {
                                           handleMerchantSelect(o.id)
                                       }}
                                       className="list-group-item list-group-item-action">
                                        {o.legal_name}
                                    </a>)
                            })}
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    )
}
export default Login
