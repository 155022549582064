import React, {useEffect} from 'react'
import Header from "../../components/header/headerEmptyPage";
import Footer from '../../components/footer/footerPage'

const Support = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
        <div className="container" style={{ height: 'calc(100vh - 65px)' }}>
          <div>
            <div>
            <br /><br /><br /><br /><br />
              <h3>Support</h3>
              For support issues please contact customer service at 1-415-340-2545.
              <br /><br />
              
            </div>
        </div>
      </div>
      <Footer />
    </>  
  )
}
export default Support
