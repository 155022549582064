import React, { useEffect, useState } from 'react'
import StoreLayout from "../../../../layouts/store";
import { useAsync } from '../../../../service/utils';
import { useSetting } from '../../../../provider/setting';
import QrBox from './qrBox';
import Checkout from './checkout';

const boxNumbers = [1, 2, 3, 4, 5, 6, 7]
const boxNumber = [1, 2, 3, 4, 5, 6, 7]
const setBoxNumber = [1, 2, 3, 4, 5, 6, 7]


const donation = {
  legal_name: 'donate',
  street: 'street',
  city: 'city',
  state: 'state',
  zip: 'zip',
  wallet: {
    address: 'address'
  },
  merchant_code: 'kb7fF1knHfgoiVz7P48e-CdN1rZEwK5fgoKMXA73EhmCeu7migO-QcaEH',
  tax_info: 'tax_info'
}
const EventCreate = () => {
  const { data, status, error, run } = useAsync({
    status: "idle",
  });
  const [setting] = useSetting()
  const [step, setStep] = useState(0)
  
  const nextStep = () => {
    setStep(step + 1)
  }
  const prevStep = () => {
    setStep(step - 1)
  }
  
  useEffect(() => {
    if (status === 'resolved') {
    }
    else if (status === 'rejected') {
      console.log(error)
    }
  }, [status])
  return (
    <>
      <StoreLayout>
      {/* <Styles> */}
        <section className="bank-section donate-view">
          <div className="container tab-part">
            <div className="selected-merchant-name">
              {setting?.auth?.signin_merchant_name}
              <span className="selected-nav-top"> &gt; Checkout &gt; </span>
              <span className="selected-nav-top-active">Donation QR</span>
            </div>
            {/* <Nav active="store" /> */}
          </div>
          <div className="features tab-section" id="id_sec_veev_id_access">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-md-12">
                  {/* <Sidebar active="store" /> */}
                  {/* DONATE VIEW */}
                  <div className="devsite-section-article" id="id_dv_contact_info">
                    <fieldset id="">
                      <legend>Donation QR</legend>
                    </fieldset>
                    <div className='view-container'>
                      <div id="delivery_container" className='view-box'>
                        <select style={{ width: '100%' }} id="id_event_start_date" className="event_times form-control mo-userfield-input ak-has-overlay" value={boxNumber} onChange={(e) => setBoxNumber(e.target.value)}>
                            {boxNumbers.map((item, index) => (
                              <option value={item} key={index}>{item}</option>
                            ))}
                        </select>
                        {step === 0 ?
                        <QrBox donation={donation} next={nextStep} /> :
                        step === 1 ?
                        <Checkout prev={prevStep} next={nextStep} /> :
                        ''
                        }
                      </div>
                    </div>
                  </div>
                   {/* END DONATE VIEW */}
                </div>
              </div>
            </div>
          </div>
        </section>
      {/* </Styles> */}
      </StoreLayout>
    </>
  );
}
export default EventCreate
