import React, {useEffect, useState} from 'react';
import StoreLayout from "../../../../layouts/store";
import ProfileSidebar from "./sidebar";
import {Col, Form, Input, Row, Select, Switch, Upload} from "antd";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {getMerchantApi, updateMerchantApi} from "../../../../api/api";
import {useSetting} from "../../../../provider/setting";
import SubmitButton from "../components/SubmitButton";
import {getFileExtension} from "../../../../service/string"
import {uploadFileApi} from "../../../../api/api/upload";

const {Item} = Form;
const {Option} = Select;

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]

const initDays = [
  {open: false, openHour: "09:00", openSuffix: "AM", closeHour: "05:00", closeSuffix: "PM"},
  {open: false, openHour: "09:00", openSuffix: "AM", closeHour: "05:00", closeSuffix: "PM"},
  {open: false, openHour: "09:00", openSuffix: "AM", closeHour: "05:00", closeSuffix: "PM"},
  {open: false, openHour: "09:00", openSuffix: "AM", closeHour: "05:00", closeSuffix: "PM"},
  {open: false, openHour: "09:00", openSuffix: "AM", closeHour: "05:00", closeSuffix: "PM"},
  {open: false, openHour: "09:00", openSuffix: "AM", closeHour: "05:00", closeSuffix: "PM"},
  {open: false, openHour: "09:00", openSuffix: "AM", closeHour: "05:00", closeSuffix: "PM"},
]

const StoreProfileEditPage = () => {
  const [setting] = useSetting();
  const [openDays, setOpenDays] = useState(initDays);
  const [age, setAge] = useState(false);
  const [tipping, setTipping] = useState(false);
  const [mobileListing, setMobileListing] = useState(false);
  const [user, setUser] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [merchant, setMerchant] = useState({});
  const [loading, setLoading] = useState(false);

  const getMerchantHandle = () => {
    if (!!setting?.auth?.signin_merchant_id) {
      getMerchantApi(setting?.auth?.signin_merchant_id)
        .then(({data}) => {
          setMerchant(data)
          setImageUrl(data.logo);
          setAge(Boolean(data.minimumGuestAge));
          setTipping(Boolean(data.tipping));
          setMobileListing(Boolean(data.list_in_stores))
          setOpenDays(JSON.parse(data.other)?.openHours || initDays)
        })
        .catch((e) => {
          console.warn(e)
        })
    }
  }

  const beforeUpload = async (file) => {
    try {
      setLoading(true);
      const filename = setting?.auth?.signin_merchant_id + '_' + Date.now() + '.' + getFileExtension(file.name)
      uploadFileApi(file, filename, {
        onError: (e) => {
          console.log(e.code);
          console.log(e.message);
          setLoading(false)
        },
        onSuccess: (data) => {
          setImageUrl(data.Location);
          setLoading(false);
        },
        onProgress: (e) => {
          console.log(e)
        }
      })


    } catch (e) {
      console.warn(e)
    }
  };

  const updateMerchantHandle = async (payload) => {
    setLoading(true)
    console.log(payload, "PAYLOAD");
    try {
      const data = {
        user: {
          merchant_id: setting?.auth?.signin_merchant_id,
          merchant_user_id: setting?.auth?.signin_merchant_user_id,
          merchant: {
            description: payload.description,
            category: payload.category,
            logo: !!imageUrl ? imageUrl: undefined,
            minimumGuestAge: age ? payload.minimumGuestAge : null,
            tipping: tipping,
            list_in_stores: mobileListing,
            other: JSON.stringify({
              openHours: openDays
            })
          }
        },
      };
      const res = await updateMerchantApi(setting?.auth?.signin_merchant_id, {...data})
      console.log("UPDATE", res)
      getMerchantHandle();
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }


  useEffect(() => {
    if (!!setting?.auth?.signin_merchant_id) {
      getMerchantHandle();
    }
  }, [setting?.auth])


  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined/> : <PlusOutlined/>}
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  );


  return (
    <StoreLayout>
      <div className="row">
        <div className="col col-lg-3">
          <ProfileSidebar/>
        </div>
        <div className="col col-lg-9 pb-5">
          <h4>Business Profile</h4>
          <Form
            fields={[
              {name: "category", value: merchant.category},
              {name: "dba", value: merchant.dba},
              {name: "description", value: merchant.description},
              {name: "minimumGuestAge", value: merchant.minimumGuestAge || 13}
            ]}
            onFinish={updateMerchantHandle}
            layout="vertical">
            <h5 className="mt-5">Basic Information</h5>
            <Row gutter={12}>
              <Col span={16}>
                <Item
                  id="dba"
                  name="dba"
                  rules={[{required: true, message: "Name is required."}]}
                  label="Name">
                  <Input
                    style={{margin: 0}}
                    disabled
                    placeholder="Store Name"
                    size="large"/>
                </Item>
              </Col>
              <Col span={8}>
                <Item
                  name="category"
                  id="category"
                  rules={[
                    {
                      required: true,
                      message: "Merchant Type is required"
                    }
                  ]}
                  label="Merchant Type">
                  <Select
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    showSearch
                    options={[
                      {value: "Cafe", label: "Cafe"},
                      {value: "Nail Spa", label: "Nail Spa"},
                      {value: "Event", label: "Event"},
                      {value: "Membership", label: "Membership"},
                      {value: "Parking", label: "Parking"},
                    ]}
                    size="large"/>
                </Item>
              </Col>
              <Col span={24}>
                <h5>Address</h5>
                <Item>
                  <p style={{fontSize: 16}}>
                    <b style={{fontWeight: 500}}>
                      {merchant.address},&nbsp;
                      {merchant.city},&nbsp;
                      {merchant.state}&nbsp;
                      {merchant.country || "US"}&nbsp;
                      {merchant.zip}
                    </b>
                  </p>
                </Item>
              </Col>
              <Col span={24}>
                <h5>Logo & Description</h5>
              </Col>
              <Col span={6}>
                <Item label="Image">
                  <Upload
                    multiple={false}
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}>
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
                  </Upload>
                </Item>
              </Col>
              <Col span={18}>
                <Item
                  id="description"
                  name="description"
                  rules={[{required: true, message: "Description is required."}]}
                  label="Description">
                  <Input.TextArea
                    style={{minHeight: 150}}
                    placeholder="Description"
                    size="large"/>
                </Item>
              </Col>
            </Row>

            <h5 className="mt-5">Open Hours</h5>
            <Row gutter={12}>
              <Col span={8}>
                <label>Week Day</label>
              </Col>
              <Col span={8}>
                <label>Open Time:</label>
              </Col>
              <Col span={8}>
                <label>Close Time:</label>
              </Col>
              {
                [...new Array(7)].map((_, index) => (
                  <Col span={24} key={`open-${index}`}>
                    <Row
                      gutter={12}
                      align="middle">
                      <Col span={2}>
                        <Item>
                          <Switch
                            checked={openDays[index].open}
                            onChange={(checked) => {
                              const od = openDays
                              od[index] = {...od[index], open: checked}
                              setOpenDays([...od])
                            }}
                          />
                        </Item>
                      </Col>
                      <Col span={6}>
                        <Item>
                          <Select
                            disabled={!openDays[index].open}
                            value={weekDays[index]}
                            size="large">
                            <Option value={weekDays[index]}>{weekDays[index]}</Option>
                          </Select>
                        </Item>
                      </Col>
                      <Col span={5}>
                        <Item>
                          <Select
                            disabled={!openDays[index].open}
                            onChange={(value) => {
                              const od = openDays
                              od[index] = {...od[index], openHour: value}
                              setOpenDays([...od])
                            }}
                            value={openDays[index].openHour}
                            size="large">
                            {
                              [...Array(12)].map((i, index) => (
                                <Option
                                  key={`state-${index}`}
                                  value={`${index < 9 && "0"}${index + 1}:00`}>
                                  {index < 9 && "0"}{index + 1}:00
                                </Option>
                              ))
                            }
                          </Select>
                        </Item>
                      </Col>
                      <Col span={3}>
                        <Item>
                          <Select
                            disabled={!openDays[index].open}
                            value={openDays[index].openSuffix}
                            onChange={(value) => {
                              const od = openDays
                              od[index] = {...od[index], openSuffix: value}
                              setOpenDays([...od])
                            }}
                            size="large">
                            <Option value="AM">AM</Option>
                            <Option value="PM">PM</Option>
                          </Select>
                        </Item>
                      </Col>
                      <Col span={5}>
                        <Item>
                          <Select
                            disabled={!openDays[index].open}
                            value={openDays[index].closeHour}
                            onChange={(value) => {
                              const od = openDays
                              od[index] = {...od[index], closeHour: value}
                              setOpenDays([...od])
                            }}
                            size="large">
                            {
                              [...Array(12)].map((i, index) => (
                                <Option
                                  key={`state-${index}`}
                                  value={`${index < 9 && "0"}${index + 1}:00`}>
                                  {index < 9 && "0"}{index + 1}:00
                                </Option>
                              ))
                            }
                          </Select>
                        </Item>
                      </Col>
                      <Col span={3}>
                        <Item>
                          <Select
                            disabled={!openDays[index].open}
                            onChange={(value) => {
                              const od = openDays
                              od[index] = {...od[index], closeSuffix: value}
                              setOpenDays([...od])
                            }}
                            value={openDays[index].closeSuffix}
                            size="large">
                            <Option value="AM">AM</Option>
                            <Option value="PM">PM</Option>
                          </Select>
                        </Item>
                      </Col>
                    </Row>
                  </Col>
                ))
              }
            </Row>

            <Row className="mt-5 mb-5">
              <Col span={12}>
                <h5>Age & ID Verification</h5>
                
                <Row gutter={12}>
                  <Col>
                    <Item>
                      <Switch
                        height={22}
                        width={44}
                        checked={age}
                        onChange={(checked) => {
                          setAge(checked)
                        }}/>
                    </Item>
                  </Col>
                  <Col>
                    {
                      age && (
                        <Item 
                          name="minimumGuestAge">
                          <Select
                            style={{width: 70}}
                            name="minimumGuestAge"
                            id="minimumGuestAge"
                            defaultValue="13"
                            size="large">
                            {
                              [...new Array(13)].map((_, index) => (
                                <Option key={`age-${index}`} value={index + 13}>{index + 13}</Option>
                              ))
                            }
                          </Select>
                        </Item>
                      )
                    }
                  </Col>
                </Row>
              </Col>
              {/* <Col span={12}>
                <h5>Identity Verification</h5>
                <Item>
                  <Switch
                    height={22}
                    width={44}
                    checked={user}
                    onChange={(checked) => {
                      setUser(checked)
                    }}/>
                </Item>
              </Col> */}
              <Col span={4}>
                <h5>Tips</h5>
                <Item>
                  <Switch
                    checked={tipping}
                    onChange={(checked) => {
                      setTipping(checked)
                    }}
                  />
                </Item>
              </Col>
              <Col span={8}>
                <h5>Mobile App Listing</h5>
                <Item>
                  <Switch
                    checked={mobileListing}
                    onChange={(checked) => {
                      setMobileListing(checked)
                    }}
                  />
                </Item>
              </Col>
            </Row>

            <Item>
              <SubmitButton
                htmlType="submit"
                type="primary"
                loading={loading}
                size="large">
                Update Profile
              </SubmitButton>
            </Item>
          </Form>
        </div>
      </div>
    </StoreLayout>
  );
};


export default StoreProfileEditPage;
