import styled from "styled-components";

export const Styles = styled.div`
  .nft {
    margin: 125px 50px 50px 50px;
    border: 1px solid #d3d3d3;
    border-radius: 1rem;
    @media (max-width: 400px) {
      margin: 82px 5px 0px 5px;
    }
    .main-container {
      padding: 32px;
      @media (max-width: 400px) {
        padding: 5px;
      }
      .nft-image {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 60vh;
          width: auto;
          max-width: 100%;
          border-radius: 1rem;
        }
      }
      .action {
        position: absolute;
        top: 0px;
        right: 15px;
        display: flex;
        .action-item {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #877b7b;
          padding: 0.15rem 0.25rem;
          font-size: 0.875rem;
          line-height: 1.5;
          cursor: pointer;
          color: #877b7b;
          .icon {
            font-size: 15px;
          }
        }
        .action-left {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 0.5em;
          border-bottom-left-radius: 0.5em;
        }
        .action-center {
        }
        .action-right {
          border-top-right-radius: 0.5em;
          border-bottom-right-radius: 0.5em;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .code {
        color: #1d3561;
        font-size: 26px;
        font-weight: 600;
      }
      .category {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        .categroy-item {
          margin: 10px 0px 0px 10px;
          padding: 2px 5px;
          border: solid 1px;
          border-color: #1d3561;
          color: #1d3561;
          border-radius: 0.25rem;
        }
      }
      .collection {
        color: #c7c6c6;
        padding-bottom: 1rem;
        border-bottom: solid 1px #c7c6c6;
      }
      .price-info {
        padding: 2rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price {
          color: #1d3561;
          font-size: 45px;
          font-weight: 600;
        }
        .state {
          color: #c7c6c6;
          font-size: 27px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
      .toggle-item {
        border: 1px solid #d3d3d3;
        border-radius: 0.5rem;
        margin-bottom: 1rem;
        .header {
          background-color: rgba(244, 244, 244, 0.4);
          font-size: 22px;
          color: #1d3561;
          border-radius: 0.5em;
          line-height: 1em;
          padding: 1.35rem 1.85rem;
          display: flex;
          align-items: center;
          cursor: pointer;
          .header-name {
            padding: 0px 25px;
          }
        }
        .toggle-body {
          padding: 0.75rem 2.25rem;
          @media (max-width: 400px) {
            overflow: scroll;
            padding: 10px;
          }
        }
      }
      .properties {
        .property-item {
          background-color: rgba(244, 244, 244, 0.4);
          border-radius: 0.5em;
          border: 1px solid #d3d3d3;
          text-align: center;
          padding: 12px;
          .title {
            font-size: 18px;
            color: #817c7c;
            text-transform: uppercase;
          }
          .subtitle {
            font-size: 20px;
            font-weight: 600;
            color: #2a2929;
          }
          .value {
            font-size: 12px;
          }
        }
      }
      .about {
        img {
          width: 100px;
          float: left;
          margin-right: 10px;
          margin-bottom: 10px;
        }
        .title {
          font-size: 20px;
          font-weight: 600;
          color: #1d3561;
          padding: 10px 0px;
        }
      }
      .details {
        table {
          width: 100%;
        }
        tr {
          padding: 10px 0px;
          border-bottom: solid 1px #e5e4e4;
          th {
            color: #c7c6c6;
          }
          td.user-info {
            display: flex;
            img {
              width: 40px;
              height: 40px;
              border-radius: 40px;
              margin-right: 10px;
            }
            .user-name {
              color: #1d3561;
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
        tr.last {
          border: none;
        }
      }
      .more {
        .board-item {
          padding: 0px 8px;
        }
      }
    }
  }
`;
