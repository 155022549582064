import styled from "styled-components";

export const Styles = styled.div`
  section.signup {
    margin-top: 70px;
    padding: 50px 10px !important;
    label.cursor {
      cursor: text;
      pointer-events: none;
    }
  }
  .submit-button {
    padding-bottom: 100px;
  }
  .btn-create-account {
    border-radius: 300px;
    font-family: Lato, Helvetica, Arial, sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-right: 45px;
    padding: 15px;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    color: #fff !important;
    background-color: #28a745;
    border-color: #28a745;
  }
`;