import React from 'react';
import {Link, useLocation} from "react-router-dom";

const ItemsSidebar = () => {
  const location = useLocation()

  return (
    <div className="side-nav-wrap">
      <div className="side-nav">
        <nav>
          <li>
            <Link
              className={location.pathname.includes("items") ? "active" : ""}
              to="/bank/store/items">
              Items
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname.includes("addons") ? "active" : ""}
              to="/bank/store/addons">
              Add Ons
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname.includes("categories") ? "active" : ""}
              to="/bank/store/categories">
              Categories
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname.includes("storelink") ? "active" : ""}
              to="/bank/store/storelink">
              StoreLink
            </Link>
          </li>
        </nav>
      </div>
    </div>
  );
};

export default ItemsSidebar;
