import React from 'react'

import { Styles } from "../../layouts/style/footerHomeStyle";

const FooterEmpty = () => {

  return (
    <Styles>
      <footer id="footer2">
        <div>
          <p style={{ textAlign: 'center', color: 'black' }}>
            Copyright &copy; 2023 VEEV Inc.
          </p>
        </div>
      </footer>
    </Styles>
  );
}
export default FooterEmpty
