import { apiPost } from './index'

async function loginWithSession(data) {
  try {
    const res = await apiPost("/loginWithSession", data);
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

async function loginWithEmail(email, auth) {
  try {
    let data = { 
        user: {
            username: email, password: auth 
        }
    };
    const res = await apiPost("v1/sessions", data);
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

export {
  loginWithSession,
  loginWithEmail
}
