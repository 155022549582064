import React, {useEffect, useState} from 'react';
import StoreLayout from "../../../../layouts/store";
import CheckinSidebar from "./sidebar";
import {Avatar, Button, Card, Modal, Tabs} from "antd";
import {deleteEventApi, getEventsApi} from "../../../../api/api";
import {useSetting} from "../../../../provider/setting";
import styled from "styled-components";
import {Icon} from "@iconify/react";
import {useNavigate} from "react-router-dom";
import SubmitButton from "../components/SubmitButton";
import {NotificationManager} from "react-notifications";
import {ClockCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import siteConfig from "../../../../config/site.config";
import {merchantCreateAccessApi} from "../../../../api/api";

const EventsPage = () => {
  const navigate = useNavigate();
  const [setting] = useSetting();
  const [active, setActive] = useState([]);
  const [inactive, setInactive] = useState([]);
  const [modal, contextHolder] = Modal.useModal();

  const getHandle = () => {
    if (setting?.auth?.signin_merchant_id) {
      getEventsApi(setting?.auth?.signin_merchant_id)
        .then(({data}) => {
          setActive(data?.active_events)
          setInactive(data?.past_events)
        })
        .catch(e => console.warn(e))
    }
  }

  //QR start
  const [qrcode, setQrcode] = useState('')
  const [accessQrCode, setAccessQrCode] = useState('')

  const generateAccess = async () => {
    const mid = setting?.auth?.signin_merchant_id
    const code = setting?.auth?.signin_merchant_code
    const { data } = merchantCreateAccessApi(mid, code);
    const data_str = `${siteConfig.checkoutUrl}/${data?.access?.['access_token']}`;
    const qr_src = "https://chart.googleapis.com/chart?cht=qr&chs=200x200&chld=H7C0&chl=" + data_str;
    setAccessQrCode(qr_src)
  }

  useEffect(()=>{
    if (setting?.auth?.signin_merchant_name) {
      generateAccess();
      const data = [
        "VEEV-Order",
        1,
        setting?.auth?.signin_merchant_name
      ]
      const data_str = data.join("|||");
      const qr_src = "https://chart.googleapis.com/chart?cht=qr&chs=200x200&chld=H7C0&chl=" + data_str;
      setQrcode(qr_src)
    }
  },[setting?.auth])
   //QR end 

  const deleteHandle = (item) => {
    console.log(item)

    modal.confirm({
      title: <h5>Delete Event!</h5>,
      icon: <Icon style={{width: 60, height: 60, color: "#cc0000"}} icon="ep:warn-triangle-filled"/>,
      content: <p>Are you sure to delete <b>{item.event_title}</b> event?</p>,
      okText: 'Delete',
      cancelText: 'Cancel',
      okType: "danger",
      onOk: () => {
        console.log(item)
        deleteEventApi({
          merchant_id: setting?.auth?.signin_merchant_id,
          merchant_user_id: setting?.auth?.signin_merchant_user_id,
        }, item.id)
          .then(() => {
            getHandle();
            NotificationManager.success("Successfully deleted!", "Success", 3000)
          })
          .catch((e) => {
            console.warn(e);
            NotificationManager.error("An error was created. try again later!", "Error", 3000)
          })
      },
    });
  }

  useEffect(() => {
    getHandle()
  }, [setting])


  return (
    <StoreLayout>
      <div className="row">
        <div className="col col-lg-3">
          <CheckinSidebar/>
        </div>
        <div className="col col-lg-9">
          <Tabs
            defaultActiveKey="2"
            items={[
              {
                label: (
                  <span>
                    <ClockCircleOutlined />
                    Active
                  </span>
                ),
                key: "active",
                children: (<>
                  <h4>Active Events</h4>
                  {
                    active.map((i) => (
                      <EventCard key={i.id}>
                        <Avatar
                          shape="square"
                          src={i?.event_image}/>
                        <div className="details">
                          <h5>{i.event_title}</h5>
                          <p className="address">{i?.address}, {i?.city}, {i?.state}, {i?.zip_code}</p>
                          <p className="hours">{i?.start_date}~{i?.end_date}</p>
                        </div>
                        <div>
                          <img
                            id='client-barcode'
                            width={200}
                            height={200}
                            src={qrcode}
                            alt="generated QR Code" />
                        </div>
                        <div className="actions">
                          <Button
                            ghost
                            onClick={() => {
                              navigate(`/bank/store/events/edit/?id=${i?.id}`)
                            }}
                            icon={<Icon icon="material-symbols:edit-outline-sharp"/>}
                            type="primary">
                          </Button>
                          <Button
                            ghost
                            danger
                            onClick={() => {
                              deleteHandle(i)
                            }}
                            icon={<Icon icon="material-symbols:delete-outline-sharp"/>}
                            type="primary">
                          </Button>
                        </div>
                      </EventCard>
                    ))
                  }
                </>),
              },
              {
                label: (
                  <span>
                    <CloseCircleOutlined />
                    Passed
                  </span>
                ),
                key: "expired",
                children: (<>
                  <h4>Passed Events</h4>
                  {
                    inactive.map((i) => (
                      <EventCard key={i.id}>
                        <Avatar
                          shape="square"
                          src={i?.event_image}/>
                        <div className="details">
                          <h5>{i.event_title}</h5>
                          <p className="address">{i?.address}, {i?.city}, {i?.state}, {i?.zip_code}</p>
                          <p className="hours">{i?.start_date}~{i?.end_date}</p>
                        </div>
                        <div className="actions">
                          <Button
                            ghost
                            onClick={() => {
                              navigate(`/bank/store/events/edit/?id=${i?.id}`)
                            }}
                            icon={<Icon icon="material-symbols:edit-outline-sharp"/>}
                            type="primary">
                          </Button>
                          <Button
                            ghost
                            danger
                            onClick={() => {
                              deleteHandle(i)
                            }}
                            icon={<Icon icon="material-symbols:delete-outline-sharp"/>}
                            type="primary">
                          </Button>
                        </div>
                      </EventCard>
                    ))
                  }
                </>),
              }
            ]}
          />


          <div className="mt-5">
            <SubmitButton
              onClick={() => {
                navigate("/bank/store/events/create")
              }}
              ghost
              type="primary"
              size="large">
              Create New Event
            </SubmitButton>
          </div>
        </div>
      </div>
      {contextHolder}
    </StoreLayout>
  );
};

const EventCard = styled(Card)`
  margin-bottom: 24px;

  .ant-card-body {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 122px 0 0;

    .ant-avatar {
      margin-right: 24px;
      width: 250px;
      height: 160px;
    }

    .details {
      display: flex;
      flex-direction: column;
      grid-gap: 20px;

      h5 {
        line-height: 1;
        margin: 0;
        font-size: 24px;
      }

      p {
        line-height: 1;
        margin: 0;
        font-size: 18px;

        &.address {
          margin-bottom: 4px;
        }

        &.hours {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 2px;
        }
      }
    }

    .actions {
      position: absolute;
      right: 24px;
      top: 20px;
      display: flex;
      flex-direction: column;
      grid-gap: 12px;
    }
  }
`

export default EventsPage;
