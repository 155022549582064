import React from 'react';
import StoreLayout from "../../../../layouts/store";
import CheckinSidebar from "./sidebar";

const TicketsEditPage = () => {
  return (
    <StoreLayout>
      <div className="row">
        <div className="col col-lg-3">
          <CheckinSidebar/>
        </div>
        <div className="col col-lg-9">

        </div>
      </div>
    </StoreLayout>
  );
};

export default TicketsEditPage;
