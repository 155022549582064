import React, {useEffect, useState} from 'react';
import StoreLayout from "../../../../layouts/store";
import ItemsSidebar from "./sidebar";
import {useNavigate} from "react-router-dom";
import {useSetting} from "../../../../provider/setting";
import {deleteCategoryApi, getCategoriesApi} from "../../../../api/api";
import {Button, Collapse, Space, Modal} from "antd";
import {Icon} from "@iconify/react";
import {NotificationManager} from "react-notifications";
import SubmitButton from "../components/SubmitButton";

const { Panel } = Collapse

const StoreCategoriesPage = () => {
  const navigate = useNavigate();
  const [setting] = useSetting();

  const [modal, contextHolder] = Modal.useModal();
  const [categories, setCategories] = useState([]);

  const getHandle = async () => {
    if(setting?.auth?.signin_merchant_code) {
      getCategoriesApi(setting.auth.signin_merchant_code)
        .then(({data}) => {
          setCategories(data);
        })
        .catch(e=>console.warn(e))
    }
  }
  const deleteHandle = (category) => {
    modal.confirm({
      title: <h5>Delete Category!</h5>,
      icon: <Icon style={{width: 60, height: 60, color: "#cc0000"}} icon="ep:warn-triangle-filled" />,
      content: <p>Are you sure to delete <b>{category.name}</b> category?</p>,
      okText: 'Delete',
      cancelText: 'Cancel',
      okType: "danger",
      onOk: () => {
        console.log(category)
        deleteCategoryApi(setting?.auth?.signin_merchant_code, category.id)
          .then(() => {
            getHandle();
            NotificationManager.success("Successfully deleted!", "Success", 3000)
          })
          .catch((e)=>{
            console.warn(e);
            NotificationManager.error("An error was created. try again later!", "Error", 3000)
          })
      },
    });
  }


  useEffect(() => {
    getHandle()
  }, [setting])


  console.log(categories)

  return (
    <StoreLayout>
      <div className="row">
        <div className="col col-lg-3">
          <ItemsSidebar/>
        </div>

        <div className="col col-lg-9">
          <h4>Categories</h4>
          <Space direction="vertical" style={{width: "100%"}} className="mt-4">
            {
              categories.map((category, index) => (
                <Collapse key={`category-collapse-${index}`}>
                  <Panel
                    extra={
                      [
                        <Button
                          key={`edit-${index}`}
                          onClick={()=>{navigate(`/bank/store/categories/edit/?id=${category.id}`)}}
                          className="icon-button"
                          style={{marginRight: 6}}
                          type="link" >
                          <Icon color="#006000" icon="mdi:circle-edit-outline"/>
                        </Button>,
                        <Button
                          key={`delete-${index}`}
                          onClick={()=>{deleteHandle(category)}}
                          className="icon-button"
                          type="link"
                          danger>
                          <Icon icon="material-symbols:delete-outline-sharp"/>
                        </Button>
                      ]
                    }
                    header={category.name}
                    key={`category-${index}`}>
                    <p>{category.description}</p>
                  </Panel>
                </Collapse>
              ))
            }
          </Space>
          <div className="mt-5">
            <SubmitButton
              size="large"
              type="primary"
              ghost
              onClick={()=>{navigate('/bank/store/categories/create')}}>
              Create New Category
            </SubmitButton>
          </div>
        </div>
      </div>
      {contextHolder}
    </StoreLayout>
  );
};

export default StoreCategoriesPage;
