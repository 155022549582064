import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Layout} from "antd";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Layout style={{backgroundColor: "transparent"}}>
    <App />
  </Layout>
);

reportWebVitals();
