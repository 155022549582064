import React from 'react';
import {Link, useLocation} from "react-router-dom";

const ProfileSidebar = () => {
  const location = useLocation()

  return (
    <div className="side-nav-wrap">
      <div className="side-nav">
        <nav>
          <li>
            <Link
              className={location.pathname === "/bank/store/profile" ? "active" : ""}
              to="/bank/store/profile">
              Profile
            </Link>
            {/* <Link
              className={location.pathname === "/bank/store/profile/edit" ? "active" : ""}
              to="/bank/store/profile/edit">
              Profile
            </Link> */}
          </li>
          <li>
          <Link
              className={location.pathname === "/bank/store/details" ? "active" : ""}
              to="/bank/store/details">
              Details
            </Link>
            {/* <Link
              className={location.pathname.includes("create")? "active" : ""}
              to="/bank/store/profile/setting">
              Settings
            </Link> */}
          </li>
          {/* <li>
            <Link
              className={location.pathname === "/bank/store/profile/checkin"? "active" : ""}
              to="/bank/store/profile/checkin">
              Checkin QR
            </Link>
          </li> */}
        </nav>
      </div>
    </div>
  );
};

export default ProfileSidebar;
