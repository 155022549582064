import styled from "styled-components";

export const Styles = styled.div`
  .nft-item {
    padding: 10px;
    text-decoration: none;
    height: 300px;
    max-height: 300px;
    list-style-type: none;
    cursor: pointer;
    border-radius: 1rem;
    border: 1px solid #cecece;
    margin: 8px 0px;
    transition: all 0.2s;
    opacity: 1;
    &:hover {
      border-color: #6c707e !important;
    }
    .nft-action {
      color: #c7c6c6;
      text-align: right;
      width: 100%;
      .action-item {
        font-size: 16px;
      }
    }
    .item-image {
      min-height: 180px;
      max-height: 180px;
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        max-width: 200px;
        height: auto;
        max-height: 150px;
      }
    }
    .nft-info {
      width: 100%;
      text-align: left;
      .nft-collection {
        font-size: 14px;
        color: #c7c6c6;
        overflow: hidden;
        white-space: nowrap;
      }
      .nft-code {
        font-size: 16px;
        line-height: 25px;
        font-weight: 300;
        color: #1d3561;
        overflow: hidden;
        white-space: nowrap;
      }
      .nft-price {
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -1px;
        font-weight: 600;
        color: #1d3561;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
`;
