import React, {useState, useEffect, useImperativeHandle, forwardRef} from 'react'
import {CardElement, useElements,useStripe} from "@stripe/react-stripe-js";
import {NotificationManager} from 'react-notifications';
import {paymentIntent} from '../api/stripe'
import {useAsync} from '../service/utils'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#303238",
      fontSize: "14px",
      fontFamily: "sans-serif",
      "::placeholder": {
        color: "#CFD7DF"
      }
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238"
      }
    }
  }
};
function CardSection() {
  return (
    <label style={{width: '100%'}}>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </label>
  );
}
const Stripe = forwardRef((props, ref) => {
  const {data, status, error, run} = useAsync({
    status: 'idle',
  })
  const {name, email, title, price, result} = props
  const stripe = useStripe()
  const elements = useElements()
  const [asyncState, setAsyncState] = useState('')

  
  const confirm = async (clientSecret) => {
    if (!stripe || !elements) {
      return;
    }
    const billingDetails = {
      name: name,
      email: email,
    }
    const card = elements.getElement(CardElement);
    let paymentMethodReq = await stripe.createPaymentMethod({
      type: 'card',
      card: card,
      billing_details: billingDetails,
    })
    console.log(clientSecret)
    console.log(paymentMethodReq)
    const confirmedCardPayment = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodReq?.paymentMethod?.id
    })
    console.log(confirmedCardPayment)
    if (confirmedCardPayment?.paymentIntent?.status === 'succeeded') {
      result(true)
    }
    else {
      NotificationManager.warning('Payment Error', 'Error', 3000);
    }
  }

  useImperativeHandle(ref, () => ({
    handlePayment : async () => {
      if (!stripe || !elements) {
        return;
      }
      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card);
      run(paymentIntent({
        name: name,
        email: email,
        amount: price, 
        currency: 'inr', 
        description: `${title} by ${email}`, 
        token: result.token
      }))
      setAsyncState('paymentIntent')
    }
  }))
  useEffect( () => {
    if (status === 'resolved') {
      if (asyncState === 'paymentIntent') {
        console.log(data)
        confirm(data.data.body.clientSecret)
      }
    }
    else if (status === 'rejected') {
      console.log(error)
    }
  }, [status, run])
  return (
    <>
        <CardSection />
    </>
  )
})
export default Stripe
