import styled from "styled-components";

export const Styles = styled.div`
  .nfts {
    margin: 0px;
    padding: 0rem 1rem;
    @media (max-width: 350px) {
      padding: 0px 5px;
    }
    .sidebar-filter {
      padding: 90px 0px 0px 0px;
      position: sticky;
      top: 0px;
      max-height: 100vh;
      .main-filters {
        height: 100%;
        background-color: rgba(244, 244, 244, 0.5);
        border-radius: 0.75rem;
      }
    }
    .main-container {
      padding-top: 82px;
      @media (max-width: 350px) {
        padding: 0px 5px;
      }
      .board-item {
        padding: 0px 8px;
      }
    }
  }
`;
