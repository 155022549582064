import React, { useState, useEffect, useRef } from 'react'
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js"
import Stripe from '../../../../components/stripe'
import siteConfig from '../../../../config/site.config';
const stripePromise = loadStripe(siteConfig.stripeClient);
const Checkout = (props) => {
  const {prev, next} = props
  const stripeRef = useRef()
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")

  const handleSubmit = () => {
    // stripeRef.current.handlePayment()
    prev()
  }
  const paymentResult = () => {
    next()
  }
  return (
    <>
      <div className='title'>Enter Information for Receipt Details</div>
      <div className='checkout-form'>
        <section className='form-item row'>
          <div className='col-md-6 col-12'>
            <input required placeholder="First name" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          </div>
          <div className='col-md-6 col-12'>
            <input required placeholder="Last name" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </div>
        </section>
        <section className='stripe'>
          <Elements stripe={stripePromise}>
            <Stripe ref={stripeRef} name={`${firstName} ${lastName}`} email={email} title="Donation payment" result={paymentResult} />
          </Elements>
        </section>
        <section className='form-item row'>
          <div className='col-md-6 col-12'>
            <input required placeholder="Phone Number" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
          </div>
          <div className='col-md-6 col-12'>
            <input required placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
        </section>
      </div>
      <section id="checkout_header">
        <section class="cancel_submit_order">
            <span id="cancel_user_pickup_info" onClick={() => prev()}>Back</span>
            <label for="submit_user_pickup_info" type="button" id="submit_user_pickup_label" onClick={handleSubmit}> Continue </label>
          </section>
      </section>
    </>
  )
}
export default Checkout
