import { apiGet, apiGetToken, apiPostToken, apiPutToken } from './index'

async function get(id) {
  try {
    const res = await apiGetToken(`events/${id}`);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getTickets(id) {
  try {
    const res = await apiGetToken(`events/${id}/event_tickets`);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getEvents(merchantId) {
  try {
    const res = await apiPostToken("get_events", {param: {merchant_id: merchantId}});
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getAll(data) {
  try {
    const res = await apiGet("events", data);
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

async function getAllWithMerchantId(merchantId) {
  try {
    const res = await apiPostToken("events_all", {param: {merchant_id: merchantId}});
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function create(data) {
  try {
    const res = await apiPostToken("events", data);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function update(data) {
  try {
    const res = await apiPutToken(`events/${data.id}`, data);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

export {
  get,
  getTickets,
  getEvents,
  getAll,
  getAllWithMerchantId,
  create,
  update
}
