import React, { useEffect, useMemo, useState } from "react";
import HeaderHome from "../../components/header/headerHomePage";
import FooterHome from "../../components/footer/footerHomePage";
import { Styles } from '../../layouts/style/homeStyle'

const Home = () => {
  const [slider, setSlider] = useState('')
  const sliders = useMemo(() => [
    "../assets/img/veev_1.png",
    "../assets/img/veev_2.png",
    "../assets/img/veev_3.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider(sliders[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders.length)
        index = 0
      setSlider(sliders[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders])

  const [loop, setloop] = useState('')
  const loops = useMemo(() => [
  // "VEEV Digital Identity",
  // "Create Once Use Everywhere",
  // "Share Contacts, Pay, Checkin, Sign Up",
  // "In One Click",
  // "../assets/img/1.png",
   "../assets/img/2.png",
  // "../assets/img/3.png",
  //"../assets/img/4.png",
  // "<p className="title">Checkin Checkout</p>",
  // "<p className="title1">& Earn Rewards</p>",
  // "<p>One click checkin & checkout at bars, cafes, events, stores, websites & more ...</p>",

  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setloop(loops[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= loops.length)
        index = 0
      setloop(loops[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [loops])

  return (
    <>
      <HeaderHome />
      <Styles>
        <section className="features">
          <div className="container container--main">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-8 my-auto text-left" style={{ alignItems: 'center' }}>
                      <div>
                        <p className="title">Shop & Sell</p>
                        <p className="title0">on Social Apps & Stores</p>
                        {/* <p className="title">Checkout</p>
                        <p className="title0">for Social Apps & Stores</p> */}
                        {/* <p style={{ width: '100%', paddingBottom: '0px', paddingTop: '30px', paddingLeft: '0px', color: 'grey' }}>VEEV is a one stop social commerce app with everything you need to run your business in one place. Turn Instagram, TikTok, YouTube and social media followers into customers.</p> */}
                        {/* <p style={{ width: '100%', paddingBottom: '0px', paddingTop: '30px', paddingLeft: '0px', color: 'grey' }}>VEEV is a one stop social commerce marketplace with tools to convert Instagram, TikTok, Twitch, Twitter, YouTube and social media followers into customers.</p> */}
                        <p style={{ width: '100%', paddingBottom: '0px', paddingTop: '30px', paddingLeft: '0px', color: 'grey' }}>
                          A link based checkout system to shop & sell on social app and stores. Manage payments, billing, shipping details across from one place.</p>
                        <div className="text-center">
                          <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
                          <img className="wallet" src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png" style={{ marginTop: 25, height: 38 }} alt="VEEV Wallet" /></a>
                        </div>	
                      </div>
                    </div>
                    <div className="col-lg-1 dv-loadmoney" style={{ paddingBottom: '50px' }}>
                    </div>
                    <div className="col-lg-3 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                      <div className="device-container" style={{ marginBottom: '0px' }}>
                        <div className="device-mockup iphone6 portrait gold">
                          <div className="device" style={{marginTop: 20, marginBottom: 40}}>
                            <div className="screen">	
                              <div className="slideshow-container">
                                <div className="mySlides">
                                  <div className="veevslogan">
                                    <img src={slider} alt="VEEV Checkin" className="img-fluid2" />
                                  </div>
                                </div>
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-4 my-auto text-center" style={{ alignItems: 'center' }}>
                      <div>
                        <p style={{ width: '100%', paddingBottom: '0px', paddingTop: '55px', paddingLeft: '0px', color: 'grey' }}>VEEV is a one stop social commerce app to turn Instagram, TikTok, YouTube and social media followers into customers.</p>
                        <div className="text-center">
                          <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
                          <img className="wallet" src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png" style={{ marginTop: 25, height: 38 }} alt="VEEV Wallet" /></a>
                        </div>	
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-center" style={{padding: 20}}>
            {/* <a href="#howitworks">
            <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '70px', marginLeft: '10px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
            </a> */}
            </div>
          </div>
        </section>
      </Styles>
      <FooterHome />
    </>
  );
};
export default Home;