import React, {useEffect, useState} from 'react';
import StoreLayout from "../../../../layouts/store";
import ItemsSidebar from "./sidebar";
import {Col, Form, Input, InputNumber, Row, Select} from "antd";
import SubmitButton from "../components/SubmitButton";
import {createAddonApi, getCategoriesApi, updateCategoryApi} from "../../../../api/api";
import {useSetting} from "../../../../provider/setting";
import {NotificationManager} from "react-notifications";
import {useNavigate} from "react-router-dom";

const {Item} = Form;

const StoreAddonCreatePage = () => {
    const [setting] = useSetting();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState("")

    const finishHandle = (value) => {
        const cat = categories.find((c) => c.id === category)

        createAddonApi(setting?.auth?.signin_merchant_code, value)
            .then(({data}) => {
                if(category) {
                    const cat = categories.find((c) => c.id === category)
                    let other = JSON.parse(cat.other|| "[]")
                    other.push(data.id)
                    updateCategoryApi(setting?.auth?.signin_merchant_code,
                        category,
                        {
                            other: JSON.stringify(other)
                        }
                    )
                        .then(({data})=>{
                        })
                        .catch((e)=>{
                            console.warn(e)
                        })
                }
                NotificationManager.success("Successfully created an addon!", "Success", 3000);
                navigate('/bank/store/addons')
            })
            .catch((e) => {
                console.warn(e);
                NotificationManager.error(e.response?.data?.message, "Error", 3000)
            })

    }

    const getCategoriesHandle = async () => {
        if (setting?.auth?.signin_merchant_code) {
            getCategoriesApi(setting.auth.signin_merchant_code)
                .then(({data}) => {
                    setCategories(data);
                })
                .catch(e => console.warn(e))
        }
    }


    useEffect(() => {
        getCategoriesHandle()
    }, [])


    return (
        <StoreLayout>
            <div className="row">
                <div className="col col-lg-3">
                    <ItemsSidebar/>
                </div>
                <div className="col col-lg-9">
                    <h4>Create New Add-On</h4>

                    <Form
                        onFinish={finishHandle}
                        layout="vertical">

                        <Row gutter={12}>
                            <Col span={8}>
                                <Item
                                    name="category"
                                    id="category"
                                    label="Category">
                                    <Select
                                        size="large"
                                        showSearch
                                        placeholder="Select a category"
                                        optionFilterProp="children"
                                        onChange={(e) => {setCategory(e)}}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={
                                            categories.map((category) => {
                                                category.value = category.id;
                                                category.label = category.name;
                                                return category
                                            })
                                        }/>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item
                                    name="name"
                                    id="name"
                                    label="Name">
                                    <Input
                                        placeholder="Enter Addon Name"
                                        size="large"/>
                                </Item>
                            </Col>

                            <Col span={8}>
                                <Item
                                    name="price"
                                    id="price"
                                    label="Price">
                                    <InputNumber
                                        step="0.01"
                                        parser={(value) => parseFloat(value.toString() || 0).toFixed(2)}
                                        prefix="$"
                                        suffix="USD"
                                        placeholder="0.00"
                                        size="large"/>
                                </Item>
                            </Col>

                            <Item>
                                <SubmitButton
                                    htmlType="submit"
                                    type="primary"
                                    size="large">
                                    Create Add-on
                                </SubmitButton>
                            </Item>
                        </Row>
                    </Form>
                </div>
            </div>
        </StoreLayout>
    );
};

export default StoreAddonCreatePage;
