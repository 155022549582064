import siteConfig from "../config/site.config";
import { getCookie } from '../service/cookie'

function api(url, method, headers, body) {
  try {
    return window
      .fetch(url, {
        method: method,
        headers: headers,
        body: body,
      })
      .then(async (response) => {
        const res = await response.json();
        if (response.ok) {
          return res;
        } else {
          const error = {
            message: res.message,
          };
          return Promise.reject(error);
        }
      });
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

async function apiGet(url) {
  try {
    const res = await api(`${siteConfig.apiUrl}${url}`, "GET");
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function apiGetToken(url, _email=null, _token=null) {
  try {
    let email = _email
    let token = _token
    if (email == null)
      email = getCookie("current_user_email");
    if (token == null)
      token = getCookie("current_ident_token");
    if (token) {
      const res = await api(`${siteConfig.apiUrl}${url}`, "GET", {
        'X-User-Email': email,
        'X-User-Token': token
      });
      return Promise.resolve(res);
    }
    else {
      return Promise.reject({ message: "Empty token" });
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

async function apiPost(url, data) {
  try {
    const res = await api(
      `${siteConfig.apiUrl}${url}`,
      "POST",
      {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      JSON.stringify(data)
    );
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function apiPostToken(url, data, _email=null, _token=null) {
  try {
    let email = _email
    let token = _token
    if (email == null)
      email = getCookie("current_user_email");
    if (token == null)
      token = getCookie("current_ident_token");
    const res = await api(
      `${siteConfig.apiUrl}${url}`,
      "POST",
      {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-User-Email": email,
        "X-User-Token": token,
      },
      JSON.stringify(data)
    );
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function apiPut(url, data) {
  try {
    const res = await api(
      `${siteConfig.apiUrl}${url}`,
      "PUT",
      {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      JSON.stringify(data)
    );
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function apiPutToken(url, data, _email=null, _token=null) {
  try {
    let email = _email
    let token = _token
    if (email == null)
      email = getCookie("current_user_email");
    if (token == null)
      token = getCookie("current_ident_token");
    const res = await api(
      `${siteConfig.apiUrl}${url}`,
      "PUT",
      {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-User-Email": email,
        "X-User-Token": token,
      },
      JSON.stringify(data)
    );
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function apiDelete(url, data) {
  try {
    const res = await api(
      `${siteConfig.apiUrl}${url}`,
      "DELETE",
      {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      JSON.stringify(data)
    );
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function apiDeleteToken(url, data, _email=null, _token=null) {
  try {
    let email = _email
    let token = _token
    if (email == null)
      email = getCookie("current_user_email");
    if (token == null)
      token = getCookie("current_ident_token");
    const res = await api(
      `${siteConfig.apiUrl}${url}`,
      "DELETE",
      {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-User-Email": email,
        "X-User-Token": token,
      },
      JSON.stringify(data)
    );
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

export {
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
  apiGetToken,
  apiPostToken,
  apiPutToken,
  apiDeleteToken,
};
