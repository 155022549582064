import React from 'react';
import styled from "styled-components";
import {Link, useNavigate} from "react-router-dom";
import {setCookie} from "../../service/cookie";
import {useSetting} from "../../provider/setting";

const AuthHeader = () => {
  const [,dispatch] = useSetting()
  const navigate = useNavigate();

  const logoutHandle = () => {
    dispatch({ type: "SET", settingName: "auth", settingData: null });
    setCookie("signin_session_id", '', 0);
    setCookie("signin_merchant_code", '', 0);
    setCookie("signin_merchant_id", '', 0);
    setCookie("signin_merchant_user_id", '', 0);
    setCookie("signin_current_user_id", '', 0);
    setCookie("signin_merchant_name", '', 0);
    setCookie("current_ident_token", '', 0);
    setCookie("current_user_email", '', 0);
    setCookie("selected_merchant_address", '', 0);
    setCookie("current_merchant_wallet_address", '', 0);
    setCookie("selected_merchant_bool_cannabis_merchant", '', 0);
    setCookie("veev_session_id", '', 0);
    setCookie("veev_current_user_id", '', 0);
    setCookie("veev_current_user_email", '', 0);
    setCookie("veev_current_user_ident_token", '', 0);
    navigate("/signin");
  }

  return (
    <AuthHeaderWrap>
      <div className="container">
        <div className="header-wrap">
          <h1 className="logo">
            <Link to="/bank/transactions/checkout">VEEV</Link>
          </h1>

          <nav>
            <ul>
              <li><Link to="/bank/transactions/checkout">Transactions</Link></li>
              <li><Link to="/bank/store/profile">Store</Link></li>
              <li><Link to="/bank/crm/activity">CRM</Link></li>
              <li><a onClick={logoutHandle}>Logout</a></li>
            </ul>
          </nav>

        </div>
      </div>
    </AuthHeaderWrap>
  );
};

export const AuthHeaderWrap = styled.div`
  background-color: #cc0000;
  z-index: 1030;
  height: 62px;
  position: sticky;
  top: 0;
  
  .container {
    height: 100%;
    .header-wrap {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: space-between;
      
      h1{
        line-height: 1;
        margin-bottom: 0;
        margin-top: 0;
        color: #ffffff;
        font-size: 1.5rem;
        font-weight: 200;
        letter-spacing: 1px;
        font-family: Catamaran,Helvetica,Arial,sans-serif;
        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
      
      nav {
        ul {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          grid-gap: 42px;
          li {
            list-style: none;
            margin-bottom: 0;
            line-height: 1;
            a {
              font-size: 1rem;
              line-height: 1.5;
              color: #ffffff;
              text-decoration: none;
              font-family: Lato,Helvetica,Arial,sans-serif;
              letter-spacing: 2px;
              touch-action: manipulation;
            }
          }
        }
      }
    }
  }
`

export default AuthHeader;
