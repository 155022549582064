import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import HeaderHome from "../../components/header/headerHomePage";
import FooterHome from "../../components/footer/footerPage";
import { Styles } from '../../layouts/style/homeStyle'

const Checkin = () => {
  const [slider, setSlider] = useState('')
  const sliders = useMemo(() => [
    "https://staging.veev.app/img/veev_qr_activity1.png",
    // "https://staging.veev.app/img/veev_app_receipt1.png",
    "https://staging.veev.app/img/veev_qr_code1.png",
    // "../assets/img/veev_connect.png",
    // "https://staging.veev.app/img/veevid.png",
    //"https://staging.veev.app/img/veevnft1.png",
    "../assets/img/checkin_access.png",
    //"https://staging.veev.app/img/veev_events_signup.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider(sliders[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders.length)
        index = 0
      setSlider(sliders[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders])

  const [slider2, setSlider2] = useState('')
  const sliders2 = useMemo(() => [
    "https://staging.veev.app/img/veev_qr_activity1.png",
    "https://staging.veev.app/img/veevid.png",
    "../assets/img/veev_connect.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider2(sliders2[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders2.length)
        index = 0
      setSlider2(sliders2[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders2])

  const [slider3, setSlider3] = useState('')
  const sliders3 = useMemo(() => [
    "https://staging.veev.app/img/veev_qr_activity1.png",
    "https://staging.veev.app/img/veevid.png",
    "../assets/img/veev_connect.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider3(sliders3[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders3.length)
        index = 0
      setSlider3(sliders3[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders3])

  const [slider4, setSlider4] = useState('')
  const sliders4 = useMemo(() => [
    "https://staging.veev.app/img/veev_sales.png",
    "https://staging.veev.app/img/veev_event_signups.png",
    "https://staging.veev.app/img/veev_marketing.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider4(sliders4[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders4.length)
        index = 0
      setSlider4(sliders4[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders4])

  const [slider5, setSlider5] = useState('')
  const sliders5 = useMemo(() => [
    "https://staging.veev.app/img/veev_checkin.png",
    "https://staging.veev.app/img/veev_event_signups.png",
    "https://staging.veev.app/img/veev_marketing.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider5(sliders5[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders5.length)
        index = 0
      setSlider5(sliders5[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders5])

  const [slider6, setSlider6] = useState('')
  const sliders6 = useMemo(() => [
    "https://staging.veev.app/img/veev_pos2.png",
    "https://staging.veev.app/img/veev_pos3.png",
    "https://staging.veev.app/img/veev_pos4.png",
    "https://staging.veev.app/img/veev_sales.png",
    "https://staging.veev.app/img/veev_pos5.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider6(sliders6[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders6.length)
        index = 0
      setSlider6(sliders6[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders6])

  return (
    <>
    <HeaderHome />
    <Styles>
      <div className="guide">
        <section className="features">
          <div className="container container--main">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '75px'}}>
                    <img src="../assets/img/veevqr.png" style={{ marginTop: 10, marginBottom: 20, height: 120 }} alt="VEEV" />
                      <p className="title1">Scan &</p>
                      <p className="title">Checkin</p>
                      <div className="section-heading text-center submit-button">
                          <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
                          <img src="../assets/img/appstore-apple-light.svg" style={{ marginTop: 20, height: 38 }} alt="VEEV" />
                          </a>
                      </div> 
                    </div>
                    <div className="col-lg-1 dv-loadmoney">
                    </div>
                    <div className="col-lg-3 dv-loadmoney text-center" style={{ paddingBottom: '0px', marginBottom: '0' }}>
                      <div className="device-container">
                        <div className="device-mockup iphone6 portrait gold">
                          <div className="device" style={{marginTop: 20, marginBottom: 0}}>
                            <div className="screen">	
                              <div className="slideshow-container">
                                <div className="mySlides">
                                  <div className="veevslogan">
                                    <img src={slider} alt="VEEV Checkin" className="img-fluid2" />
                                  </div>
                                </div>
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4" style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                      <div className="row">
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '25px', paddingLeft: '50px', color: 'grey' }}>
                        <p className="title3black">Bars & Clubs</p>
                        One click signup, pay, ID verification and ticketing {/* issuance and redemption */} system for bars, clubs, events & access controlled venues <a href="#checkin" style={{ color: '#cc0000' }}>more >></a> 
                        </div>
                        <div style={{ width: '100%', paddingBottom: '25px', paddingLeft: '50px', color: 'grey' }}> 
                        <p className="title3black">Events</p>
                        One click pay and verification solution eliminating ID and payment fraud for on & offline stores <a href="#checkout" style={{ color: '#cc0000' }}>more >></a>
                        </div>
                        <div style={{ width: '100%', paddingBottom: '0px', paddingLeft: '50px', color: 'grey' }}> 
                        <p className="title3black">Appointments</p>
                        One click contact sharing, marketing & promotion system for marketers, businesses and influencers <a href="#connect" style={{ color: '#cc0000' }}>more >></a>
                        </div>
                        {/* <div style={{ width: '100%', paddingBottom: '0px', paddingLeft: '50px', color: 'grey' }}> 
                        <p className="title3black">Rewards</p>
                        Reviews from real customers and build loyalty with <a href="#rewards" style={{ color: '#cc0000' }}>Rewards</a> >>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-center" style={{padding: 10}}>
            <a href="#checkin">
            <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '70px', marginLeft: '10px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
            </a>
            </div>
          </div>
        </section>

        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="checkin">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <p className="title1">Checkin</p><br></br>
                  <p className="title3black text-center">One Click ID Verification, Signup & RSVP System</p><br></br>    
                </div>
              </div>
            </div>
          </div>
    
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3" style={{paddingTop: 33}}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/checkin_access.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                        <p className="title4">ID Verification</p>
                        Age & ID Verificatio for bars, clubs and access controlled venues.
                        </div>  
                    </div>
                  </div>
                  <div className="col-lg-2 dv-loadmoney">
                  </div>
                  <div className="col-lg-3 dv-loadmoney" style={{paddingTop: 33}}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/checkin_event.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                        <p className="title4">Registration</p>
                        One click signup, ticketing & payments for Events, Clubs & Venues.
                        </div>  
                    </div>
                  </div>
                  <div className="col-lg-1 dv-loadmoney">
                  </div>  
                  <div className="col-lg-3" style={{paddingTop: 33}}>   
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/checkin_connect.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center" style={{paddingTop: 0}}>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '20px', color: 'grey' }}> 
                        <p className="title4">Appointments & Reviews</p>
                        Appointment booking for businesses and customer reviews. 
                      </div>  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-heading text-center submit-button" style={{ paddingTop: '35px', paddingBottom: '30px'}}>
            <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
            <img src="../assets/img/appstore-apple-light.svg" style={{ marginTop: 20, height: 38 }} alt="VEEV" />
            </a>
          </div>    
        </section>
      </div>
    </Styles>
    <FooterHome />
  </>  
  )
}
export default Checkin