import { apiGet, apiGetToken, apiPostToken, apiPutToken } from './index'

async function getAll() {
  try {
    const res = await apiGet("groups");
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

async function update(id, data) {
  try {
    const res = await apiPutToken(`groups/${id}`, data);
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

async function getAllWithMerchantId(merchantId) {
  try {
    const res = await apiGetToken(`merchants/${merchantId}/groups`);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function sendAnnouncement(data) {
  try {
    const res = await apiPostToken("groups/announcement", data);
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}


export {
  getAll,
  getAllWithMerchantId,
  sendAnnouncement,
  update
}
