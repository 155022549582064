import React from 'react'
import { Link } from "react-router-dom";
import { FavoriteBorder, StarBorder } from "@material-ui/icons";
import {Styles} from '../../layouts/style/nftBoardStyle'

const Board = (props) => {
  const {nft, index} = props

  return (
    <Styles>
      <Link className="store_container nft-item" to={`/nfts/${index}`}>
        <div className="nft-action">
          <FavoriteBorder className="action-item" />
          <StarBorder className="action-item" />
        </div>
        <section className="item-image">
          <img src={nft.image} />
        </section>
        <section className="nft-info">
          <div className="nft-collection"> {nft.name} </div>
          <div className="nft-code"> BattleBooba #1871 </div>
          <div className="nft-price"> 0.5 </div>
        </section>
      </Link>
    </Styles>
  );
}
export default Board
