import React, {useState} from 'react';
import StoreLayout from "../../../../layouts/store";
import ItemsSidebar from "./sidebar";
import {useSetting} from "../../../../provider/setting";
import {createCategoryApi} from "../../../../api/api";
import {useNavigate} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import SubmitButton from "../components/SubmitButton";

const StoreCategoryCreatePage = () => {
  const navigate = useNavigate();
  const [setting] = useSetting()
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const onsubmit = () => {
    console.log(setting?.auth?.signin_merchant_code)
    createCategoryApi(setting?.auth?.signin_merchant_code, {name, description})
      .then(()=>{
        NotificationManager.success("Successfully created a category!", "Success", 3000)
        navigate('/bank/store/categories')
      })
      .catch((e)=>{
        NotificationManager.error("An error was created. try again later!", "Error", 3000)
      })
  }

  return (
    <StoreLayout>
      <div className="row">
        <div className="col col-lg-3">
          <ItemsSidebar/>
        </div>
        <div className="col col-lg-9">
          <h4 className="mb-4">Create Category</h4>

          <div className="row">
            <div className="col col-lg-12">
              <label htmlFor="name">Category Name*</label>
              <input
                value={name}
                onChange={(e)=>{setName(e.target.value)}}
                name="name"
                id="name"
                className="form-control"
                placeholder="Enter Category Name"
                type="text"/>
            </div>
            <div className="col col-lg-12">
              <label htmlFor="name">Category Description</label>
              <textarea
                value={description}
                onChange={(e)=>{setDescription(e.target.value)}}
                name="description"
                id="description"
                className="form-control"
                rows={4}
                placeholder="Enter Category Description"
                type="text"/>
            </div>
          </div>

          <div className="mt-5">
            <SubmitButton
              disabled={!name}
              onClick={onsubmit}
              type="primary"
              size="large">
              Create Category
            </SubmitButton>
          </div>
        </div>
      </div>
    </StoreLayout>
  );
};

export default StoreCategoryCreatePage;
