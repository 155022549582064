import styled from "styled-components";

export const Styles = styled.div`
  .stores {
    section {
      .store_container {
        text-decoration: none;
        #store_a {
          margin: 10px 0px;
            .store_logo_wrapper {
              min-height: 210px;
              max-height: 210px;
              background-size: auto 210px;
              background-repeat: no-repeat;
              background-position: center center;
              border: solid 1px #cecece;
              border-radius: 5px;
            }
          .store_name_address {
            .firstChild {
            }
            .secondChild {
              min-width: 50px;
            }
          }
        }
      }
    }
  }
  
  .container {
    padding-top: 30px;
    
    .card {
      margin-bottom: 12px;
      border: none;
      background: #f0f0f0;
      border-radius: 12px;
      
      h4 {
        font-weight: 700;
        font-size: 24px;
      }
      
      p {
        margin-top: 12px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.2;
      }
    }
  }
`;

export const ModalStyles = styled.div`
  div.modal-image {
    min-height: 300px;
    background-color: #cfcfcf;
    img {
      width: 100%;
    }
  }
`;