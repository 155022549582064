import styled from "styled-components";

export const Styles = styled.div`
  div.guide {
    section.cta {
      box-shadow: 0px 5px 50px 0px #00000026;
      .title {
        padding-bottom: 30px;
        font-weight: bold;
        font-size: 30pt;
        color: #4d4d4d;
      }
      .subtitle {
        font-weight: bold;
        font-size: 20pt;
        color: #000;
      }
    }
    section.features-gray {
      background: url(), -webkit-linear-gradient(to top, #f6f6f6, #f6f6f6);
      .title {
        font-weight: bold;
        font-size: 30pt;
        color: #4d4d4d;
      }
    }
    section.features-white {
      // background: url(), -webkit-linear-gradient(to top, #f6f6f6, #f6f6f6);
      background: url(), linear-gradient(to top, #fff, #fff);
      .title {
        font-weight: bold;
        font-size: 20pt;
        color: #000;
      }
    }
    section.download {
      padding: 0px 0px 0px 0px;
      div.image {
        padding: 0px 0px 0px 0px;
        img {
          width: 100%;
          height: auto;
        }
      }
      div.content {
        padding: 0px 0px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        p.title {
          font-weight: bold;
          font-size: 40pt;
          color: #fff;
        }
        p.subtitle {
          font-weight: bold;
          font-size: 20pt;
          color: #fff;
        }
      }
    }
    img.wallet {
      height: 60px;
    }
  }
`;