import React, {Fragment, useEffect, useState} from 'react';
import StoreLayout from "../../../../layouts/store";
import ItemsSidebar from "./sidebar";
import {useNavigate} from "react-router-dom";
import {useSetting} from "../../../../provider/setting";
import {deleteItemApi, getItemsApi} from "../../../../api/api";
import {Avatar, Button, Modal, Table} from "antd";
import {Icon} from "@iconify/react";
import {NotificationManager} from "react-notifications";
import SubmitButton from "../components/SubmitButton";

const StoreItemsPage = () => {
  const [setting] = useSetting();
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const [items, setItems] = useState([]);

  const getHandle = () => {
    if(setting?.auth?.signin_merchant_code) {
      getItemsApi(setting?.auth?.signin_merchant_code)
        .then(({data}) => {
          setItems(data.map((i)=>{
            i.key=i.id;
            return i
          }));
        })
        .catch((e)=>{console.warn(e, "ITEMS")})
    }
  }

  console.log(items)

  const deleteHandle = (item) => {
    modal.confirm({
      title: <h5>Delete Item!</h5>,
      icon: <Icon style={{width: 60, height: 60, color: "#cc0000"}} icon="ep:warn-triangle-filled" />,
      content: <p>Are you sure to delete <b>{item.name}</b> item?</p>,
      okText: 'Delete',
      cancelText: 'Cancel',
      okType: "danger",
      onOk: () => {
        console.log(item)
        deleteItemApi(setting?.auth?.signin_merchant_code, item.id)
          .then(() => {
            getHandle();
            NotificationManager.success("Successfully deleted!", "Success", 3000)
          })
          .catch((e)=>{
            console.warn(e);
            NotificationManager.error("An error was created. try again later!", "Error", 3000)
          })
      },
    });
  }

  const columns = [
    {
      title: "",
      width: 110,
      key: "id",
      render: (i) => {
        return (
          <Avatar size={100} shape="square" src={i.picture_url}/>
        )
      }
    },
    {
      title: "Details",
      key: "name",
      render: (i) => {
        return (
          <Fragment>
            <h4>{i.name}</h4>
            <p>{i.description}</p>
          </Fragment>
        )
      }
    },
    {
      title: "Category",
      dataIndex: "category_id",
      key: "category"
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (i) => (`$${parseFloat(i).toFixed(2)}`)
    },
    {
      title: "",
      width: 100,
      align: "center",
      render: (i) => {
        return (
          <Fragment>
            <Button
              onClick={()=>{navigate(`/bank/store/items/edit/?id=${i.id}`)}}
              className="icon-button"
              style={{marginRight: 6}}
              type="link" >
              <Icon color="#006000" icon="mdi:circle-edit-outline"/>
            </Button>
            <Button
              onClick={()=>{deleteHandle(i)}}
              className="icon-button"
              type="link"
              danger>
              <Icon icon="material-symbols:delete-outline-sharp"/>
            </Button>
          </Fragment>
        )
      }
    },
  ]

  useEffect(()=>{
    getHandle()
  }, [setting?.auth?.signin_merchant_code])


  return (
    <StoreLayout>
      <div className="row">
        <div className="col col-lg-3">
          <ItemsSidebar/>
        </div>
        <div className="col col-lg-9">
          <h4>Items</h4>
          <div className="mt-5">
            <SubmitButton
              ghost
              size="large"
              type="primary"
              onClick={()=>{navigate('/bank/store/items/create')}}>
              New Offering
            </SubmitButton>
          </div>
          <Table
            dataSource={items}
            columns={columns}
            pagination={{
              pageSize: 4
            }}
          />
        </div>
      </div>
      {contextHolder}
    </StoreLayout>
  );
};

export default StoreItemsPage;
