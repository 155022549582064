import React, {useState, useEffect, Fragment} from 'react'
import {useParams} from 'react-router-dom'
import {Dialog} from '@material-ui/core'
import Header from "../../../components/header/headerHomePage";
import Footer from "../../../components/footer/footerEmptyPage";
import {getAll as getStores} from "../../../api/stores";
import {useAsync} from "../../../service/utils";
import {Styles, ModalStyles} from "../../../layouts/style/storeDetailStyle"
import {isEmpty, getRandomLowString} from "../../../service/string";
import CheckoutPage from "./checkout";
import {Avatar, Card, Checkbox, Space} from "antd";
import {getAddonsApi} from "../../../api/api";

const {Meta} = Card

const NavbarFoodOption = (props) => {
    const {categories} = props
    const [fixed, setFixed] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 139) {
            setFixed(true);
        } else if (scrolled <= 139) {
            setFixed(false);
        }
    };

    window.addEventListener("scroll", toggleVisible);

    return (
        <ul id="nav_bar_food_options" style={fixed ? {position: 'fixed', top: 59} : {position: 'absolute', top: 160}}>
            {categories.map((category, index) => (
                <li className="nav_bar_foodOptionsLi" key={index}><a className="foodOptions_a"
                                                                     href={`#${category.category_id}`}> {category.name} </a>
                </li>
            ))}
        </ul>
    );
}

const Detail = () => {
    const {data, status, error, run} = useAsync({
        status: "idle",
    });
    const {merchantCode} = useParams()
    const [isVeevCheckout, setIsVeevCheckout] = useState(false)
    const [store, setStore] = useState({})
    const [categories, setCategories] = useState([])
    const [items, setItems] = useState([])
    const [item, setItem] = useState({})
    const [quantity, setQuantity] = useState(1)
    const [orderPrice, setOrderPrice] = useState(0)
    const [modalActive, setModalActive] = useState(false)
    const [activeCheck, setActiveCheck] = useState(false)
    const [extras, setExtras] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)
    const [checkoutItems, setCheckoutItems] = useState([])
    const [qrSrc, setQrSrc] = useState('')
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [cardNumber, setCardNumber] = useState("")
    const [expiry, setExpiry] = useState("")
    const [cvv, setCvv] = useState("")
    const [addons, setAddons] = useState([])

    const handleModalOpen = (index) => {
        if (items.length !== 0) {
            let _item = items[index]
            setItem(_item)
            setQuantity(1)
            setOrderPrice(parseFloat(_item.price) * 1)
            setExtras([])
        }
        setModalActive(true)
    }
    const handleModalClose = () => {
        setModalActive(false)
    }
    const addQuantity = () => {
        const _quantity = quantity + 1
        setOrderPrice(parseFloat(item.price) * _quantity)
        setQuantity(_quantity)
    }
    const checkExtra = (e, extra) => {
        if (e.target.checked) {
            let tmp = extras.filter((item) => item.name === extra.name)
            if (tmp.length === 0)
                setExtras([...extras, extra])
        }
    }
    const minusQuantity = () => {
        const _quantity = Math.max(1, quantity - 1);
        setOrderPrice(parseFloat(item.price) * _quantity)
        setQuantity(_quantity)
    }
    const handleAdd = () => {
        const _checkoutItem = {
            id: item.id,
            order: {
                name: item.name,
                price: item.price,
                quantity: quantity,
                total: parseFloat(item.price) * quantity
            },
            extras: extras
        }
        const _checkoutItems = [...checkoutItems, _checkoutItem];
        setCheckoutItems(_checkoutItems)
        const _totalPrice = calcTotal(_checkoutItems)
        setTotalPrice(_totalPrice)
        setActiveCheck(true)
        setModalActive(false)
    }
    const handleDelete = (index) => {
        const _checkoutItems = [...checkoutItems.slice(0, index), ...checkoutItems.slice(index + 1)]
        setCheckoutItems(_checkoutItems)
        setTotalPrice(calcTotal(_checkoutItems))
    }
    const calcTotal = (items) => {
        let total = 0
        items.forEach((item) => {
            total += parseFloat(item?.order?.price) * item?.order?.quantity
            item?.extras?.forEach((extra) => {
                total += parseFloat(extra.price)
            })
        })
        return total
    }
    const handleCheckout = () => {
        const receiptUuid = getRandomLowString(8) + "-" + getRandomLowString(4) + "-" + getRandomLowString(4) + "-" + getRandomLowString(4) + "-" + getRandomLowString(12)
        let receipt = []
        checkoutItems.forEach((item) => {
            let receiptData = item?.order?.quantity
            item?.extras?.forEach((extra) => {
                receiptData += "|||" + extra?.id
            })
            receipt = [...receipt, {[item.id]: `${receiptData}`}]
        })
        const data = [
            "veev-tx",
            store.legal_name,
            totalPrice,
            `${store.street}, ${store.city}, ${store.state}, ${store.zip}`,
            receiptUuid,
            merchantCode,
            store?.wallet?.address,
            true,
            '0.00',
            0,
            parseFloat(store.tax_info / 10),
            JSON.stringify(receipt)
        ]
        const data_str = data.join("|||");
        const qr_src =
            "https://chart.googleapis.com/chart?cht=qr&chs=200x200&chld=H7C0&chl=" +
            data_str;
        setQrSrc(qr_src)
        setIsVeevCheckout(true)
    }
    const handleBack = () => {
        setQrSrc('')
        setIsVeevCheckout(false)
    }

    useEffect(() => {
        run(getStores());
    }, [run]);

    useEffect(() => {
        if (status === "resolved") {
            data.forEach((item) => {
                if (item.merchant_code === merchantCode) {
                    let _categories = item.categories || []
                    _categories = _categories.map((category) => {
                        let category_id = category.name
                        category_id = category_id.toLowerCase();
                        category_id = category_id.replaceAll(" ", "_");
                        category_id = category_id.replaceAll("'", "");
                        category.category_id = category_id;
                        return category
                    })
                    setCategories(_categories)
                    setItems(item.items || [])
                    setStore(item)
                }
            })
        } else if (status === "rejected") {
            console.log(error);
        }
    }, [status, merchantCode]);


    useEffect(() => {
        console.log(merchantCode)
        getAddonsApi(merchantCode)
            .then(({data}) => {
                console.log(data, "ADDONS")
                setAddons(data.map((i) => {
                    i.key = i.id;
                    return i;
                }))
            })
            .catch((e) => console.warn(e))
    }, [merchantCode])


    return (
        <>
            <Header/>
            {/* <Styles>
        <main id="container" className='store'>
          {isVeevCheckout ?
            (
		<div styles={{display: "flex"}}>
                    <CheckoutPage 
			amount={totalPrice}
			merchantId={store.id}
                    />
		</div>
            ): (
              <>
                <div id="mySidenav" className="sidenav" style={activeCheck?{width: 374}:{width: 0}}>
                  <span className="closebtn" onClick={() => setActiveCheck(false)}>&times;</span>
                  <div id="checkout_menu">
                    <section id="checkout_items_parent">
                      <div className="sidenav_footer">
                        <span className="continue_shopping"> Continue Ordering  </span>
                        <h3 id="checkout_order_title"> Your Order </h3>
                        <label htmlFor="submit_form" id="items_checkout" onClick={handleCheckout}>
                          <span id="checkout_span">
                            Checkout
                          </span>
                          <span id="checkout_total_dollar_sign"> $ </span>
                          <label id="items_checkout_total" htmlFor="checkout_order_total"> { totalPrice.toFixed(2) } </label>
                        </label>
                      </div>
                    </section>
                    <section id="checkout_items_list">
                      <section className="checkout_items_header">
                        <span id="items_header">
                          Items
                        </span>
                      </section>
                      {checkoutItems.map((checkoutItem, i) => (
                        <div className="checkout_item" key={i}>
                          <div className="checkout_item_inner">
                            <label className="checkout_item_quantity" htmlFor="item_quantity_input2">{ checkoutItem?.order?.quantity }</label>
                            <div className="checkout_item_details">
                              <label className="checkout_item_name" htmlFor="item_name_input2"> {checkoutItem?.order?.name} </label>
                              <span className="dollar_sign">$</span>
                              <label className="checkout_item_price" htmlFor="item_price_input2">{ checkoutItem?.order?.total }</label>
                            </div>
                          </div>
                          <ul className="checkout_item_extras">
                            {checkoutItem?.extras?.map((extra, j) => (
                              <li className="checkout_item_details" key={j}>
                                <label className="extra_item_name" htmlFor="store_extra_name02">{ extra?.name }</label><span className="dollar_sign">$</span>
                                <label className="extra_item_price" htmlFor="store_extra_price02">{ extra?.price }</label>
                              </li>
                            ))}
                          </ul>
                          <section className="checkout_item_remove" onClick={() => handleDelete(i)}>Delete</section>
                        </div>
                      ))}
                    </section>
                  </div>
                </div>
                <section id="main_content">
                  <section id="store_info_wrapper">
                    <section>
                      <h1>{store.legal_name}</h1>
                      <section id="store_street_address">  
                        <h3 className="store_address_complete"> { store.street }, </h3> 
                        <h3 className="store_address_complete"> { store.city } </h3> 
                        <h3 className="store_address_complete"> { store.state }, </h3> 
                        <h3 className="store_address_complete"> { store.zip }  </h3>
                      </section>
                    </section>
                  </section>
                  <NavbarFoodOption categories={categories} />
                  <div id="store_menu">
                    <section id="category_wrapper">
                      {categories.map((category, i) => (
                        <section id="store_category" key={i}>
                          <h2 id={category.category_id}> {category.name } </h2>
                          <ul>
                            {items.map((item, j) => (
                              item.category_id === category.id ?
                              <li className="store_item" key={j} onClick={() => handleModalOpen(j)}>
                                <img className="item_image" src={ item.picture_url } alt={ item.name } />
                                <div className="store_name_price_details">
                                  <div>
                                    <h3 className="category_item_name"> { item.name } </h3>
                                  </div>
                                  <div>
                                    <h3> $ </h3>
                                    <h3 className="category_item_price"> { parseFloat(item?.price).toFixed(2) } </h3>
                                  </div>
                                </div>
                              </li>:
                              ""
                            ))}
                          </ul>
                        </section>
                      ))}
                    </section>
                  </div>
                </section>
              </>
            )
          }
        </main>
        <Dialog open={modalActive} 
          onClose={handleModalClose} aria-labelledby="form-dialog-title">
          {!isEmpty(item) && 
            <ModalStyles>
              <section>
                <div className="modal-image">
                  <img src={item.picture_url} />
                </div>
                <section id='item_name' className='text-center'>
                  {item.name}
                </section>
                <section className="checkout_row">
                  <span id="header_items">
                    Add-Ons
                  </span>
                </section>
                <section id="extras_wrapper">
                  {store?.menu_extras?.map((extra, index) => (
                    <section className="extras_row" key={index}>
                      <label className="checkbox_plusName">
                        <input className="fieldset_input" type="checkbox" onChange={(e) => checkExtra(e, extra)} />
                        <label id="extra_name"> {extra.name} </label>
                      </label>
                      <label className="dollar_plusPrice">
                        <span className="dollar_main"> $ </span>
                        <label id="extra_price"> {parseFloat(extra?.price).toFixed(2)} </label>
                      </label>
                    </section>
                  ))}
                </section>
                <section className="checkout_row">
                  <div className="menu_item_quantity">
                    <i id="decrease_arrow" className="fa fa-minus" onClick={() => minusQuantity()}> </i>
                    <label id="item_quantity" htmlFor="item_quantity_input"> {quantity} </label>
                    <i id="increase_arrow" className="fa fa-plus" onClick={() => addQuantity()}> </i>
                  </div>
                  <div className="menu_item_price">
                    <span className="dollar_main"> $ </span>
                    <label id="item_price" htmlFor="item_price_input">{ orderPrice.toFixed(2) }</label>
                  </div>
                </section>
                <div id="store_form_action">
                  <a id="continue_shopping"> Continue Shopping  </a>
                  <label htmlFor="submit-form" className="store_form_button text-center" onClick={handleAdd}> Add to Cart </label>
                </div>
              </section>
            </ModalStyles>
          }
        </Dialog>
      </Styles>*/}

            <Styles>
                <div className="container">
                    {categories.map((category, i) => (
                        <section id="store_category" key={i}>
                            <h2 id={category.category_id}> {category.name} </h2>
                            <Space size={24}>
                                {items.map((item, j) => (
                                    item.category_id === category.id ?
                                        (
                                            <Card
                                                onClick={() => handleModalOpen(j)}
                                                key={j}
                                                hoverable
                                                style={{width: 240}}
                                                cover={
                                                    <img
                                                        alt="example"
                                                        width={240}
                                                        height={240}
                                                        style={{objectFit: "cover"}}
                                                        src={item.picture_url}/>}>
                                                <Meta
                                                    title={item.name}
                                                    description={`$${parseFloat(item?.price).toFixed(2)}`}/>
                                            </Card>
                                        ) :
                                        ""
                                ))}
                            </Space>
                        </section>
                    ))}
                </div>

                <Dialog open={modalActive}
                        onClose={handleModalClose} aria-labelledby="form-dialog-title">
                    {!isEmpty(item) &&
                        <ModalStyles>
                            <section>
                                <div className="modal-image">
                                    <img src={item.picture_url}/>
                                </div>
                                <section id='item_name' className='text-center'>
                                    {item.name}
                                </section>
                                <section style={{padding: "12px"}}>
                                    <h5 id="header_items">
                                        Add-Ons
                                    </h5>
                                    <div>
                                        {
                                          addons.map((addon, index) => (
                                              JSON.parse(categories.find((c) => c.id === item.category_id)?.other || "[]").includes(addon.id) ?
                                              <Checkbox style={{display: "flex"}} key={`addon-${index}`}>
                                                    {addon.name} - ${parseFloat(addon.price).toFixed(2)}
                                              </Checkbox> : <Fragment key={`addon-${index}`}></Fragment>
                                            ))
                                        }
                                    </div>
                                </section>
                                <section id="extras_wrapper">
                                    {store?.menu_extras?.map((extra, index) => (
                                        <section className="extras_row" key={index}>
                                            <label className="checkbox_plusName">
                                                <input className="fieldset_input" type="checkbox"
                                                       onChange={(e) => checkExtra(e, extra)}/>
                                                <label id="extra_name"> {extra.name} </label>
                                            </label>
                                            <label className="dollar_plusPrice">
                                                <span className="dollar_main"> $ </span>
                                                <label id="extra_price"> {parseFloat(extra?.price).toFixed(2)} </label>
                                            </label>
                                        </section>
                                    ))}
                                </section>
                                <section className="checkout_row">
                                    <div className="menu_item_quantity">
                                        <i id="decrease_arrow" className="fa fa-minus"
                                           onClick={() => minusQuantity()}> </i>
                                        <label id="item_quantity" htmlFor="item_quantity_input"> {quantity} </label>
                                        <i id="increase_arrow" className="fa fa-plus"
                                           onClick={() => addQuantity()}> </i>
                                    </div>
                                    <div className="menu_item_price">
                                        <span className="dollar_main"> $ </span>
                                        <label id="item_price"
                                               htmlFor="item_price_input">{orderPrice.toFixed(2)}</label>
                                    </div>
                                </section>
                                <div id="store_form_action">
                                    <a id="continue_shopping"> Continue Shopping </a>
                                    <label htmlFor="submit-form" className="store_form_button text-center"
                                           onClick={handleAdd}> Add to Cart </label>
                                </div>
                            </section>
                        </ModalStyles>
                    }
                </Dialog>
            </Styles>
            <Footer/>
        </>
    )
}
export default Detail
