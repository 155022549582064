import React, { useEffect, useState } from 'react'
import {Dialog,} from '@material-ui/core'
import Header from "../../../components/header/headerHomePage";
import Footer from '../../../components/footer/footerPage'
import { getAll as getEvents } from '../../../api/events'
import { useAsync } from '../../../service/utils'
import { Styles, ModalStyles } from "../../../layouts/style/eventsStyle";
import { isEmpty } from '../../../service/string'
import siteConfig from '../../../config/site.config'
import Nav from '../nav'

const Events = () => {
  const { data, status, error, run } = useAsync({
    status: "idle",
  });
  const [events, setEvents] = useState([])
  const [event, setEvent] = useState({});
  const [modalActive, setModalActive] = useState(false);

  const handleModalClose = () => {
    setModalActive(false);
  };
  const handleModalOpen = (index) => {
    if (events.length !== 0) {
      let _event = events[index]
      const data = [
        "veev-events",
        1,
        _event.id,
        _event.merchant_id,
        "events",
        siteConfig.baseUrl + "/events/" + _event.id,
      ];
      const data_str = data.join("|||");
      const qr_src =
        "https://chart.googleapis.com/chart?cht=qr&chs=200x200&chld=H7C0&chl=" +
        data_str;
      _event.qr_src = qr_src;
      setEvent(_event);
    }
    setModalActive(true);
  };

  useEffect(() => {
    run(getEvents())
  }, [run])
  useEffect(() => {
    if (status === 'resolved') {
      const _events = data.map((item) => {
        let descriptions = []
        const event_description = item?.event_description
        if (event_description && event_description !== '') {
          descriptions = event_description.split('\n');
        }
        item.descriptions = descriptions
        return item
      })
      console.log(_events)
      setEvents(_events)
    }
    else if (status === 'rejected') {
      console.log(error)
    }
  }, [status])
  return (
    <>
      <Header />
      <Styles>
        <div className="events">
          <section className="container">
            <div className="tab-part">
              <Nav directory="events" />
              <div className="selected-merchant-name">
                Events
                <span className="selected-nav-top-active"> &gt; All </span>
              </div>
            </div>
            <div className="row h-100">
              <div className="col-md-12">
                <div className="devsite-section-nav">
                  <div className="row mt-sm-3">
                    <div className="col-md-12">
                      <p href="#" className="trx-left-nav active"></p>
                    </div>
                  </div>
                </div>
                <div className="">
                  {/* Main Transactions part begin. */}
                  <div className="devsite-section-article">
                    <ul className="row">
                      {events.map((event, index) => (
                        <div className="col-lx-4 col-lg-4 col-sm-12" key={index} onClick={() => handleModalOpen(index)}>
                          <li id="store_a" className="store_container">
                            <section className="store_logo_wrapper" style={{backgroundImage: `url(${event.event_image})`}}>
                              {/* <img className="store_logo" src={event.event_image} /> */}
                            </section>
                            <section className="store_name_address" style={{justifyContent: 'space-around'}}>
                              <section className="firstChild">
                                <h2> {event.event_title} </h2>
                                <section id="store_street_address">
                                  <h3 className="store_address_complete"> {event.address}, {event.city}, {event.state}, {event.zip_code}</h3>
                                </section>
                              </section>
                              <section>
                                <div className="price">
                                  $ {event.start_price}
                                </div>
                              </section>
                            </section>
                          </li>
                        </div>
                      ))}
                    </ul>
                  </div>            
                </div>
              </div>
            </div>
          </section>
          <Dialog
            open={modalActive} 
            onClose={handleModalClose} 
            aria-labelledby="form-dialog-title"
          >
            {!isEmpty(event) && 
              <ModalStyles>
                <section>
                  <div className="modal-image">
                    <img src={event.event_image} />
                  </div>
                  <div className="qr-code">
                    <img src={event.qr_src} />
                  </div>
                  <section className="text-center content">		
                    <div className="title">{event.event_title}</div>
                    <div className="subtitle">Date and Time</div>
                    <div className="description">
                      {event.start_date}
                      {event.end_date}
                    </div>
                    <div className="subtitle">Location</div>
                    <div className="description">{event.address}</div>
                    <div className="description">{event.city}, {event.state} {event.zip_code}</div>
                    <div className="subtitle">Price</div>
                      <div className="description">
                        {parseInt(event.start_price) < 1 ?
                        "Free" : 
                        `$ ${event.start_price}`
                        }
                      </div>
                    <div className="subtitle">Details</div>
                      <div className="description">
                      {event.descriptions.map((item, index) => (
                        <p key={index}>
                          {item}
                        </p>
                      ))}
                      </div>
                  </section>
                </section>
              </ModalStyles>
            }
          </Dialog>
        </div>
      </Styles>
      <Footer />
    </>  
  )
}
export default Events
