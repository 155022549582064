export const NFTdata = [
  {
    name: "Arianna",
    price: 100,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_Cep0n5_bI-QvHTz_EMdN6s0uwXSXzJ6xTw&usqp=CAU",
  },
  {
    name: "CathyWood",
    price: 100,
    image:
      "https://gateway.pinata.cloud/ipfs/Qmbw2d2g8QCVjAQrLFBgJ12wDzkTQ6o1nDdtYRrV1mtByj/1.png",
  },
  {
    name: "Jennifer",
    price: 80,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRV2mWgbiBhbhkeCgDewQdOXtaPUSJwlM4Qjw&usqp=CAU",
  },
  {
    name: "Sam",
    price: 110,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwIRi1r1hP5C1MGNTk6RpGwXT-5FXHG9VPdfZGh6qWhQA2uyNn_84pFA--BdYM7u-bMUQ&usqp=CAU",
  },
  {
    name: "Valeria",
    price: 90,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-HY4yy6kDK7UVtGa8C6KJJHR44VUrACTKwQ&usqp=CAU",
  },
  {
    name: "Jo",
    price: 100,
    image:
      "https://www.freeiconspng.com/thumbs/unicorn-png/unicorn-png-picture-12.png",
  },
  {
    name: "Arianna",
    price: 100,
    image:
      "https://www.pngkey.com/png/full/44-448415_clipart-unicorn-transparent-background-unicorn-clipart-transparent-background.png",
  },
  {
    name: "Jennifer",
    price: 80,
    image:
      "https://st3.depositphotos.com/4155807/16049/v/950/depositphotos_160495106-stock-illustration-cute-cartoon-blue-unicorn-with.jpg",
  },
  {
    name: "Sam",
    price: 110,
    image:
      "https://www.pngkit.com/png/full/129-1294727_cartoon-unicorn-transparent-background.png",
  },
  {
    name: "Leah",
    price: 95,
    image:
      "https://i.pinimg.com/originals/bf/70/81/bf70816a84a2b638e26fda1f12a7b9c3.png",
  },
  {
    name: "Jo",
    price: 100,
    image:
      "https://cdn1.vectorstock.com/i/thumb-large/51/80/sleeping-unicorn-vector-595180.jpg",
  },
  {
    name: "Arianna",
    price: 100,
    image:
      "https://media.istockphoto.com/vectors/cute-unicorn-in-love-cartoon-vector-illustration-vector-id1282231613?k=20&m=1282231613&s=170667a&w=0&h=2pklb05zhJI4z-inEFrTOtSW1EDhOvCr2wN8wGel_KE=",
  },
  {
    name: "Jennifer",
    price: 80,
    image:
      "https://image.freepik.com/free-vector/cute-unicorn-with-rainbow-clouds-white-background_1308-64044.jpg",
  },
  {
    name: "Leah",
    price: 95,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNXTTsATHMcZ2gKfrXa6C0rP4S6p4UaHKyPw&usqp=CAU",
  },
  {
    name: "Jo",
    price: 100,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT510J230sMPbuZTMO3yXAyS1mMJHO8JuoMmA&usqp=CAU",
  },
  {
    name: "Valeria",
    price: 90,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSk583amLsHjBuvIlUUc109STkk0VohZERRQkOwsh6-wbXcHXEP9Zf4fIb-PGvqs8jrLHI&usqp=CAU",
  },
  {
    name: "Leah",
    price: 95,
    image:
      "https://www.transparentpng.com/thumb/unicorn/4ubMTg-unicorn-rainbow-pegasus-free-image-pixabay.png",
  },
  {
    name: "Valeria",
    price: 90,
    image:
      "https://2lu2u93dgza03aot753nn9nu-wpengine.netdna-ssl.com/wp-content/uploads/2019/02/unicorn.jpg",
  },
  {
    name: "Arianna",
    price: 100,
    image:
      "https://www.pngkey.com/png/full/44-448415_clipart-unicorn-transparent-background-unicorn-clipart-transparent-background.png",
  },
  {
    name: "Jennifer",
    price: 80,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIYaDj7F-GGqJdG8OykP5EE1t0shirIveNI7RnMwnvstuWIBipCpyTQBRbWSmd5zg4jGE&usqp=CAU",
  },
  {
    name: "Sam",
    price: 110,
    image:
      "https://www.pngkit.com/png/full/129-1294727_cartoon-unicorn-transparent-background.png",
  },
];