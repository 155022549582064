import S3 from 'react-aws-s3'

import siteConfig from '../config/site.config'

async function upload(file, filename) {
  const filepath = "/uploaded_event_image/" + filename;
  const ReactS3Client = new S3(siteConfig.s3Config)
  return ReactS3Client.uploadFile(file, filepath)
    .then(data => {
      if (data.status == 204) {
        return Promise.resolve(data)
      }
      else
        return Promise.reject({ message: 'error' })
    })
}

export {
  upload,
}
