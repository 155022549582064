import styled from "styled-components";

export const Styles = styled.div`
  .store {
    h1 {
      margin: 10px 0px;
    }
    #mySidenav {
      .closebtn {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 36px;
        color: #818181;
        display: block;
        transition: 0.3s;
        cursor: pointer;
      }
    }
  }
`;

export const ModalStyles = styled.div`
  section {
    width: 375px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &:-webkit-scrollbar {
      display: none;
    }
    div.modal-image {
      min-height: 250px;
      background-color: #cfcfcf;
      img {
        width: 100%;
        height: 250px;
      }
    }
    .checkout_row {
      width: 95%;
    }
    .extras_row {
      width: 95%;
    }
  }
`;