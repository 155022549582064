import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import { Styles } from '../../layouts/style/headerHomeStyle';
import {Icon} from "@iconify/react";

const HeaderHome = (props) => {
const {directory} = props
const {active} = props

  return (
    <Styles>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top header" id="mainNav">
        <div className="container">
          <Link className="navbar-brand js-scroll-trigger" to="/#"><img src="/../assets/img/veevlogo.png" height="25" width="*" /></Link>
          <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <i className="fa fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto" style={{height: '40px'}}>
              <li className="dropdown">
                <Link className={`nav-link ${active==='app'?'active':''}`} to='/app'>App</Link>
                <div className="dropdown-content">
                  <div className="drop-wrap">
                    <ul style={{width: '21%'}}>
                      <li><h4>Checkout</h4></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="mdi:user-outline"/> Order</Link></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="quill:creditcard"/> Pay</Link></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="material-symbols:local-shipping-outline"/> Shipping</Link></li>
                    </ul>
                    <ul style={{width: '21%'}}>
                      <li><h4>Checkin</h4></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="fluent:calendar-48-regular"/> Schedule</Link></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="icon-park-outline:party-balloon"/> Events</Link></li>
                    </ul>
                    <ul style={{width: '22%'}}>
                      <li><h4>Benefits</h4></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginLeft: 5, marginRight: 9, fontSize: 25}} icon="fontisto:mobile-alt"/> Recommendations</Link></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="mdi:megaphone-outline"/> Promotions</Link></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="solar:star-bold-duotone"/> Reviews</Link></li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <Link className={`nav-link ${directory==='solutions'?'active':''}`} to="/storelink" id="">StoreLink</Link>
                <div className="dropdown-content">
                  <div className="drop-wrap">
                    <ul style={{width: '21%'}}>
                      <li><h4>Checkout</h4></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="mdi:user-outline"/> Order</Link></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="quill:creditcard"/> Pay</Link></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="material-symbols:local-shipping-outline"/> Ship</Link></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="fluent-emoji-high-contrast:mobile-phone"/> POS</Link></li>
                      {/* <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="mdi:network-pos"/> POS</Link><br></br>In store Order & Pay</li> */}
                    </ul>
                    <ul style={{width: '21%'}}>
                      <li><h4>Checkin</h4></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="fluent:calendar-48-regular"/> Scheduling</Link></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="icon-park-outline:party-balloon"/> Events</Link></li>
                    </ul>
                    <ul style={{width: '22%'}}>
                      <li><h4>Marketing</h4></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="ic:outline-email"/> Email</Link></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="material-symbols:sms-outline"/> SMS</Link></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginLeft: 5, marginRight: 9, fontSize: 25}} icon="fontisto:mobile-alt"/> In-App</Link></li>
                      {/* <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="mdi:megaphone-outline"/> Promotions</Link><br></br>Get new customers</li> */}
                      {/* <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="ic:outline-email"/> <b>Email</b></Link><br></br>Keep customers informed</li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="material-symbols:sms-outline"/> <b>SMS</b></Link><br></br>Instantly nofify customers</li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="fontisto:mobile-alt"/> <b>In-App</b></Link><br></br>Engage customers directly</li> */}
                    </ul>
                    <ul style={{width: '22%'}}>
                      <li><h4>Operations</h4></li>
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="mdi:account-clock-outline"/> CRM</Link></li>
                      {/* <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="mdi:account-clock-outline"/> CRM</Link><br></br>Customer relations management</li> */}
                      {/* <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 6, fontSize: 25}} icon="ri:robot-2-line"/> AI</Link></li> */}
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="mdi:megaphone-outline"/> Promotions</Link></li>
                      {/* <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="mdi:megaphone-outline"/> Promotions</Link><br></br>Get new customers</li> */}
                      <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="solar:star-bold-duotone"/> Reviews</Link></li>
                      {/* <li><Link to="/storelink/#marketplace"><Icon style={{marginRight: 5, fontSize: 25}} icon="solar:star-bold-duotone"/> Reviews</Link><br></br>Let customers do the selling</li> */}
                      {/* <li><Link to="/storelink/#marketplace">In-App, QR, Tap to Pay<br></br>Payments</Link></li> */}
                    </ul>
                    <ul style={{width: '10%'}}>
                      <li><h4 style={{marginLeft: 5}}>Pricing</h4></li>
                      <li><Link to="/storelink/#marketplace" style={{marginLeft: 5}}>FREE</Link></li>
                      <li><Link to="/storelink/#marketplace" style={{marginLeft: 5}}>Pro</Link></li>
                      <li><Link to="/storelink/#marketplace" style={{marginLeft: 5}}>Business</Link></li>
                    </ul>
                  </div>
                </div>
              </li>    
              <li className="nav-item">
                <Link className="nav-link js-scroll-trigger" to="/signin">
                 Sign In
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Styles>
  )
}
export default HeaderHome;
