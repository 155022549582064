import React, {useEffect} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "../pages/home/home";
import Checkin from "../pages/home/solutions3";
import Checkout from "../pages/home/solutions2";
import Links from "../pages/home/links";
import App from "../pages/home/app";
import Guide from "../pages/home/guide";
import Guide2 from "../pages/home/guide2";
import User from "../pages/home/user";
import Login from "../components/auth/login";
import Register from "../components/auth/register";
import Logout from '../components/auth/logout';
import StoreLink from "../pages/home/storelink";
import About from "../pages/home/about";
import Privacy from "../pages/home/privacy";
import Support from "../pages/home/support";
import Terms from "../pages/home/terms";
import Stores from "../pages/directory/stores/index";
import StoreDetail from "../pages/directory/stores/detail";
import Events from "../pages/directory/events/index";
import NFTS from "../pages/nft/index";
import NFTTest from "../pages/nft/nfttest";
import NFTDetail from '../pages/nft/detail'
import {getCookie, setCookie} from "../service/cookie";
import {useSetting} from "../provider/setting";
import siteConfig from '../config/site.config.js';
import {AuthRoute, PrivateRoute} from './middleware';
import StoreProfileEditPage from "../pages/bank/store/profile/edit";
import StoreDetailsPage from "../pages/bank/store/profile/details";
import StoreItemsPage from "../pages/bank/store/checkout/items";
import StoreItemCreatePage from "../pages/bank/store/checkout/items-create";
import StoreItemEditPage from "../pages/bank/store/checkout/items-edit";
import StoreCategoriesPage from "../pages/bank/store/checkout/categories";
import StoreCategoryCreatePage from "../pages/bank/store/checkout/category-create";
import StoreCategoryEditPage from "../pages/bank/store/checkout/category-edit";
import StoreAddonsPage from "../pages/bank/store/checkout/addons";
import StoreAddonCreatePage from "../pages/bank/store/checkout/addons-create";
import StoreAddonEditPage from "../pages/bank/store/checkout/addons-edit";
import SchedulingPage from "../pages/bank/store/checkin/schedule";
import ProvidersPage from "../pages/bank/store/checkin/providers";
import RSVPPage from "../pages/bank/store/checkin/rsvp";
import EventsPage from "../pages/bank/store/checkin/events";
import EventsCreatePage from "../pages/bank/store/checkin/events-edit";
import EventsEditPage from "../pages/bank/store/checkin/events-edit";
import TicketsPage from "../pages/bank/store/checkin/tickets";
import TicketsCreatePage from "../pages/bank/store/checkin/tickets-create";
import TicketsEditPage from "../pages/bank/store/checkin/tickets-edit";
import StoreLinksPage from "../pages/bank/store/checkout/storelink";
import Transactions from "../pages/bank/transactions/checkout";
import CreateDonate from "../pages/bank/store/donate/create";
import ViewDonate from "../pages/bank/store/donate";
import DonateCheckout from "../pages/bank/store/donate/checkout";
import Followers from "../pages/bank/crm/followers";
import CRM from "../pages/bank/crm/activity";
import Notification from "../pages/bank/crm/notifications";

const AppRoutes = () => {
  const [, dispatch] = useSetting()

  useEffect(() => {
    const auth = {
      signin_session_id: getCookie("signin_session_id"),
      signin_merchant_code: getCookie("signin_merchant_code"),
      signin_merchant_id: getCookie("signin_merchant_id"),
      signin_merchant_user_id: getCookie("signin_merchant_user_id"),
      signin_current_user_id: getCookie("signin_current_user_id"),
      signin_merchant_name: getCookie("signin_merchant_name"),
      current_ident_token: getCookie("current_ident_token"),
      current_user_email: getCookie("current_user_email"),
      selected_merchant_address: getCookie("selected_merchant_address"),
      current_merchant_wallet_address: getCookie("current_merchant_wallet_address"),
      selected_merchant_bool_cannabis_merchant: getCookie("selected_merchant_bool_cannabis_merchant"),
    };
    dispatch({type: 'SET', settingName: 'auth', settingData: auth})
    const selectNetwork = getCookie("sel_network");
    if (selectNetwork) {
      dispatch({type: 'SET', settingName: 'sel_network', settingData: selectNetwork})
    } else {
      dispatch({type: "SET", settingName: "sel_network", settingData: "live"});
      setCookie("sel_network", "live", siteConfig.expired);
    }
  }, [])
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route path="/signin" element={
          <AuthRoute>
            <Login/>
          </AuthRoute>
        }>
        </Route>
        <Route path="/signup" element={
          <AuthRoute>
            <Register/>
          </AuthRoute>
        }>
        </Route>
        <Route path="/signout" element={
          <PrivateRoute>
            <Logout/>
          </PrivateRoute>
        }>
        </Route>
        <Route path="/checkin" element={<Checkin/>}></Route>
        <Route path="/checkout" element={<Checkout/>}></Route>
        <Route path="/links" element={<Links/>}></Route>
        <Route path="/app" element={<App/>}></Route>
        <Route path="/howitworks/guide" element={<Guide/>}></Route>
        <Route path="/howitworks/guide2" element={<Guide2/>}></Route>
        <Route exact path="/about/user" element={<User/>}></Route>
        <Route path="/storelink" element={<StoreLink/>}></Route>
        <Route path="/about/" element={<About/>}></Route>
        <Route path="/privacy" element={<Privacy/>}></Route>
        <Route path="/support" element={<Support/>}></Route>
        <Route path="/terms" element={<Terms/>}></Route>
        <Route exact path="/stores" element={<Stores/>}></Route>
        <Route path="/stores/:name/:merchantCode" element={<StoreDetail/>}></Route>
        <Route path="/events" element={<Events/>}></Route>
        <Route exact path="/nfts" element={<NFTS/>}></Route>
        <Route exact path="/nfttest" element={<NFTTest/>}></Route>
        <Route exact path="/nfts/:id" element={<NFTDetail/>}></Route>
        {/* TRANSACTIONS */}
        <Route exact path="/bank/transactions/checkout" element={<PrivateRoute><Transactions/></PrivateRoute>}></Route>
        {/* STORE */}
        <Route path="/bank/store/profile" element={<PrivateRoute><StoreProfileEditPage/></PrivateRoute>}/>
        <Route path="/bank/store/profile/edit" element={<PrivateRoute><StoreProfileEditPage/></PrivateRoute>}/>
        <Route path="/bank/store/details" element={<PrivateRoute><StoreDetailsPage/></PrivateRoute>}/>
        <Route path="/bank/store/items" element={<PrivateRoute><StoreItemsPage/></PrivateRoute>}/>
        <Route path="/bank/store/items/create" element={<PrivateRoute><StoreItemCreatePage/></PrivateRoute>}/>
        <Route path="/bank/store/items/edit" element={<PrivateRoute><StoreItemEditPage/></PrivateRoute>}/>
        <Route path="/bank/store/categories" element={<PrivateRoute><StoreCategoriesPage/></PrivateRoute>}/>
        <Route path="/bank/store/categories/create" element={<PrivateRoute><StoreCategoryCreatePage/></PrivateRoute>}/>
        <Route path="/bank/store/categories/edit" element={<PrivateRoute><StoreCategoryEditPage/></PrivateRoute>}/>
        <Route path="/bank/store/addons" element={<PrivateRoute><StoreAddonsPage/></PrivateRoute>}/>
        <Route path="/bank/store/addons/create" element={<PrivateRoute><StoreAddonCreatePage/></PrivateRoute>}/>
        <Route path="/bank/store/addons/edit" element={<PrivateRoute><StoreAddonEditPage/></PrivateRoute>}/>
        <Route path="/bank/store/storelink" element={<PrivateRoute><StoreLinksPage/></PrivateRoute>}/>
        <Route path="/bank/store/schedule" element={<PrivateRoute><SchedulingPage/></PrivateRoute>}/>
        <Route path="/bank/store/providers" element={<PrivateRoute><ProvidersPage/></PrivateRoute>}/>
        <Route path="/bank/store/rsvp" element={<PrivateRoute><RSVPPage/></PrivateRoute>}/>
        <Route path="/bank/store/events" element={<PrivateRoute><EventsPage/></PrivateRoute>}/>
        <Route path="/bank/store/events/create" element={<PrivateRoute><EventsCreatePage/></PrivateRoute>}/>
        <Route path="/bank/store/events/edit" element={<PrivateRoute><EventsEditPage/></PrivateRoute>}/>
        <Route path="/bank/store/tickets" element={<PrivateRoute><TicketsPage/></PrivateRoute>}/>
        <Route path="/bank/store/tickets/create" element={<PrivateRoute><TicketsCreatePage/></PrivateRoute>}/>
        <Route path="/bank/store/tickets/edit" element={<PrivateRoute><TicketsEditPage/></PrivateRoute>}/>
        {/* CRM */}
        <Route exact path="/bank/crm/followers" element={<PrivateRoute><Followers/></PrivateRoute>}></Route>
        <Route exact path="/bank/crm/activity" element={<PrivateRoute><CRM/></PrivateRoute>}/>
        <Route exact path="/bank/crm/notification" element={<PrivateRoute><Notification/></PrivateRoute>}/>
        {/* DONATE */}
        <Route exact path="/bank/store/donate/create" element={<PrivateRoute><CreateDonate/></PrivateRoute>}/>
        <Route exact path="/bank/store/donate/" element={<PrivateRoute><ViewDonate/></PrivateRoute>}/>
        <Route exact path="/bank/store/donate/checkout" element={<PrivateRoute><DonateCheckout/></PrivateRoute>}/>
        
        {/*OLD*/}
        {/*<Route exact path="/bank/account/onboard/reauth" element={*/}
        {/*  <PrivateRoute>*/}
        {/*    <Profile/>*/}
        {/*  </PrivateRoute>*/}
        {/*}></Route>*/}
        {/*<Route exact path="/bank/account/edit/reauth" element={<PrivateRoute><Profile/></PrivateRoute>}></Route>*/}
        
        {/*<Route exact path="/bank/store/checkin/create" element={<PrivateRoute><CheckinCreate/></PrivateRoute>}></Route>*/}
        {/*<Route exact path="/bank/store/checkin/list" element={<PrivateRoute><Checkinlist/></PrivateRoute>}/>*/}
        {/*<Route exact path="/bank/store/checkin/:id" element={<PrivateRoute><CheckinEdit/></PrivateRoute>}/>*/}
        
        {/*<Route exact path="/bank/store/identity/" element={<PrivateRoute><Identity/></PrivateRoute>}/>*/}
        {/*<Route exact path="/bank/store/schedule/" element={<PrivateRoute><Schedule/></PrivateRoute>}/>*/}
        {/*<Route exact path="/bank/store/tickets" element={<PrivateRoute><Tickets/></PrivateRoute>}/>*/}

        {/*<Route exact path="/bank/store/items" element={<PrivateRoute><Items/></PrivateRoute>}/>*/}
        {/*<Route exact path="/bank/store/items/create" element={<PrivateRoute><CreateItem/></PrivateRoute>}/>*/}
        {/*<Route exact path="/bank/store/items/:id" element={<PrivateRoute><EditItem/></PrivateRoute>}/>*/}
        {/*<Route exact path="/bank/store/categories" element={<PrivateRoute><Categories/></PrivateRoute>}/>*/}
        {/*<Route exact path="/bank/store/categories/create" element={<PrivateRoute><CreateCategory/></PrivateRoute>}/>*/}
        {/*<Route exact path="/bank/store/categories/:id" element={<PrivateRoute><EditCategory/></PrivateRoute>}/>*/}
        {/*<Route exact path="/bank/store/extras" element={<PrivateRoute><Extras/></PrivateRoute>}/>*/}
        {/*<Route exact path="/bank/store/extras/create" element={<PrivateRoute><CreateExtra/></PrivateRoute>}/>*/}
        {/*<Route exact path="/bank/store/extras/:id" element={<PrivateRoute><EditExtra/></PrivateRoute>}/>*/}
        
        {/*<Route exact path="/bank/store/profile" element={<PrivateRoute><StoreProfile/></PrivateRoute>}/>*/}
        {/*<Route exact path="/bank/store/activate/" element={<PrivateRoute><StoreActivate/></PrivateRoute>}/>*/}
        {/*<Route exact path="/bank/store/activate/qr" element={<PrivateRoute><CheckoutQR/></PrivateRoute>}/>*/}
      </Routes>
    </Router>
  );
};
export default AppRoutes;
