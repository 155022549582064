import React, {useEffect, useState} from 'react';
import StoreLayout from "../../../../layouts/store";
import CheckinSidebar from "./sidebar";
import {Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Switch, Upload} from "antd";
import SubmitButton from "../components/SubmitButton";
import {getFileExtension} from "../../../../service/string";
import {uploadFileApi} from "../../../../api/api/upload";
import {useSetting} from "../../../../provider/setting";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {cities, countries, states} from "country-cities";
import {Icon} from "@iconify/react";
import uuid from "react-uuid";
import dayjs from "dayjs";
import { getEventApi, updateEventApi} from "../../../../api/api";
import {useNavigate, useSearchParams} from "react-router-dom";

const {Item} = Form;
const {Option} = Select;
const { RangePicker } = DatePicker;

const EventsEditPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [setting] = useSetting();
  const [imageUrl, setImageUrl] = useState();
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(false);
  const [age, setAge] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [tickets, setTickets] = useState([]);

  const beforeUpload = async (file) => {
    try {
      setLoading(true);
      const filename = setting?.auth?.signin_merchant_id + '_' + Date.now() + '.' + getFileExtension(file.name)
      uploadFileApi(file, filename, {
        onError: (e) => {
          console.log(e.code);
          console.log(e.message);
          setLoading(false)
        },
        onSuccess: (data) => {
          setImageUrl(data.Location);
          setLoading(false);
        },
        onProgress: (e) => {
          console.log(e)
        }
      })
    } catch (e) {
      console.warn(e)
    }
  };

  const finishHandle = (data) => {
    const payload = {
      merchant_id: setting?.auth?.signin_merchant_id,
      merchant_user_id: setting?.auth?.signin_merchant_user_id,
      tickets: tickets,
      event: {
        event_title: data.event_title,
        event_image: imageUrl,
        event_description: data.event_description,
        country: data.country,
        state: data.state,
        city: data.city,
        address: data.address,
        suite: data.suite,
        zip_code: data.zip_code,
        start_date: dayjs(data.date?.[0]).format("MM/DD/YYYY hh:mm A"),
        end_date: dayjs(data.date?.[1]).format("MM/DD/YYYY hh:mm A"),
      }
    }

    updateEventApi(payload)
      .then(({data}) => {
        navigate("/bank/store/events")
      })
  }

  const getHandle = async (id) => {
    try {
      const params = {
        merchant_id: setting?.auth?.signin_merchant_id,
        merchant_user_id: setting?.auth?.signin_merchant_user_id,
      }

      const {data} = await getEventApi(params, id)
      setEvent(data)
    } catch (e) {
      console.warn(e)
    }
  }

  const id = searchParams.get("id");

  useEffect(()=>{
    if(!!id && setting?.auth?.signin_merchant_id) {
      getHandle(id);
    }
  }, [id, setting])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined/> : <PlusOutlined/>}
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  );


  return (
    <StoreLayout>
      <div className="row">
        <div className="col col-lg-3">
          <CheckinSidebar/>
        </div>
        <div className="col col-lg-9">
          <h4>Edit Event</h4>
          <p>
            Event registration, ticketing, payment with guest checkin with age and ID verification.
          </p>
          <Form
            fields={[
              {
                name: "event_title",
                value: event.event_title
              },
              {
                name: "event_description",
                value: event.event_description
              },
              {
                name: "address",
                value: event.address
              },
              {
                name: "suite",
                value: event.suite
              },
              {
                name: "country",
                value: event.country
              },
              {
                name: "state",
                value: event.state
              },
              {
                name: "city",
                value: event.city
              },
              {
                name: "zip_code",
                value: event.zip_code
              },
            ]}
            onFinish={finishHandle}
            layout="vertical">
            <h5 className="mt-5">Basic Information</h5>
            <Item
              rules={[{required: true, message: "Event Name is required"}]}
              name="event_title"
              id="event_title"
              label="Event Title">
              <Input
                placeholder="Event Title"
                size="large"/>
            </Item>

            <Row gutter={12}>
              <Col span={6}>
                <Item label="Event Image">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}>
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
                  </Upload>
                </Item>
              </Col>
              <Col span={18}>
                <Item
                  name="event_description"
                  id="event_description"
                  label="Description">
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    placeholder="Description"
                    style={{height: 150}}
                    size="large"/>
                </Item>
              </Col>
            </Row>

            <h5 className="mt-5">Event Address</h5>
            <Row gutter={12}>
              <Col span={18}>
                <Item
                  name="address"
                  id="address"
                  rules={[{required: true, message: "Address is required"}]}
                  label="Address">
                  <Input
                    size="large"
                    placeholder="Address"/>
                </Item>
              </Col>
              <Col span={6}>
                <Item
                  name="suite"
                  id="suite"
                  label="Suite">
                  <Input
                    size="large"
                    placeholder="Suite"/>
                </Item>
              </Col>

              <Col span={6}>
                <Item
                  label="Country"
                  rules={[{required: true, message: "Country is required."}]}
                  name="country"
                  id="country">
                  <Select
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    showSearch
                    placeholder="Select Country"
                    onChange={(e) => {
                      setCountry(e)
                    }}
                    options={countries.all().map((i) => {
                      i.value = i.isoCode
                      i.label = i.name
                      return i
                    })}
                    size="large">
                  </Select>
                </Item>
              </Col>
              <Col span={6}>
                <Item
                  label="State"
                  rules={[{required: true, message: "State is required."}]}
                  name="state"
                  id="state">
                  <Select
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    showSearch
                    placeholder="Select State"
                    onChange={(e) => {
                      setState(e)
                    }}
                    options={states.getByCountry(country).map((i) => {
                      i.value = i.isoCode
                      i.label = i.name
                      return i
                    })}
                    size="large">
                  </Select>
                </Item>
              </Col>
              <Col span={6}>
                <Item
                  label="City"
                  rules={[{required: true, message: "City is required."}]}
                  name="city"
                  id="city">
                  <Select
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    showSearch
                    placeholder="Select City"
                    options={cities.getByState(state, country).map((i) => {
                      i.value = i.name
                      i.label = i.name
                      return i
                    })}
                    size="large">
                  </Select>
                </Item>
              </Col>
              <Col span={6}>
                <Item
                  name="zip_code"
                  id="zip_code"
                  rules={[{required: true, message: "Zipcode is required"}]}
                  label="Zipcode">
                  <Input
                    size="large"
                    placeholder="Zipcode"/>
                </Item>
              </Col>
            </Row>

            <h5 className="mt-5">Age & ID Verification</h5>
            <p>Select Age & ID Verification for Event. Checkin guests at event with Checkin App.</p>
            <Item>
              <Switch
                height={22}
                width={44}
                checked={age}
                onChange={(checked) => {
                  setAge(checked)
                }}/>
            </Item>
            {
              age && (
                <Item>
                  <Select
                    name="minimumGuestAge"
                    id="minimumGuestAge"
                    size="large">
                    {
                      [...new Array(13)].map((_, index) => (
                        <Option key={`age-${index}`} value={index + 13}>{index + 13}</Option>
                      ))
                    }
                  </Select>
                </Item>
              )
            }

            <h5 className="mt-5">Date & Time</h5>
            <Item
              id="date"
              name="date">
              <RangePicker
                onChange={(event)=>{console.log(event)}}
                style={{width: "100%"}}
                size="large"
                block
                showTime />
            </Item>

            <h5 className="mt-5">Ticket Details</h5>
            {
              tickets.map((ticket) => (
                <Row
                  key={ticket.id}
                  gutter={[12, 0]}>
                  <Col span={9}>
                    <Form.Item>
                      <Input
                        onChange={(e) => {
                          setTickets(tickets.map((i) => {
                            if (i.id === ticket.id) {
                              i.ticket_type = e.target.value
                            }
                            return i
                          }))
                        }}
                        value={ticket.ticket_type}
                        placeholder="Ticket Name"
                        size="large"/>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Item>
                      <Space style={{
                        height: "40px",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center"}}>
                        <Switch
                          checked={ticket.paid}
                          onChange={(checked)=>{
                            setTickets(tickets.map((i) => {
                              if (i.id === ticket.id) {
                                i.paid = checked
                              }
                              return i
                            }))
                          }}
                          unCheckedChildren={"FREE"}
                          checkedChildren={"PAID"}
                        />
                      </Space>
                    </Item>
                  </Col>
                  <Col span={6}>
                    <Item>
                      <InputNumber
                        disabled={!ticket.paid}
                        onChange={(e) => {
                          setTickets(tickets.map((i) => {
                            if (i.id === ticket.id) {
                              i.ticket_quantity = e
                            }
                            return i
                          }))
                        }}
                        value={ticket.ticket_quantity}
                        placeholder="Quantity"
                        size="large"/>
                    </Item>
                  </Col>
                  <Col span={4}>
                    <Item>
                      <InputNumber
                        disabled={!ticket.paid}
                        onChange={(e) => {
                          setTickets(tickets.map((i) => {
                            if (i.id === ticket.id) {
                              i.ticket_price = e
                            }
                            return i
                          }))
                        }}
                        value={ticket.ticket_price}
                        prefix="$"
                        suffix="USD"
                        placeholder="Price"
                        size="large"/>
                    </Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item>
                      <Button
                        block
                        size="large"
                        danger
                        onClick={() => {
                          setTickets(tickets.filter((i) => i.id !== ticket.id))
                        }}
                        type="primary">
                        <Icon style={{width: 24, height: 24}} icon="material-symbols:close"/>
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))
            }
            <Form.Item>
              <Button
                block
                type="primary"
                danger
                ghost
                icon={<Icon style={{width: 24, height: 24}} icon="material-symbols:add"/>}
                onClick={() => {
                  setTickets([...tickets, {id: uuid()}])
                }}
                size="large">
                Add Ticket
              </Button>
            </Form.Item>

            <Item className="mt-5">
              <SubmitButton
                type="primary"
                htmlType="submit"
                size="large">
                Save Event
              </SubmitButton>
            </Item>
          </Form>
        </div>
      </div>
    </StoreLayout>
  );
};

export default EventsEditPage;
