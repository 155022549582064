import React from 'react'
import { Link } from 'react-router-dom'

const Nav = (props) => {
  const {directory} = props

  return (
    <ul class="nav nav-tabs">
      {/* <li class="nav-item">
        <Link class={`nav-link ${directory==='nfts'?:''}`} to="/nfts" id="id_tab_dashboard">NFTs</Link>
      </li> */}
      <li class="nav-item">
        <Link class={`nav-link ${directory==='events'?'active':''}`} to="/events" id="id_tab_dashboard">Events</Link>
      </li>
      <li class="nav-item">
        <Link class={`nav-link ${directory==='stores'?'active':''}`} to="/stores" id="id_tab_dashboard">Stores</Link>
      </li>
      <li class="nav-item">
        <Link class={`nav-link ${directory==='groups'?'active':''}`} to="/groups" id="id_tab_dashboard">Groups</Link>
      </li>
    </ul>
  )
}
export default Nav
