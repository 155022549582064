import React, {Fragment, useEffect, useState} from 'react';
import StoreLayout from "../../../../layouts/store";
import AddonsSidebar from "./sidebar";
import {useNavigate} from "react-router-dom";
import {useSetting} from "../../../../provider/setting";
import {Button, Collapse, Space, Modal, Table} from "antd";
import {Icon} from "@iconify/react";
import {deleteAddonApi, deleteCategoryApi, getAddonsApi, getCategoriesApi} from "../../../../api/api";
import {NotificationManager} from "react-notifications";
import SubmitButton from "../components/SubmitButton";

const {Panel} = Collapse

const StoreAddonsPage = () => {
    const navigate = useNavigate();
    const [setting] = useSetting();

    const [modal, contextHolder] = Modal.useModal();
    const [addons, setAddons] = useState([]);
    const [categories, setCategories] = useState([]);

    const getHandle = async () => {
        if (setting?.auth?.signin_merchant_code) {
            getAddonsApi(setting?.auth?.signin_merchant_code)
                .then(({data}) => {
                    setAddons(data.map((i) => {
                        i.key = i.id;
                        return i;
                    }))
                })
                .catch((e) => console.warn(e))
        }
    }

    const getCategoriesHandle = async () => {
        if (setting?.auth?.signin_merchant_code) {
            getCategoriesApi(setting.auth.signin_merchant_code)
                .then(({data}) => {
                    setCategories(data);
                })
                .catch(e => console.warn(e))
        }
    }

    const deleteHandle = (addon) => {
        modal.confirm({
            title: <h5>Delete Add on!</h5>,
            icon: <Icon style={{width: 60, height: 60, color: "#cc0000"}} icon="ep:warn-triangle-filled"/>,
            content: <p>Are you sure to delete <b>{addon.name}</b> Addon?</p>,
            okText: 'Delete',
            cancelText: 'Cancel',
            okType: "danger",
            onOk: () => {
                deleteAddonApi(setting?.auth?.signin_merchant_code, addon.id)
                    .then(() => {
                        getHandle();
                        NotificationManager.success("Successfully deleted!", "Success", 3000)
                    })
                    .catch((e) => {
                        console.warn(e);
                        NotificationManager.error("An error was created. try again later!", "Error", 3000)
                    })
            },
        });
    }

    const columns = [
        {
            title: 'Category',
            dataIndex: 'id',
            key: 'id',
            align: "right",
            width: 100,
            render: (i) => {
              let z = ""

              categories.map((category) => {
                  if(JSON.parse(category.other || "[]").includes(i)) {
                    z = category.name
                  }
              })

              return z
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (i) => "$" + parseFloat(i).toFixed(2)
        },
        {
            title: '',
            width: 100,
            align: "center",
            render: (i) => {
                return (
                    <Fragment>
                        <Button
                            onClick={() => {
                                navigate(`/bank/store/addons/edit/?id=${i.id}`)
                            }}
                            className="icon-button"
                            style={{marginRight: 6}}
                            type="link">
                            <Icon color="#006000" icon="mdi:circle-edit-outline"/>
                        </Button>
                        <Button
                            onClick={() => {
                                deleteHandle(i)
                            }}
                            className="icon-button"
                            type="link"
                            danger>
                            <Icon icon="material-symbols:delete-outline-sharp"/>
                        </Button>
                    </Fragment>
                )
            }
        }
    ]


    useEffect(() => {
        getHandle();
        getCategoriesHandle();
    }, [setting])


    console.log(categories)

    return (
        <StoreLayout>
            <div className="row">
                <div className="col col-lg-3">
                    <AddonsSidebar/>
                </div>

                <div className="col col-lg-9">
                    <h4>Add Ons</h4>

                    <Table
                        dataSource={addons}
                        columns={columns}
                        bordered
                        pagination={{
                            pageSize: 5
                        }}
                    />
                    <div className="mt-5">
                        <SubmitButton
                            type="primary"
                            ghost
                            onClick={() => {
                                navigate('/bank/store/addons/create')
                            }}
                            size="large">
                            Create New Add-On
                        </SubmitButton>
                    </div>
                </div>
            </div>
            {contextHolder}
        </StoreLayout>
    );
};

export default StoreAddonsPage;
