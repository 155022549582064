import React from 'react'
import { Link } from 'react-router-dom';
// import { Styles } from '../style/headerEmptyStyle';
import { Styles } from '../../layouts/style/headerHomeStyle';

const HeaderHome = () => {

  return (
    // <Styles>
    //   <nav className="navbar navbar-expand-lg navbar-light fixed-top header" id="mainNav">
    //     <div className="container">
    //       <div className="col-lg-12 my-auto">
    //       <p style={{ textAlign: 'center', paddingTop: 0 }}>
    //       <Link className="navbar-brand js-scroll-trigger" to="/#"><img src="" height="0" width="0" /><b>VEEV</b></Link>
    //       </p>
    //       </div>
    //     </div> 
    //   </nav>
    // </Styles>
    <Styles>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top header" id="mainNav">
        <div className="container">  
            <ul className="navbar-nav style={{ textAlign: 'center', paddingTop: 0 }}">
              {/* <p style={{ textAlign: 'center', paddingTop: 0 }}> */}
                <Link className="navbar-brand js-scroll-trigger" to="/#"><img src="../assets/img/veevlogo.png" height="25" width="*" /></Link>
              {/* </p>             */}
            </ul>
        </div>
      </nav>
    </Styles>
  )
}
export default HeaderHome;
