import styled from "styled-components";

export const Styles = styled.div`
  .nfts {
    section {
      #store_a {
        margin: 10px 0px;
      }
      .nft-image {
        min-height: 210px;
        max-height: 210px;
        background-size: auto 210px;
        background-repeat: no-repeat;
        background-position: center center;
        border: solid 1px #cecece;
        border-radius: 5px;
      }
      .store_name_address {
        .firstChild {
        }
        .secondChild {
          min-width: 50px;
        }
      }
    }
  }
`;

export const ModalStyles = styled.div`
  section {
    width: 375px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &:-webkit-scrollbar {
      display: none;
    }
    div.modal-image {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 300px;
      background-color: #cfcfcf;
      img {
        height: 200px;
      }
    }
    div.qr-code {
      display: flex;
      justify-content: center;
    }
    .content {
      padding: 20px;
      .title {
        font-size: 22px;
        font-weight: 500;
      }
      .subtitle {
        font-size: 17px;
        font-weight: 500;
        padding: 10px;
      }
      .descrption {
        font-size: 15px;
      }
    }
  }
`;