export default {
  apiUrl: "https://veev-dev.herokuapp.com/api/",
  webSocketUrl: "wss://veev-dev.herokuapp.com/ws",
  baseUrl: "https://staging.veev.app",
  // apiUrl: "https://veev-production.herokuapp.com/api/",
  // webSocketUrl: "wss://veev-production.herokuapp.com/ws",
  // baseUrl: "https://www.veev.app",
  checkoutUrl: "https://checkout.veev.app",
  expired: 100,
  s3Config: {
    bucketName: "veev-prod-bucket",
    region: "us-west-1",
    accessKeyId: "AKIA2ZTBBGXYDE7CBGO7",
    secretAccessKey: "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",
  },
  stripeClient: 'pk_test_CukwWAFm3eN4t1fok5eaclzR'
};
