import React, {useEffect, useState} from 'react';
import StoreLayout from "../../../../layouts/store";
import SchedulingSidebar from "./sidebar";
import {AutoComplete, Avatar, Button, Col, Input, List, Row, Skeleton} from "antd";
import {Icon} from "@iconify/react";
import {useSetting} from "../../../../provider/setting";
import {getMerchantApi, getUsersApi} from "../../../../api/api";

const fakeDataUrl = `https://randomuser.me/api/?results=${5}&inc=name,gender,email,nat,picture&noinfo`;

const ProvidersPage = () => {
  const [setting] = useSetting()
  const [merchant, setMerchant] = useState({});
  const [initLoading, setInitLoading] = useState(true);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {

  };

  const onSelect = (value) => {

  };


  useEffect(() => {
    if(setting?.auth?.signin_merchant_id) {
      getMerchantApi(setting?.auth?.signin_merchant_id)
        .then(({data}) => {
          data.other = JSON.parse(data.other)
          setMerchant(data)
        })
        .catch((e) => {
          console.warn(e)
        })
        .finally(()=>setInitLoading(false))
    }
  }, [setting?.auth])


  console.log(merchant)

  useEffect(() => {
    getUsersApi()
      .then(({data}) =>{
        console.log(data, "USERS")
      })
      .catch((e)=>{
        console.warn(e)
      })
    // fetch(fakeDataUrl)
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setInitLoading(false);
    //     setData(res.results);
    //     setList(res.results);
    //   });
  }, []);


  return (
    <StoreLayout>
      <div className="row">
        <div className="col col-lg-3">
          <SchedulingSidebar/>
        </div>

        <div className="col col-lg-9">
          <h4 className="mb-3">Providers</h4>

          <Row gutter={12} className="mb-4">
            <Col span={16}>
              <AutoComplete
                dropdownMatchSelectWidth={252}
                style={{ width: "100%" }}
                options={options}
                onSelect={onSelect}
                onSearch={handleSearch}>
                <Input
                  size="large"
                  placeholder="Enter Name or VEEV id"/>
              </AutoComplete>
            </Col>
            <Col span={8}>
              <Button
                icon={<Icon style={{width: 24, height: 24}}  icon="material-symbols:add"/>}
                block
                danger
                type="primary"
                size="large">
                Add New Provider
              </Button>
            </Col>
          </Row>

          <List
            loading={initLoading}
            itemLayout="horizontal"
            dataSource={list}
            renderItem={(item) => (
              <List.Item actions={[<a key="list-remove">Remove</a>]}>
                <Skeleton
                  avatar
                  title={false}
                  loading={item.loading}
                  active>
                  <List.Item.Meta
                    avatar={<Avatar size={60} src={item.picture.large} />}
                    title={<h6>{item.name?.first} {item.name?.last}</h6>}
                    description={item?.name?.first}
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </div>
      </div>
    </StoreLayout>
  );
};

export default ProvidersPage;
