import React, { useEffect, useMemo, useState } from "react";
import HeaderHome from "../../components/header/headerHomePage";
import Footer from '../../components/footer/footerPage'
import { Styles } from '../../layouts/style/homeStyle'
import Plans from './plans'

const Solutions = () => {
  const [slider, setSlider] = useState('')
  const sliders = useMemo(() => [
    "../assets/img/veevcheckin.png",
    "../assets/img/veevid.png",
    "../assets/img/veevstore.png",
    "../assets/img/veevreviews.png",
    "../assets/img/store.png",
    "../assets/img/checkout.png",
    "../assets/img/recommendations.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider(sliders[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders.length)
        index = 0
      setSlider(sliders[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders])

  const [slider2, setSlider2] = useState('')
  const sliders2 = useMemo(() => [
    // "../assets/img/veev_connect.png",
    "../assets/img/homescreen.png",
    "../assets/img/store.png",
    "../assets/img/veevreviews.png",
    // "../assets/img/veevcheckin.png",    
    // "../assets/img/veevstore.png",
    "../assets/img/veevid.png",

  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider2(sliders2[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders2.length)
        index = 0
      setSlider2(sliders2[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders2])

  const [slider3, setSlider3] = useState('')
  const sliders3 = useMemo(() => [
    "../assets/img/veev_connect.png",
    "../assets/img/checkout.png",
    "../assets/img/mobilepos1.png",
    "../assets/img/mobilepos2.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider3(sliders3[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders3.length)
        index = 0
      setSlider3(sliders3[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders3])

  const [slider4, setSlider4] = useState('')
  const sliders4 = useMemo(() => [
    "../assets/img/veev_sales.png",
    "../assets/img/veev_event_signups.png",
    "../assets/img/veev_marketing.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider4(sliders4[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders4.length)
        index = 0
      setSlider4(sliders4[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders4])

  const [slider5, setSlider5] = useState('')
  const sliders5 = useMemo(() => [
    "../assets/img/veev_checkin.png",
    "../assets/img/veev_event_signups.png",
    "../assets/img/veev_marketing.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider5(sliders5[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders5.length)
        index = 0
      setSlider5(sliders5[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders5])

  const [slider6, setSlider6] = useState('')
  const sliders6 = useMemo(() => [
    "../assets/img/pos2.png",
    "../assets/img/pos3.png",
    "../assets/img/pos4.png",
    "../assets/img/veev_sales.png",
    "../assets/img/pos5.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider6(sliders6[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders6.length)
        index = 0
      setSlider6(sliders6[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders6])

  return (
  <>
  <HeaderHome />
    <Styles>
      <div className="guide" id="app" style={{paddingTop: 0, paddingBottom: 0}}>   
        <section className="cta text-center" style={{paddingTop: 70, paddingBottom: 50}}>
          <div className="container">
            <div className="col-lg-12 my-auto">  
              <h className="title">Sell Directly On Social Media With StoreLink</h>
              {/* <h3 style={{ width: '100%', paddingBottom: '0px', paddingTop: '10px', paddingLeft: '0px', color: 'grey'}}>
                A Vertically Integrated sales, marketing & checkout solution to sell to followers on all social platforms.
              </h3> */}
              <h5 style={{ width: '100%', paddingBottom: '30px', paddingTop: '10px', paddingLeft: '0px', color: '#000000'}}>
                Sell to your followers of Instagram, TikTok, YouTube, Twitter and social platforms with VEEV StoreLink. 
                {/* Create your store, add your link to your social media profile, let your followers know and watch orders come. */}
              </h5>
            </div>
          </div>
          
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-8">  
                  <img src={'../assets/img/socialmedia.png'} alt="VEEV StoreLink" className="img-fluid2"/>
                  </div>
                  <div className="col-lg-4 text-left" style={{width: '100%', paddingTop: 100, paddingLeft: '0px' }}>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">One Store on All Social Apps</p>
                    <p style={{ width: '100%', color: 'grey' }}>Create store once and share link on all social apps.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '0px', color: 'grey' }}> 
                    <p className="title4">Turn Followers into Customers</p>
                    <p style={{ width: '100%', color: 'grey' }}>Tools to build a customer base.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '0px', color: 'grey' }}> 
                    <p className="title4">Single Location</p>
                    <p style={{ width: '100%', color: 'grey' }}>To manage sales, marketing & operations on 
                    social platforms and stores.</p>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          </div> 
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div>
        </section>

        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="checkin">
          <div className="container">
            <div className="col-lg-12 my-auto">  
              <h className="title">One Store on All Social Apps</h>
              <h5 style={{ width: '100%', paddingBottom: '50px', paddingTop: '10px', paddingLeft: '0px', color: '#000000'}}>
              No more creating stores on every social app, uploading products, waiting for approval, setting up payment, remembering passwords and all that headache. Simply create store once and share VEEV StoreLink on all social apps. 
              </h5>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row"> 
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device" style={{marginTop: 0, marginBottom: 40}}>
                        <div className="screen">	
                          <div className="slideshow-container">
                            <div className="mySlides">
                              <div className="veevslogan">
                                <img src={slider2} alt="VEEV Checkin" className="img-fluid2" />
                              </div>
                            </div>
                          </div>
                          <div style={{ textAlign: 'center' }}>
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 text-left" style={{width: '100%', paddingTop: '0px', paddingLeft: '40px' }}>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <h3>Store Features</h3>
                    </div> 
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Store Info Page</p>
                    <p style={{ width: '100%', color: 'grey' }}>Store name, logo, address and hours of operation.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Product Listing</p>
                    <p style={{ width: '100%', color: 'grey' }}>Menu of product listings, description, add-ons and pricing.</p>                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Checkout</p>
                    <p style={{ width: '100%', color: 'grey' }}>Menu of product listings, description, add-ons and pricing.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Recommendations</p>
                    <p style={{ width: '100%', color: 'grey' }}>Real reviews and recommendations from real customers.</p>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          </div> 
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div> 
        </section>      
      
        {/* <section>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3" style={{paddingTop: 33}}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/store.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
                    <a href="/signin">
                      <button className="btn btn-product btn-sm" id="signup_btn" type='signup' style={{ margin: '0px'}}>
                        Store  
                      </button>
                    </a>
                    </div> 
                  </div>
                  <div className="col-lg-2 dv-loadmoney">
                  </div>
                  <div className="col-lg-3 dv-loadmoney" style={{paddingTop: 33}}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/checkout.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
                    <a href="/signin">
                      <button className="btn btn-product btn-sm" id="signup_btn" type='signup' style={{ margin: '0px'}}>
                      Checkout
                      </button>
                    </a>
                    </div>
                  </div>
                  <div className="col-lg-1 dv-loadmoney">
                  </div>  
                  <div className="col-lg-3" style={{paddingTop: 33}}>   
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/recommendations.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
                    <a href="/signin">
                      <button className="btn btn-product btn-sm" id="signup_btn" type='signup' style={{ margin: '0px'}}>
                      Marketing
                      </button>
                    </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>    
        </section> */}

        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="checkin">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1">One Location To Manage Sales, Marketing & Operations</p>  
              <h5 style={{ width: '100%', paddingBottom: '30px', paddingTop: '10px', paddingLeft: '0px', color: '#000000'}}>
                Single location to manage sales, marketing and operations on Tiktok, Instagram, YouTube, Twitter and all social apps. 
                {/* Create your store, add your link to your social media profile, let your followers know and watch orders come. */}
              </h5>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row" style={{marginTop: 0, marginBottom: 0}}>
                  <div className="col-lg-12 text-center" style={{ maxHeight: 'auto' }}>
                  <img src="../assets/img/socialmediasales.png" className="img-fluid2" alt="" />
                  </div>
                  <div className="col-lg-12 text-center" style={{ width: '100%', paddingBottom: '5px', paddingTop: '5px', color: '#000000' }}>
                    <div className="row">
                      <div className="col-lg-3"> 
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}> 
                        <p className="title4">Store Operations</p>
                        <p style={{ width: '100%', color: 'grey' }}>Setup, location, store hours, pricing etc.</p>
                        </div>  
                      </div>
                      <div className="col-lg-3"> 
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}>  
                        <p className="title4">Sales & Orders</p>
                        <p style={{ width: '100%', color: 'grey' }}>Sales & order tracking and processing.</p>
                        </div>
                      </div> 
                      <div className="col-lg-3"> 
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}>  
                        <p className="title4">Payment & Checkout</p>
                        <p style={{ width: '100%', color: 'grey' }}>In-App & In-Store order pay and checkout PoS.</p>
                        </div>
                      </div>
                      <div className="col-lg-3"> 
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}>  
                        <p className="title4">Marketing & Promotions</p>
                        <p style={{ width: '100%', color: 'grey' }}>Manage customer marketing and promotions.</p>
                        </div>
                      </div>
                      {/* <div className="col-lg-2 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>

                      </div>
                      <div className="col-lg-3">
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '7px', color: '#000000' }}> 
                        <p className="title4">CRM</p>
                        Robust CRM for sales, marketing & promotions.
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '7px', color: '#000000' }}> 
                        <p className="title4">Sales</p>
                        Share contact details in one click with users & customer’s. 
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '7px', color: '#000000' }}> 
                        <p className="title4">Marketing</p>
                        Payment processing for tickets & donations.
                        </div>
                      </div> */}
                    </div>  
                  </div> 
                </div>
              </div>
            </div>
          </div>
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div>
        </section>

        {/* <section className="features">
          <div className="container container--main">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '75px'}}>
                      <img src="../assets/img/veevqr.png" style={{ marginTop: 10, marginBottom: 20, height: 120 }} alt="VEEV" />
                      <p className="title1">One Click Checkin, Checkout</p>
                      <p className="title3black" style={{ paddingTop: '3px'}}>solution for bars, cafes, events & stores</p>
                    </div>
                    <div className="col-lg-1 dv-loadmoney">
                    </div>
                    <div className="col-lg-3 dv-loadmoney text-center" style={{ paddingBottom: '0px', marginBottom: '0' }}>
                      <div className="device-container">
                        <div className="device-mockup iphone6 portrait gold">
                          <div className="device" style={{marginTop: 20, marginBottom: 0}}>
                            <div className="screen">	
                              <div className="slideshow-container">
                                <div className="mySlides">
                                  <div className="veevslogan">
                                    <img src={slider} alt="VEEV Checkin" className="img-fluid2" />
                                  </div>
                                </div>
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4" style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                      <div className="row">
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '25px', paddingLeft: '50px', color: 'grey' }}>
                        <p className="title3black">Checkin</p>
                        One click signup, scheduling, pay & ID verification for bars, clubs, events & businesses <a href="#checkin" style={{ color: '#cc0000' }}>more >></a> 
                        </div>
                        <div style={{ width: '100%', paddingBottom: '25px', paddingLeft: '50px', color: 'grey' }}> 
                        <p className="title3black">Checkout</p>
                        One click pay solution, eliminating ID and pay fraud in stores & online <a href="#checkout" style={{ color: '#cc0000' }}>more >></a>
                        </div>
                        <div style={{ width: '100%', paddingBottom: '20px', paddingLeft: '50px', color: 'grey' }}> 
                        <p className="title3black">CRM</p>
                        Robust CRM for sales, marketing & promotions <a href="#connect" style={{ color: '#cc0000' }}>more >></a>
                        </div>
                        <div style={{ width: '100%', paddingBottom: '0px', paddingLeft: '50px', color: 'grey' }} className="section-heading text-center submit-button">
                          <a href="/signin">
                            <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup'>
                            Sign Up
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-center" style={{padding: 10}}>
            <a href="#checkin">
            <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '70px', marginLeft: '10px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
            </a>
            </div>
          </div>
        </section> */}

        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="checkin">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1">Checkout</p>  
              <h5 style={{ width: '100%', paddingBottom: '30px', paddingTop: '10px', paddingLeft: '0px', color: '#000000'}}>
                In-App and In-Store payment and PoS checkout system.
              </h5>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device" style={{marginTop: 0, marginBottom: 40}}>
                        <div className="screen">	
                          <div className="slideshow-container">
                            <div className="mySlides">
                              <div className="veevslogan">
                                <img src={slider3} alt="VEEV Checkin" className="img-fluid2" />
                              </div>
                            </div>
                          </div>
                          <div style={{ textAlign: 'center' }}>
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 text-left" style={{width: '100%', paddingTop: '50px', paddingLeft: '40px' }}>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">In-App Order & Pay</p>
                    <p style={{ width: '100%', color: 'grey' }}>Seamless order and pay system for social apps.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Order Management</p>
                    <p style={{ width: '100%', color: 'grey' }}>Order fulfillment and management.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">In-Store PoS</p>
                    <p style={{ width: '100%', color: 'grey' }}>Easy to use In-Store PoS integrated with Tap to Pay</p>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          </div> 
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div>   
        </section>
        
        {/* <section className="download bg-primary3 text-center" id="events">
          <div>  
            <div className="row">
              <div className="col-lg-6 image"> 
                <img src="https://www.qrc.life/assets/images/ipgarde-qr-code-fidelisez-vos-utilisateurs-communiquez-avec-votre-audience-boostez-vos-ventes-888x592.jpg" alt="VEEV Event Signup" />	
              </div>
              <div className="col-lg-6 content">
                <div className="container">  
                  <div className="section-heading text-center">
                  <p className="title">Checkin Access</p>	
                  </div>
                </div> 
              </div>  
            </div>
          </div>
        </section>

        <section id="stores" className="download bg-primary3 text-center">
          <div>  
            <div className="row">
            <div className="col-lg-6 image">  
                <img src="https://staging.veev.app/img/veevscan.jpeg" alt="VEEV Pay" />	
              </div>
              <div className="col-lg-6 content"> 
                <div className="container">  
                  <div className="section-heading text-center">
                  <p className="title">Store Checkout</p>	
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </section>

        <section className="text-center download" id="qrlogin">
          <div>  
            <div className="row">
              <div className="col-lg-6 image">
                <img src="https://staging.veev.app/img/checkoutweb1.png" className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6">      
                <img src="https://staging.veev.app/img/checkinsls.png" className="img-fluid" style={{paddingTop: 0, paddingBottom: 40}} alt="" />    
              </div>
              <div className="col-lg-6 content bg-primary3">
                <div className="container">  
                  <div className="section-heading text-center">
                  <p className="title">Community</p>
                  
                  </div>
                </div> 
              </div>  
            </div>
          </div>
        </section>

        <section id="stores" className="download bg-primary3 text-center">
          <div>  
            <div className="row">
              <div className="col-lg-6 image">  
                <img src="https://staging.veev.app/img/veev_qrscan.png" alt="VEEV Pay" />	
              </div>
              <div className="col-lg-6 content" > 
                <div className="container">  
                  <div className="section-heading text-center">
                  <p className="title">Checkout Services</p>	
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </section> */}

        {/* <section className="cta text-center" style={{paddingTop: 100, paddingBottom: 50}} id="checkout">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <p className="title1">Checkout</p><br></br>
                  <p className="title3black text-center"> An integrated online and onsite store, with web, mobile ordering and pay.</p><br></br><br></br>     
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev-qr-code.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <h4>Scan</h4>
                    </div>
                  </div>
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_app_order.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <h3>Order</h3>
                    </div>
                  </div>  
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_app_pay.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <h4>Pay</h4>
                    </div>
                  </div>
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_app_receipt.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <h4>Receipt</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
              <a className="badge-link" href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1380885775&mt=8"><br /><img className="wallet" src="img/app-store-badge.svg" alt="VEEV Wallet" /></a>	
              </div>	
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8" style={{ paddingBottom: 20, paddingTop: 50 }}>
                      <div className="device-mockup ipad_air_2 landscape silver">
                        <div className="device" style={{marginTop: 0, marginBottom: 30}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider6} className="img-fluid" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>    */}
                    {/* <div className="col-lg-5">  
                      <div className="section-heading" style={{ paddingTop: '50px' }}>
                      <p className="title1">FREE Point of Sale</p>		
                      </div>
                      <div className="section-heading">
                      VEEV Pay is an easy to use, intuitive POS, loaded with features, designed to serve your business. VEEV POS suports:
                      <br />	
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>In-Store Ordering</b></a><br />
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>Online Ordering</b></a><br />
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>Mobile Ordering</b></a><br />
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>Pick-Ups & Delivery</b></a><br />
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>Customer Check-Ins & Verification</b></a><br />	
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>Credit & Branded Gift Cards</b></a><br />
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>Sales Tracking & CRM</b></a><br />
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>Store Operations, Inventory & Employee Management</b></a><br />
                      <a href="#bank" className="js-scroll-trigger item-link"><b>Banking & Compliance</b></a><br />
                      </div>
                    </div> */}
                  {/* </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-4">  */}
                      {/* <div className="device-mockup ipad_air_2 landscape silver">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veev_pos3.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="section-heading"><br />
                      <h4>Point of Sale</h4>
                      </div>
                      <div className="section-heading">
                      - Process payments instantly and Get paid instantly.<br /><br />
                      - View and get transaction details in real-time<br /><br />
                      - Support for credit and debit transactions     
                      </div>
                    </div> 
                    <div className="col-lg-4">  */}
                      {/* <div className="device-mockup ipad_air_2 landscape silver">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veev_pos4.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="section-heading"><br />
                      <h4>Orders</h4>
                      </div>
                      <div className="section-heading">
                      - Eliminate paper receipts by issuing digital receipts<br /><br />
                      - View in-App orders initiated from the VEEV Wallet<br /><br /> 
                      - Search and sort to quickly find what you are looking for 
                      </div>
                    </div>
                    <div className="col-lg-4">  */}
                      {/* <div className="device-mockup ipad_air_2 landscape silver">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veev_sales.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="section-heading"><br />
                      <h4>Sales</h4>
                      </div>
                      <div className="section-heading">
                      - Process payments instantly and Get paid instantly<br /><br />
                      - View and get transaction details in real-time<br /><br />
                      - Support for credit and debit transactions	
                      </div>
                    </div> */}
                    {/* <div className="col-lg-3"> 
                      <div className="device-mockup ipad_air_2 landscape silver">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veev_pos5.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading"><br />
                      <h4>Marketing</h4>
                      </div>
                      <div className="section-heading">
                      - Online, Offline and Mobile store management<br /><br />
                      - Employee Checkins, Hours & Payroll<br /><br />
                      - Access & Permissions<br /><br />
                      </div>
                    </div>  */}
                  {/* </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div>  
        </section> */}

        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="crm">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1">Turn Followers Into Customers</p>  
              <h5 style={{ width: '100%', paddingBottom: '0px', paddingTop: '10px', paddingLeft: '0px', color: '#000000'}}>
              Build your customer base with StoreLink by turning followers into customers.
              </h5>
            </div>
          </div>
          {/* <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-1 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    
                  </div>
                  <div className="col-lg-3 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    <div className="device-container">
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device" style={{marginTop: 0, marginBottom: 40}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider2} alt="VEEV Checkin" className="img-fluid2" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>

                  </div>
                  <div className="col-lg-7 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    <div className="device-container">
                      <div className="device-mockup imac imac">
                        <div className="device" style={{marginTop: 0, marginBottom: 40}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider4} className="img-fluid" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12"> 
                     
                      One system to run your entire operations. VEEV Pay provides your business Mobile Ordering and fullfilment capability for items listed in you POS. Mobile orders initiated from VEEV Wallets are automatically organized into customer’s fulfillment preference, pickup, deliver, or ship. 

                      Supports instant mobile ordering and pay from any smart phone with simple Click & Pay, approve transaction. Payment are processed instantly. Purchase receipt is displayed instantly.<br /><br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          
          <div className="container">
            <div className="row">
              <div className="section-heading text-center">
                <div className="col-lg-12 my-auto" style={{ paddingTop: 30 }}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-4"> 
                        <div className="device-mockup imac imac">
                          <div className="device">
                            <div className="screen">
                            <img src="../assets/img/veev_sales.png" className="img-fluid" alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="section-heading">
                        <h3>Sales</h3>
                        </div>
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '0px', paddingRight: '20px', color: 'grey' }}>
                        <p style={{ width: '100%', color: 'grey' }}>Sales transactions by customer and product, daily, monthly, by region and many other parameters.</p>
                        </div>
                      </div>
                      <div className="col-lg-4"> 
                        <div className="device-mockup imac imac">
                          <div className="device">
                            <div className="screen">
                            <img src="../assets/img/veev_marketing.png" className="img-fluid" alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="section-heading">
                        <h3>Marketing</h3>
                        </div>
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '0px', paddingRight: '20px', color: 'grey' }}>
                        <p style={{ width: '100%', color: 'grey' }}>Offers & Promotions targeted by customer, by product and other metrics to generate demand.</p>
                        </div>
                      </div>
                      <div className="col-lg-4"> 
                        <div className="device-mockup imac imac">
                          <div className="device">
                            <div className="screen">
                            <img src="../assets/img/veev_event_signups.png" className="img-fluid" alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="section-heading">
                        <h3>CRM</h3> 
                        </div>
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '0px', paddingRight: '20px', color: 'grey' }}>
                        <p style={{ width: '100%', color: 'grey' }}>Dynamic CRM to own your customers in case you get hacked or shut out from social media.</p>
                        </div>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>	
            </div>
          </div>
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div>     
        </section>

        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="crm">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1">Prevent Business Disruption</p>  
              <h5 style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px', paddingLeft: '0px', color: '#000000'}}>
              If you loose access or get shut out of your social media account due to hacking, deplatforming or ID theft your business will suffer substantially or even go out of business.
              </h5>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-7 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    <div className="device-container">
                      <div className="device-mockup imac imac">
                        <div className="device" style={{marginTop: 0, marginBottom: 40}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider4} className="img-fluid" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 text-left" style={{width: '100%', paddingTop: 10, paddingLeft: '0px' }}>
                  <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '20px', color: 'grey' }}> 
                    <p className="title4">Access Denied to Social Apps & Data Loss</p>
                    <p style={{ width: '100%', color: 'grey' }}>Losing access to your account or lose your data, that will be the end of your business.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Shutdown due to Hacking & Deplatforming</p>
                    <p style={{ width: '100%', color: 'grey' }}>If your account is shutdown due to hacking & deplatfoming your business will collapse.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '20px', color: 'grey' }}> 
                    <p className="title4">CRM to Keep Your Business Running</p>
                    <p style={{ width: '100%', color: 'grey' }}>A robust CRM to run your business independent of social media.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          {/* <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12"> 
                     
                      One system to run your entire operations. VEEV Pay provides your business Mobile Ordering and fullfilment capability for items listed in you POS. Mobile orders initiated from VEEV Wallets are automatically organized into customer’s fulfillment preference, pickup, deliver, or ship. 

                      Supports instant mobile ordering and pay from any smart phone with simple Click & Pay, approve transaction. Payment are processed instantly. Purchase receipt is displayed instantly.<br /><br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div>     
        </section>

        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="pricing">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <p className="title1">Pricing</p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="#pricing">
            <Plans/>
          </div>
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div>    
        </section>
      </div>
    </Styles>
  <Footer />
  </>  
  )
}
export default Solutions
