import React from 'react'
import { Link } from 'react-router-dom'
import { Styles } from '../../layouts/style/footerHomeStyle'

const FooterHome = () => {

  return (
    <Styles>
      <footer>
        <div className="row">
          <div className="col-lg-10">
            <div className="link-item">
              <p><Link to="/about">About</Link></p> 
            </div>
            <div className="link-item">
              <p><Link to="/app">App</Link></p>
            </div>
            <div className="link-item">
              <p><Link to="/storelink">StoreLink</Link></p>
            </div>
            <div className="link-item">
              <p><Link to="/privacy">Privacy</Link></p>
            </div>
            <div className="link-item">
              <p><Link to="/terms">Terms</Link></p>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="copyright" style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
              <p>&copy; 2023 VEEV Inc. &nbsp;&nbsp;
                <img src="https://www.bbg.gov/wp-content/themes/bbgRedesign/img/us_flag_small.png" />
              </p>
            </div>
            <div className="clearfix"></div>
           </div>
        </div>  
      </footer>
    </Styles>
  )
}
export default FooterHome
