import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import {
  FavoriteBorder,
  StarBorder,
  ShareOutlined,
  SubjectOutlined,
  Dns,
  Bookmarks,
  AssignmentOutlined,
  InsertChartOutlined,
  AppsOutlined,
} from "@material-ui/icons";

import Header from "../../components/header/headerHomePage";
import Footer from '../../components/footer/footerPage'
import {NFTdata} from '../../data/nfts'
import { Styles } from "../../layouts/style/nftDetailStyle";
import Board from './board'

const NFTs = () => {
  const { id } = useParams()
  const nfts = NFTdata
  const [nft, setNft] = useState({})

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  }

  useEffect(() => {
    setNft(nfts[id])
    scrollToTop();
  }, [id])
  return (
    <>
      <Header />
      <Styles>
        <div className="nft">
          <div className="main-container row">
            <div className="col-md-5 col-sm-12 nft-image">
              <img src={nft?.image} alt="" />
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="action">
                <div className="action-left action-item">
                  <FavoriteBorder className="icon" /> 1
                </div>
                <div className="action-center action-item">
                  <StarBorder className="icon" />
                </div>
                <div className="action-right action-item">
                  <ShareOutlined className="icon" />
                </div>
              </div>
              <div className="code">#2696</div>
              <div className="category">
                <span className="categroy-item">art</span>
                <span className="categroy-item">games</span>
                <span className="categroy-item">memes</span>
                <span className="categroy-item">mode</span>
                <span className="categroy-item">music</span>
                <span className="categroy-item">photography</span>
              </div>
              <div className="collection">Firat NFTs Collection</div>
              <div className="price-info">
                <div className="price">5</div>
                <div className="state">cancelled</div>
              </div>
              <div className="description toggle-item">
                <div
                  className="header"
                  data-toggle="collapse"
                  href="#collapseDescription"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseDescription"
                >
                  <SubjectOutlined />
                  <div className="header-name">Description</div>
                </div>
                <div className="collapse toggle-body" id="collapseDescription">
                  Fırat keeps reigning as the most famous comics hero of Turkey
                  since he came into our lives in 2007. The cartoonish life of
                  this kid, who has a language and a world-view of his own, has
                  glimpses of everybody's childhood one way or the other. Fırat
                  has an answer to every situation he finds himself in. He
                  suffers no traumas whatsoever. He never cries, even if he
                  falls. He finds joy in falling in a cool manner. He can
                  imagine block-buster features with rockets exploding and cars
                  crashing one another with a slipper or a triple socket. His
                  only concern is to grow up fast, when he finally does, he
                  imagines he will be the coolest person on the planet. Fırat
                  has 5 published books up to now, each of them a best seller.
                  He now multiplies in NFT.
                </div>
              </div>
              <div className="properties toggle-item">
                <div
                  className="header"
                  data-toggle="collapse"
                  href="#collapseProperties"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseProperties"
                >
                  <Dns />
                  <div className="header-name">Properties</div>
                </div>
                <div
                  className="collapse toggle-body row"
                  id="collapseProperties"
                >
                  <div className="col-4 property-item">
                    <div className="title">Backgrounds</div>
                    <div className="subtitle">Blossom</div>
                    <div className="value">10% probability</div>
                  </div>
                </div>
              </div>
              <div className="about toggle-item">
                <div
                  className="header"
                  data-toggle="collapse"
                  href="#collapseAbout"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseAbout"
                >
                  <Bookmarks />
                  <div className="header-name">About Firat NFTs Collection</div>
                </div>
                <div className="collapse toggle-body" id="collapseAbout">
                  <img src={nft?.image} alt="collection" />
                  <div>
                    <div className="title">Firat NFTs Collection</div>
                    <div>
                      Fırat keeps reigning as the most famous comics hero of
                      Turkey since he came into our lives in 2007. The
                      cartoonish life of this kid, who has a language and a
                      world-view of his own, has glimpses of everybody's
                      childhood one way or the other. Fırat has an answer to
                      every situation he finds himself in. He suffers no traumas
                      whatsoever. He never cries, even if he falls. He finds joy
                      in falling in a cool manner. He can imagine block-buster
                      features with rockets exploding and cars crashing one
                      another with a slipper or a triple socket. His only
                      concern is to grow up fast, when he finally does, he
                      imagines he will be the coolest person on the planet.
                      Fırat has 5 published books up to now, each of them a best
                      seller. He now multiplies in NFT.
                    </div>
                  </div>
                </div>
              </div>
              <div className="details toggle-item">
                <div
                  className="header"
                  data-toggle="collapse"
                  href="#collapseDetails"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseDetails"
                >
                  <AssignmentOutlined />
                  <div className="header-name">Details</div>
                </div>
                <div className="collapse toggle-body" id="collapseDetails">
                  <table>
                    <tbody>
                      <tr className="row">
                        <th className="col-3">Seller</th>
                        <td className="col-9 user-info">
                          <img src={nft?.image} alt="seller" />
                          <div>
                            <div className="user-name">Anonymous</div>
                            <div className="user-address">
                              0xcc93ea88d342623d62e073e35f9d7b805e8a0af2
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="row">
                        <th className="col-3">Creator</th>
                        <td className="col-9 user-info">
                          <img src={nft?.image} alt="creator" />
                          <div>
                            <div className="user-name">Anonymous</div>
                            <div className="user-address">
                              0xcc93ea88d342623d62e073e35f9d7b805e8a0af2
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="row">
                        <th className="col-3">NFT Address</th>
                        <td className="col-9">
                          0xa7abc3736bc5a4d3d4730eac5a1e487e56c86789
                        </td>
                      </tr>
                      <tr className="row">
                        <th className="col-3">Token ID</th>
                        <td className="col-9">2299</td>
                      </tr>
                      <tr className="row last">
                        <th className="col-3">Token type</th>
                        <td className="col-9">C-Chain (ERC-721)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="history toggle-item">
                <div
                  className="header"
                  data-toggle="collapse"
                  href="#collapseHistory"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseHistory"
                >
                  <InsertChartOutlined />
                  <div className="header-name">Trading History</div>
                </div>
                <div
                  className="collapse toggle-body"
                  id="collapseHistory"
                ></div>
              </div>
              <div className="more toggle-item">
                <div
                  className="header"
                  data-toggle="collapse"
                  href="#collapseMore"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseMore"
                >
                  <AppsOutlined />
                  <div className="header-name">
                    More sales from this collection
                  </div>
                </div>
                <div className="collapse toggle-body" id="collapseMore">
                  <div className="row">
                    {nfts.map((nft, index) => (
                      <div className="col-xl-2 col-sm-3 col-12 board-item" key={index}>
                        <Board nft={nft} index={index} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Styles>
      <Footer />
    </>
  );
}
export default NFTs
