import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import Header from "../../components/header/headerEmptyPage";
import Footer from "../../components/footer/footerEmptyPage";
import { setCookie, getCookie } from '../../service/cookie';
import siteConfig from '../../config/site.config';
import { Styles } from '../../layouts/style/registerStyle';
import { states, countries, toTitlecase } from "../../service/string";
import { create, generateWallet } from '../../api/merchant';
import { toast } from 'react-toastify';

const Register = () => {
  const navigate = useNavigate()
  const [legal, setLegal] = useState('');
  const [dba, setDba] = useState('');
  const [handle, setHandle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneMax, setPhoneMax] = useState(false)
  const [address, setAddress] = useState('');
  const [suite, setSuite] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState(states[0]);
  const [country, setCountry] = useState(countries[0])
  const [zipcode, setZipcode] = useState('')
  const [isRule, setIsRule] = useState(false)
  const [isTerms, setIsTerms] = useState(false)

  const handleChangeRegal = (e) => {
    const _legal = e.target.value;
    setLegal(_legal)
    setDba(_legal)
    const rand_num = Math.floor(Math.random() * 9000) + 1000;
    setHandle(_legal.replace(/\s/g, "").toLowerCase() + rand_num);
  }
  const handleChangeDba = (e) => {
    const _dba = e.target.value
    setDba(_dba);
    const rand_num = Math.floor(Math.random() * 9000) + 1000;
    setHandle(_dba.replace(/\s/g, "").toLowerCase() + rand_num);
  }
  const keypressZipcode = (e) => {
    if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
      e.preventDefault();
    }
  }
  const keypressPhone = (e) => {
    if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
      e.preventDefault();
    }
  }
  const handleChangePhone = (e) => {
    const _phone = e.target.value
    const _length = _phone.length
    if (_length === 3 && _phone.indexOf("(") <= -1) {
      setPhone("(" + _phone + ")" + "-");
    } else if (_length === 4 && _phone.indexOf("(") > -1) {
      setPhone(_phone + ")-");
    } else if (_length === 5 && _phone.indexOf(")") > -1) {
      setPhone(_phone + "-");
    } else if (_length === 9) {
      setPhone(_phone + "-");
      setPhoneMax(true)
    }
    else
      setPhone(_phone)
  }
  const validate = () => {
    let res = true
    if (legal === '')
      res = false
    if (dba === '')
      res = false
    if (handle === '')
      res = false
    if (firstName === '')
      res = false
    if (lastName === '')
      res = false
    if (email === '')
      res = false
    if (phone === '')
      res = false
    if (!res)
      NotificationManager.warning('Please input required fields', 'Worning', 3000);
    return res
  }
  const handleRegister = async (e) => {
    try {
      e.preventDefault()
      // if (!validate())
      //   return;
      const session_id = getCookie("veev_session_id");
      const user_id = getCookie("veev_current_user_id");
      const ident_token = getCookie("veev_current_user_ident_token");
      const user_email = getCookie("veev_current_user_email");
      if (session_id == null)
        return
      const res = await create({
        user: {
          email: email,
          password: "passofmerchantaccount",
          handle: handle,
          session_id: session_id,
          merchant: {
            legal_name: toTitlecase(legal),
            first_name: toTitlecase(firstName),
            last_name: toTitlecase(lastName),
            business_email: email,
            phone: phone,
            address: address,
            suite: suite,
            city: city,
            state: state,
            zip: zipcode,
            category: "coffee shop",
            tax_info: "0.9",
            ssn: "",
            ein: "",
            state_incorporated: "",
            tipping: "true",
            credit_name: toTitlecase(legal) + Date.now(),
            dba: toTitlecase(dba)
          }
        },
      }, user_email, ident_token);
      if (res.success === false) {
        NotificationManager.warning("This merchant is invalid. Please try again", "Warning", 3000);
        return
      }
      if (res.type === 'merchant_created_response') {
        const current_user = res.current_user;
        const merchant_information = res.merchant_information;
        const selected_merchant_address = merchant_information.address + ' ' + merchant_information.city + ' ' + merchant_information.state + ' ' + merchant_information.zip;

        setCookie('signin_session_id', session_id, siteConfig.expired);
        setCookie('signin_merchant_code', merchant_information.merchant_code, siteConfig.expired);
        setCookie('signin_merchant_id', merchant_information.id, siteConfig.expired);
        setCookie('signin_merchant_user_id', merchant_information.user_id, siteConfig.expired);
        setCookie("signin_current_user_id", current_user.id, siteConfig.expired);
        setCookie('signin_merchant_name', merchant_information.legal_name, siteConfig.expired);
        setCookie('current_ident_token', current_user.ident_token, siteConfig.expired);
        setCookie("current_user_email", current_user.email, siteConfig.expired);
        setCookie('selected_merchant_address', selected_merchant_address, siteConfig.expired);

        const resWallet = await generateWallet({
          user: {
            user_id: merchant_information.user_id
          }
        })
        if (resWallet.success === false) {
          NotificationManager.warning("This merchant is invalid. Please try again", "Warning", 3000)

          return
        }
        else {
          const wallet = resWallet.wallet
          setCookie("current_merchant_wallet_address", wallet.address, siteConfig.expired);
          /*
          const redirect_url = res.redirect_to
          if (redirect_url != undefined && redirect_url != "") {
              window.location.href = redirect_url
          } else {
          */
          navigate("/bank/transactions/checkout")
          //}
        }
      }
      else {
        NotificationManager.warning("Invalid. Please try again later.", "Warning", 3000);
      }
    } catch (e) {

      toast(e.message, {
        position: "bottom-left",
        type: "error"
      })
    }
  }
  return (
    <>
      <Header />
      <Styles>
        <form onSubmit={handleRegister}>
          <section className="signup">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-left">
                  <h3>Business Account Sign Up</h3>
                  <div className="area">
                    <p>
                      Complete all <strong>required</strong> fields in the form
                      below to setup Stores, Event Listings and Groups. After
                      setup you can start accepting orders, selling tickets and
                      processing payments.&nbsp;
                    </p>
                    <div className="form-well col-md-12">
                      <div id="event-create-form" className="col">
                        <div className="row">
                          <div className="col-md-12">
                            <fieldset id="">
                              <legend>Contact Information</legend>
                            </fieldset>
                          </div>
                          {/* legal name */}
                          <div className="col-md-6 form-group">
                            <fieldset>
                              <label
                                htmlFor="id_legal_name"
                                className="ak-is-overlaid cursor"
                              >
                                Legal Name
                                <span className="ak-required-flag">*</span>
                              </label>
                              <input
                                id="id_legal_name"
                                type="text"
                                className="form-control mo-userfield-input ak-has-overlay m-0"
                                required="required"
                                value={legal}
                                onChange={handleChangeRegal}
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-6 form-group"></div>
                          {/* Dong Business AS */}
                          <div className="col-md-6 form-group">
                            <fieldset>
                              <label
                                htmlFor="id_dba"
                                className="ak-is-overlaid cursor"
                              >
                                DBA<span className="ak-required-flag">*</span>
                              </label>
                              <input
                                id="id_dba"
                                type="text"
                                className="form-control mo-userfield-input ak-has-overlay m-0"
                                required="required"
                                value={dba}
                                onChange={handleChangeDba}
                              />
                            </fieldset>
                          </div>
                          {/* Handle name */}
                          <div className="col-md-6 form-group">
                            <fieldset>
                              <label
                                htmlFor="id_handle"
                                className="ak-is-overlaid cursor"
                              >
                                Handle Name
                                <span className="ak-required-flag">*</span>
                              </label>
                              <input
                                id="id_handle"
                                type="text"
                                className="form-control mo-userfield-input ak-has-overlay m-0"
                                required="required"
                                value={handle}
                                onChange={(e) => setHandle(e.target.value)}
                              />
                            </fieldset>
                          </div>
                          {/* first name */}
                          <div className="col-md-6 form-group">
                            <fieldset>
                              <label
                                htmlFor="id_fname"
                                className="ak-is-overlaid cursor"
                              >
                                First Name(Owner)
                                <span className="ak-required-flag">*</span>
                              </label>
                              <input
                                id="id_fname"
                                type="text"
                                className="form-control mo-userfield-input ak-has-overlay m-0"
                                required="required"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </fieldset>
                          </div>
                          {/* last name */}
                          <div className="col-md-6 form-group">
                            <fieldset>
                              <label
                                htmlFor="id_lname"
                                className="ak-is-overlaid cursor"
                              >
                                Last Name(Owner){" "}
                                <span className="ak-required-flag">*</span>
                              </label>
                              <input
                                id="id_lname"
                                type="text"
                                className="form-control mo-userfield-input ak-has-overlay m-0"
                                required="required"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </fieldset>
                          </div>
                          {/* business email */}
                          <div className="col-md-6 form-group">
                            <fieldset>
                              <label
                                htmlFor="id_email"
                                className="ak-is-overlaid cursor"
                              >
                                Business Email Address
                                <span className="ak-required-flag">*</span>
                              </label>
                              <input
                                id="id_email"
                                type="email"
                                className="form-control mo-userfield-input ak-has-overlay m-0"
                                required="required"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </fieldset>
                          </div>
                          {/* phone */}
                          <div className="col-md-6 form-group">
                            <fieldset>
                              <label
                                htmlFor="id_phone"
                                className="ak-is-overlaid cursor"
                              >
                                Phone<span className="ak-required-flag">*</span>
                              </label>
                              <input
                                id="id_phone"
                                type="phone"
                                className="form-control  ak-has-overlay m-0 phone-format"
                                required="required"
                                value={phone}
                                onChange={handleChangePhone}
                                onKeyPress={keypressPhone}
                                maxLength={phoneMax ? 14 : 0}
                              />
                            </fieldset>
                          </div>
                          {/* street address */}
                          <div className="col-md-6 form-group">
                            <fieldset>
                              <label
                                htmlFor="id_address1"
                                className="ak-is-overlaid cursor"
                              >
                                Street Address
                                <span className="ak-required-flag">*</span>
                              </label>
                              <input
                                id="id_address1"
                                type="text"
                                className="form-control mo-userfield-input ak-has-overlay m-0"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            </fieldset>
                          </div>
                          {/* suite */}
                          <div className="col-md-6 form-group">
                            <fieldset>
                              <label
                                htmlFor="id_suite"
                                className="ak-is-overlaid cursor"
                              >
                                Suite
                              </label>
                              <input
                                id="id_suite"
                                type="text"
                                className="form-control mo-userfield-input ak-has-overlay m-0"
                                value={suite}
                                onChange={(e) => setSuite(e.target.value)}
                              />
                            </fieldset>
                          </div>
                          {/* city */}
                          <div className="col-md-6 form-group">
                            <fieldset>
                              <label
                                htmlFor="id_city"
                                className="ak-is-overlaid cursor"
                              >
                                City<span className="ak-required-flag">*</span>
                              </label>
                              <input
                                id="id_city"
                                type="text"
                                required="required"
                                className="form-control mo-userfield-input ak-has-overlay m-0"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              />
                            </fieldset>
                          </div>
                          {/* state */}
                          <div className="col-md-6 form-group">
                            <fieldset>
                              <label>
                                State<span className="ak-required-flag">*</span>
                              </label>
                              <select
                                className="form-control ak-has-overlay"
                                id="id_state"
                                required="required"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                              >
                                {states.map((item, index) => (
                                  <option value={item.val} key={index}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </fieldset>
                          </div>
                          {/* country */}
                          <div className="col-md-6 form-group">
                            <fieldset>
                              <label htmlFor="country">
                                Country<span className="ak-required-flag">*</span>
                              </label>
                              <select
                                className="form-control ak-has-overlay"
                                required="required"
                                id="id_country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                              >
                                {countries.map((item, index) => (
                                  <option value={item} key={index}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </fieldset>
                          </div>
                          {/* zip code */}
                          <div className="col-md-6 form-group">
                            <fieldset>
                              <label
                                htmlFor="id_zip"
                                className="ak-is-overlaid cursor"
                              >
                                ZIP Code
                                <span className="ak-required-flag">*</span>
                              </label>
                              <input
                                id="id_zip"
                                type="text"
                                maxLength="5"
                                className="form-control mo-userfield-input ak-has-overlay m-0 zip-format"
                                required="required"
                                value={zipcode}
                                onKeyPress={keypressZipcode}
                                onChange={(e) => setZipcode(e.target.value)}
                              />
                            </fieldset>
                          </div>
                        </div>
                      </div>
                      <fieldset id="ground-rules">
                        <legend>By submitting this form you agree:</legend>
                        <div className="ak-checkbox-field mobile-checkbox-button ak-errs-below ak-err-below">
                          <input
                            id="id_event_termsandconds"
                            type="checkbox"
                            className="checkbox-inline"
                            value={isTerms}
                            onChange={(e) => setIsTerms(e.target.checked)}
                          />
                          <label
                            className="cb-label highlighted"
                            htmlFor="id_event_termsandconds"
                          >
                            <span className="glyphicon glyphicon-ok-sign"></span>
                            <em>Required</em> — I agree to the Terms &amp;
                            Conditions.
                          </label>
                        </div>
                        <div className="ak-checkbox-field mobile-checkbox-button ak-errs-below ak-err-below">
                          <input
                            id="id_event_host_ground_rules"
                            type="checkbox"
                            className="checkbox-inline"
                            value={isRule}
                            onChange={(e) => setIsRule(e.target.checked)}
                          />
                          <label
                            className="cb-label highlighted"
                            htmlFor="id_event_host_ground_rules"
                          >
                            <span className="glyphicon glyphicon-ok-sign"></span>
                            <em>Required</em> — I agree to the rules for hosting
                            an event.
                          </label>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="section-heading text-center submit-button">
            <button
              className="btn btn-success btn-lg btn-create-account"
              id="signup_btn"
              type='submit'
            >
              Create Account
            </button>
          </div>
        </form>
      </Styles>
      <Footer />
    </>
  );
}
export default Register
