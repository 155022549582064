import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from "../../../components/header/headerHomePage";
import Footer from "../../../components/footer/footerEmptyPage";
import { getAll as getStores } from '../../../api/stores'
import { useAsync } from '../../../service/utils'
import { Styles } from '../../../layouts/style/storesStyle'
import {Avatar, Rate, Space, Row, Col} from "antd";

const Stores = () => {
  const { data, status, error, run } = useAsync({
    status: "idle",
  });
  const [stores, setStores] = useState([])
  
  useEffect(() => {
    run(getStores())
  }, [run])
  useEffect(() => {
    if (status === 'resolved') {
      console.log(data)
      const sts = data.map((item) => {
        let store_url = item.legal_name
        store_url = store_url.toLowerCase()
        store_url = store_url.replaceAll(" ", "_")
        store_url = store_url.replaceAll("'", "")
        item.store_url = store_url
        return item
      })
      setStores(sts)
    }
    else if (status === 'rejected') {
      console.log(error)
    }
  }, [status])
  return (
    <>
      <Header />
      <Styles>

        <div className="container">
          <Row gutter={[24, 24]}>
            <Col span={14}>
              {
                stores.map((store, index) => (
                    <Link to={`/stores/${store.store_url}/${store.merchant_code}`} key={index} className="card">
                      <div className="card-body">
                        <Space size={24}>
                          <Avatar
                              style={{fontSize: 42}}
                              shape="square"
                              size={132}
                              src={store.logo}>
                            {store.legal_name?.[0]}
                          </Avatar>
                          <div>
                            <h4>{store.legal_name}</h4>
                            <Rate disabled value={4}/>
                            <p>{store.description}</p>
                            <p>{store.city}, {store.state}, {store.zip}</p>
                          </div>
                        </Space>
                      </div>
                    </Link>
                ))
              }
            </Col>
            <Col span={10}>
              <div className="card">
                <div className="card-body">

                </div>
              </div>
            </Col>
          </Row>
        </div>


        {/*<div className="stores">*/}
        {/*  <section class="container">*/}
        {/*     <div class="tab-part">*/}
        {/*      <Nav directory="stores" />*/}
        {/*      <div className="selected-merchant-name">*/}
        {/*        Stores*/}
        {/*        <span className="selected-nav-top-active"> &gt; All </span>*/}
        {/*      </div>*/}
        {/*    </div> */}
        {/*    <div className="row h-100">*/}
        {/*      <div className="col-md-12">*/}
        {/*         <div className="devsite-section-nav">*/}
        {/*          <div className="row mt-sm-3">*/}
        {/*            <div className="col-md-12">*/}
        {/*              <p href="#" className="trx-left-nav active"></p>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div> */}
        {/*        <div style={{paddingTop: '40px'}}>*/}
        {/*           Main Transactions part begin. */}
        {/*          <div className="devsite-section-article">*/}
        {/*            <ul className="row">*/}
        {/*            {stores.map((store, index) => (*/}
        {/*              <Link className="col-lx-4 col-lg-4 col-sm-12 store_container" key={index} to={`/stores/${store.store_url}/${store.merchant_code}`}>*/}
        {/*                <li id="store_a">*/}
        {/*                  <section class="store_logo_wrapper" style={{backgroundImage: `url(${store.logo})`}}>*/}
        {/*                    <img class="store_logo" src={store.logo} />*/}
        {/*                  </section>*/}
        {/*                  <section class="store_name_address">*/}
        {/*                    <section class="firstChild">*/}
        {/*                      <h2> {store.legal_name} </h2>*/}
        {/*                      <section id="store_street_address">*/}
        {/*                        <h3 class="store_address_complete"> {store.street}, {store.city}, {store.state}, {store.zip}</h3>*/}
        {/*                      </section>*/}
        {/*                    </section>*/}
        {/*                    <section class="secondChild">*/}
        {/*                      <div class="store_rating">*/}
        {/*                        5.0*/}
        {/*                      </div>*/}
        {/*                    </section>*/}
        {/*                  </section>*/}
        {/*                </li>*/}
        {/*              </Link>*/}
        {/*            ))}*/}
        {/*            </ul>*/}
        {/*          </div>            */}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </section>*/}
        {/*</div>*/}
      </Styles>
      <Footer />
    </>  
  )
}
export default Stores
