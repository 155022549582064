import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import HeaderHome from "../../components/header/headerHomePage";
import FooterHome from "../../components/footer/footerPage";
import { Styles } from '../../layouts/style/homeStyle'
import Plans from './plans_user'

const Home = () => {
  const [slider, setSlider] = useState('')
  const sliders = useMemo(() => [
    // "../assets/img/veev_app_order.png",
    // "../assets/img/veev_nft.png"
    // "../assets/img/veev_connect.png",
    "../assets/img/veev_id.png",
    "../assets/img/veev_contactid.png",
    "../assets/img/veev_checkin.png",
    "../assets/img/veev_checkout.png",
    "../assets/img/veev_accessid.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider(sliders[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders.length)
        index = 0
      setSlider(sliders[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders])

  const [loop, setloop] = useState('')
  const loops = useMemo(() => [
  // "VEEV Digital Identity",
  // "Create Once Use Everywhere",
  // "Share Contacts, Pay, Checkin, Sign Up",
  // "In One Click",
  // "../assets/img/1.png",
   "../assets/img/2.png",
  // "../assets/img/3.png",
  //"../assets/img/4.png",
  // "<p className="title">Checkin Checkout</p>",
  // "<p className="title1">& Earn Rewards</p>",
  // "<p>One click checkin & checkout at bars, cafes, events, stores, websites & more ...</p>",

  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setloop(loops[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= loops.length)
        index = 0
      setloop(loops[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [loops])

  return (
    <>
    <HeaderHome />
      <Styles>
      <div className="guide" id="app" style={{paddingTop: 0, paddingBottom: 0}}>   
        <section className="cta text-center" style={{paddingTop: 70, paddingBottom: 50}}>
          <div className="container">
            <div className="col-lg-12 my-auto">  
              <h className="title">Shop From Social Apps & Stores Securely</h>
              <h5 style={{ width: '100%', paddingBottom: '30px', paddingTop: '10px', paddingLeft: '0px', color: '#000000'}}>
              Shop from social apps, stores and websites without signing up, creating accounts, passwords, providing credit card, ID, phone number. Manage payments, billing, receipts, returns and shipping from one location with the VEEV App.
              </h5>
            </div>
          </div>
          
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-8">  
                  <img src={'../assets/img/checkout_app.png'} alt="VEEV Checkout" className="img-fluid2"/>
                  </div>
                  <div className="col-lg-4 text-left" style={{width: '100%', paddingTop: 100, paddingLeft: '0px' }}>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Pay, Book, RSVP</p>
                    <p style={{ width: '100%', color: 'grey' }}>manage all payment, bookings & rsvp on social apps & stores from one place</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '0px', color: 'grey' }}> 
                    <p className="title4">Billing & Shipping</p>
                    <p style={{ width: '100%', color: 'grey' }}>no more entering billing & shipping details over and over on multiple apps</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '0px', color: 'grey' }}> 
                    <p className="title4">Data Protection</p>
                    <p style={{ width: '100%', color: 'grey' }}>protect personal, financial, identity & payment data from fraud, theft and resale</p>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          </div> 
          <div className="text-center">
            <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
            <img className="wallet" src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png" style={{ marginBottom: 40, height: 38 }} alt="VEEV App" /></a>
          </div>	
        </section>
         
        {/* <section className="cta text-center" style={{paddingTop: 70, paddingBottom: 50}}>
          <div className="container">
            <div className="col-lg-12 my-auto">  
              <h className="title">Social Payments App</h>
              <h3 style={{ width: '100%', paddingBottom: '0px', paddingTop: '10px', paddingLeft: '0px', color: 'grey'}}>Manage payments, billing, shipping details across all social platforms in one place.</h3> 
            </div>
          </div>
          <div>
            <img src="../assets/img/veev_web3_identity.png" style={{paddingTop: 20, width: '100%'}} className="img-fluid" alt="" /> 
          </div>
            
          <div className="container" style={{ paddingTop: '20px', paddingBottom: '25px'}}>
            <div className="row">
              <div className="col-lg-1 dv-loadmoney"></div>
              <div className="col-lg-3" style={{paddingTop: 0}}>
                <div className="section-heading text-center" style={{paddingTop: 0}}>
                  <h3 style={{ width: '100%', paddingBottom: '0px', paddingTop: '0px', paddingLeft: '0px', color: '#000', fontWeight: '500'}}>Pay, Book, RSVP</h3>
                  <p style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', paddingLeft: '0px', color: 'grey'}}>
                  manage all payment, bookings & rsvp across all social apps from one place 
                  </p>                   
                </div>
              </div>
              <div className="col-lg-1 dv-loadmoney">
              </div>
              <div className="col-lg-3 dv-loadmoney" style={{paddingTop: 0}}>                  
                <div className="section-heading text-center" style={{paddingTop: 0}}>
                  <h3 style={{ width: '100%', paddingBottom: '0px', paddingTop: '0px', paddingLeft: '0px', color: '#000', fontWeight: '500'}}>Billing & Shipping</h3>
                  <p style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', paddingLeft: '0px', color: 'grey'}}>
                  no more entering billing & shipping details over and over on multiple apps
                  </p>                   
                </div>
              </div>
              <div className="col-lg-3" style={{paddingTop: 0}}>    
                <div className="section-heading text-center" style={{paddingTop: 0}}>
                  <h3 style={{ width: '100%', paddingBottom: '0px', paddingTop: '0px', paddingLeft: '0px', color: '#000', fontWeight: '500'}}>Data Protection</h3>
                  <p style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', paddingLeft: '0px', color: 'grey'}}>
                    protect your personal, financial, identity and transaction data from fraud, theft and resale
                  </p>                   
                </div>
              </div>
              <div className="col-lg-1 dv-loadmoney"></div>
            </div>
            
            <div className="row">   
              <div className="col-lg-3" style={{paddingTop: 0}}>             
                <div className="section-heading text-center" style={{paddingTop: 20}}>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                    <p className="title4">ID Verification</p>
                    One click age & ID Verificatio at bars, clubs & events.
                    </div>  
                </div>
              </div>
                  
              <div className="col-lg-3" style={{paddingTop: 0}}>
                <div className="section-heading text-center" style={{paddingTop: 20}}>
                  <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                    <p className="title4">Sign Up</p>
                    One click QR signup for events, promotions & more.
                    </div>  
                </div>
              </div>

              <div className="col-lg-3" style={{paddingTop: 0}}> 
                <div className="section-heading text-center" style={{paddingTop: 0}}>
                  <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '20px', color: 'grey' }}> 
                    <p className="title4">Scheduling</p>
                    One click appointment scheduling & RSVP. 
                  </div>  
                </div>
              </div>

              <div className="col-lg-3" style={{paddingTop: 0}}>
                <div className="section-heading text-center" style={{paddingTop: 20}}>
                  <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                    <p className="title4">Pay</p>
                    One click pay at stores, online, send & receive money.
                  </div>  
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-1 dv-loadmoney"></div>
                <div className="col-lg-3" style={{paddingTop: 0}}>
                  <div className="section-heading text-center" style={{paddingTop: 20}}>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                      <p className="title4">Contacts & Links</p>
                      Share contact info and all your links in one click. 
                      </div>  
                  </div>
                </div>
                <div className="col-lg-1 dv-loadmoney">
                </div>

                <div className="col-lg-3 dv-loadmoney" style={{paddingTop: 0}}>                  
                  <div className="section-heading text-center" style={{paddingTop: 20}}>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                      <p className="title4">Recommendations</p>
                      Recommendations of stores, events, clubs cool & hot spots.
                      </div>  
                  </div>
                </div>
                <div className="col-lg-3" style={{paddingTop: 0}}>    
                  <div className="section-heading text-center" style={{paddingTop: 0}}>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '20px', color: 'grey' }}> 
                      <p className="title4">Social</p>
                      Social space to share recommendations.
                    </div>  
                  </div>
                </div>
                <div className="col-lg-1 dv-loadmoney"></div>
                </div>

                <div className="row">
                  <div className="col-lg-1 dv-loadmoney"></div>
                  <div className="col-lg-3" style={{paddingTop: 0}}>
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                        <p className="title4">Wallet</p>
                        Share contact info and all your links in one click. 
                        </div>  
                    </div>
                  </div>   
                  <div className="col-lg-1 dv-loadmoney"></div>

                  <div className="col-lg-3 dv-loadmoney" style={{paddingTop: 0}}>     
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                        <p className="title4">ID Verification</p>
                        Recommendations of stores, events, clubs cool & hot spots.
                        </div>  
                    </div>
                  </div>          
                  <div className="col-lg-3" style={{paddingTop: 0}}>
                    <div className="section-heading text-center" style={{paddingTop: 0}}>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '20px', color: 'grey' }}> 
                        <p className="title4">Save Money</p>
                        Social space to share recommendations.
                      </div>  
                    </div>
                  </div>                
              <div className="col-lg-1 dv-loadmoney"></div>  
            </div>
          </div>

          <div className="text-center">
            <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
            <img className="wallet" src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png" style={{ marginTop: 0, height: 38 }} alt="VEEV Wallet" /></a>
          </div>	
        </section> */}

        <section className="cta text-center" style={{paddingTop: 100, paddingBottom: 50}}>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <p className="title1 text-center">Create Once Use Everywhere</p>
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3" style={{paddingTop: 33}}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/store.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center" style={{paddingTop: 0}}>
                      <h3 style={{ width: '100%', paddingBottom: '0px', paddingTop: '20px', paddingLeft: '0px', color: '#000', fontWeight: '500'}}>Pay, Book, RSVP</h3>
                      <p style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', paddingLeft: '0px', color: 'grey'}}>
                      manage all payment, bookings & rsvp across all social apps from one place 
                      </p>                   
                    </div>
                    <div className="text-center">
                      <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
                      <img className="wallet" src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png" style={{ marginBottom: 40, height: 38 }} alt="VEEV App" /></a>
                    </div>	
                  </div>
                  <div className="col-lg-2 dv-loadmoney">
                  </div>
                  <div className="col-lg-3 dv-loadmoney" style={{paddingTop: 33}}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/checkout.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center" style={{paddingTop: 0}}>
                      <h3 style={{ width: '100%', paddingBottom: '0px', paddingTop: '20px', paddingLeft: '0px', color: '#000', fontWeight: '500'}}>Billing & Shipping</h3>
                      <p style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', paddingLeft: '0px', color: 'grey'}}>
                      no more entering billing & shipping details over and over on multiple apps
                      </p>                   
                    </div>
                    <div className="text-center">
                      <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
                      <img className="wallet" src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png" style={{ marginBottom: 40, height: 38 }} alt="VEEV App" /></a>
                    </div>	
                  </div>
                  <div className="col-lg-1 dv-loadmoney">
                  </div>  
                  <div className="col-lg-3" style={{paddingTop: 33}}>   
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/recommendations.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center" style={{paddingTop: 0}}>
                      <h3 style={{ width: '100%', paddingBottom: '0px', paddingTop: '20px', paddingLeft: '0px', color: '#000', fontWeight: '500'}}>Data Protection</h3>
                      <p style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', paddingLeft: '0px', color: 'grey'}}>
                        protect personal, financial, transaction & identity data from fraud, theft and resale
                      </p>                   
                    </div>
                    <div className="text-center">
                      <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
                      <img className="wallet" src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png" style={{ marginBottom: 40, height: 38 }} alt="VEEV App" /></a>
                    </div>	
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/* <section className="cta" id="connect">
          <div className="container">	
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                <p className="title text-center">Create Once Use Everywhere</p>
                <p>Manage ID verification, access & payments for bars, events and venues.</p>  
                </div>
              </div>
            </div>
          </div>
          <div class="sticky-container" style={{width: '100%'}}>
            <div class="app-demo no-upper-margin" style={{width: '918px'}}>
              <div class="phone_column" style={{width: '200px'}}>
                <div style={{maxHeight: 'auto', paddingTop: 50}}>
                  <div className="device-mockup iphone6 portrait gold">
                    <div className="device">
                      <div className="screen">
                      <img src="../assets/img/veev_id.png" className="img-fluid2" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                
                <div style={{maxHeight: 'auto', paddingTop: 50}}>
                  <div className="device-mockup iphone6 portrait gold">
                    <div className="device">
                      <div className="screen">
                      <img src="../assets/img/veev_checkinevents.png" className="img-fluid2" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                
                <div style={{maxHeight: 'auto', paddingTop: 50}}>
                  <div className="device-mockup iphone6 portrait gold">
                    <div className="device">
                      <div className="screen">
                      <img src="../assets/img/veev_contactid.png" className="img-fluid2" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="text_column" style={{width: '100%'}}>
                <div class="text_holder" style={{width: '500px'}}>
                  <div class="text_content" style={{width: '500px'}}>
                    <div  >
                      <p className="title3black">1. Signup for VEEV Identity</p>
                      <p>VEEV is a ID Verification & Protection app. Protect personal & financial data, privacy, contact info, credit card & passwords from theft and fraud.</p> 
                      <br></br>    
                    </div>
                    <div  >
                      <p className="title3black">2. Create Once Use Everywhere</p>
                      <p>Create your ID once and use everywhere to share contact details, checkin into bars, clubs, events, venues, pay & signup.</p>
                      <br></br> 
                    </div>
                    <div>
                      <p className="title3black">3. Keep Your Data Private</p>
                      <p>Keep personal & financial data, privacy, contact, credit card & passwords data private safe from theft, fraud or from being sold.</p> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="cta">
          <div className="container container--main">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-4 my-auto text-center" style={{ alignItems: 'center' }}>

                      <div>
                      <p className="title">Connect ID</p>
                      </div>

                    </div>
                    <div className="col-lg-1 dv-loadmoney" style={{ paddingBottom: '50px' }}>
                    </div>
                    <div className="col-lg-3 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                      <div className="device-mockup iphone6 portrait gold">
                          <div className="device">
                            <div className="screen">
                            <img src="../assets/img/veev_contactid.png" className="img-fluid2" alt="" />
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="col-lg-4" style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                      <div >
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '25px', paddingRight: '20px', color: 'grey' }}>
                        <p className="title4">Connect</p>
                        Connect is a digital business card with community engagement and notification tools to keep your community informed.  
                        </div>
                        <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '20px', color: '' }}> 
                        <p className="title4">Contact Sharing</p>
                        QR based contact sharing tool to share contact information in one click.
                        </div>
                        
                        <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '20px', color: 'grey' }}> 
                        <p className="title4">Notifications</p>
                        Send notifications, call to action, alerts and updates to your community. 
                        </div>
                        <div style={{ width: '100%', paddingBottom: '0px', paddingRight: '20px', color: 'grey' }}> 
                        <a className="badge-link" style={{ display: 'block' }} href="https://apps.apple.com/us/app/veev-mobile-id/id1508649527">
                          <img src="../assets/img/appstore-apple-light.svg" style={{ marginTop: 20, height: 38 }} alt="VEEV" />
                        </a> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="cta">
          <div className="container container--main">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-4 my-auto text-center" style={{ alignItems: 'center' }}>

                      <div>
                      <p className="title">Checkin ID</p>
                      </div>

                    </div>
                    <div className="col-lg-1 dv-loadmoney" style={{ paddingBottom: '50px' }}>
                    </div>
                    <div className="col-lg-3 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                      <div className="device-mockup iphone6 portrait gold">
                          <div className="device">
                            <div className="screen">
                            <img src="../assets/img/veev_id.png" className="img-fluid2" alt="" />
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="col-lg-4" style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                      <div >
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '25px', paddingRight: '20px', color: 'grey' }}>
                        <p className="title4">Connect</p>
                        Connect is a digital business card with community engagement and notification tools to keep your community informed.  
                        </div>
                        <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '20px', color: '' }}> 
                        <p className="title4">Contact Sharing</p>
                        QR based contact sharing tool to share contact information in one click.
                        </div>
                        
                        <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '20px', color: 'grey' }}> 
                        <p className="title4">Notifications</p>
                        Send notifications, call to action, alerts and updates to your community. 
                        </div>
                        <div style={{ width: '100%', paddingBottom: '0px', paddingRight: '20px', color: 'grey' }}> 
                        <a className="badge-link" style={{ display: 'block' }} href="https://apps.apple.com/us/app/veev-mobile-id/id1508649527">
                          <img src="../assets/img/appstore-apple-light.svg" style={{ marginTop: 20, height: 38 }} alt="VEEV" />
                        </a> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="cta text-center" style={{paddingTop: 50, paddingBottom: 50}}>
          <div className="container">
            <div className="col-lg-12 my-auto">  
              <h className="title">How It Works</h>
            </div>
          </div>
          <Toggle></Toggle>
        </section> */}

        {/* <section className="features-gray" style={{paddingTop: 50, paddingBottom: 50}}>
        <div className="container">
          <div className="col-lg-12 text-center">  
            <p className="title" style={{paddingBottom: 0}}>Get Your NFT Key</p>
          </div>
        </div>
        <div className="container">
          <div className="col-lg-12">      
            <div className="container-fluid">
              <div className="row">   
                <div className="col-lg-3 dv-loadmoney text-center" style={{maxHeight: 'auto', paddingTop: 50}}>
                  <div className="device-mockup iphone6 portrait gold">
                    <div className="device">
                      <div className="screen">
                      <img src="../assets/img/veevid2022.png" className="img-fluid2" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9" style={{width: '100%', paddingTop: 50, paddingLeft: '30px' }}>
                  
                  <p className="title4" style={{paddingTop: 0}}>Protect Your Identity & Privacy</p>
                  VEEV is a NFT Access Key that uses the Blockchain to protect your identity, privacy, credit card, password data + + + from being sold, theft & fraud.
                  
                  <p className="title4" style={{paddingTop: 25}}>Own Your Identity, Data & Brand </p>
                  Your VEEV identity, username and key belongs to you. You own it. You control it. It is your Brand. VEEV CANNOT SELL your identity, data or brand because we don't have it, unlike in other social media and search engine websites, where they own your identity, brand and data and profit by selling it.
                  
                  <p className="title4" style={{paddingTop: 25}}>Create Once Use Everywhere</p>
                  VEEV ID Score reflects the strenght of your identity. The higher your ID score the stronger your identity. The more places your use your ID the higher your score.

                  <p className="title4" style={{paddingTop: 25}}>VEEV ID Score</p>
                  VEEV ID Score reflects the strenght of your identity. The higher your ID score the stronger your identity. The more places your use your ID the higher your score.

                  <p className="title4" style={{paddingTop: 25}}>Your ID Your Brand</p>
                  Your ID username is your brand. User your VEEV ID username everywhere and own your brand.
                  
                  <p className="title4" style={{paddingTop: 25}}>Earn Money with Your Identity</p>
                  The owner of the identity can earn money. The higher your ID score the more you can earm. Advertisers will come directly to you as versus paying a website to reach you.              
                  
                  <h4 style={{paddingTop: 25}}>VEEV ID Score</h4>
                  - VEEV ID score is a new rating system for the crypto verse. VEEV ID scores are assigned to every NFT. The more the NFT is used the higher the score. The score is used to access financial, borrow, lend and DeFi services.

                  <h4 style={{paddingTop: 25}}>NFT & Crypto Wallet</h4>
                  <h className="section-heading">
                  - The wallet holds access, logins, and passwords which give to you access to Checkin to bars, events, signup, login to websites and Checkout at stores, pay and order online. As wel as NFTs and crypto currencies.<br /></h>

                </div> 
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="text-center">
            <a className="badge-link" href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1380885775&mt=8"><br />
            <img className="wallet" src="https://staging.veev.app/img/app-store-badge.svg" style={{ marginTop: 20, height: 38 }} alt="VEEV Wallet" /></a>
          </div>		
        </div>
        </section> */}

        {/* <section className="features-gray" style={{paddingTop: 50, paddingBottom: 50}}>
          <div className="container">
            <div className="col-lg-12 text-center">  
              <h className="title">Own Your Identity</h>
              <p></p>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-4 my-auto text-center">
                      <p className="title3" style={{ marginBottom: 0 }}>Your Identity Is Your</p>
                      <p className="title">Brand</p>
                      <p>Your VEEV username belongs to you. You control it and you can generate income with it. You don't own your username on social media sites and search engines. They own your identity and profit selling your data. Your ID username is your brand. User your VEEV ID username everywhere and own your brand. </p>
                        <a className="badge-link" style={{ display: 'block' }} href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1380885775&mt=8">
                          <img src="../assets/img/appstore-apple-light.svg" style={{ marginTop: 20, height: 38 }} alt="VEEV" />
                        </a> 
                    </div>
                    <div className="col-lg-1 dv-loadmoney">
                    </div>
                    <div className="col-lg-3 dv-loadmoney text-center" style={{maxHeight: 'auto', marginTop: 20}}>
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veevid.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4" style={{ width: '100%', paddingLeft: '30px' }}>

                      <div style={{ width: '100%', paddingBottom: '50px', paddingTop: 50, paddingRight: '20px'  }}>
                      <h4><b>VEEV ID Score</b></h4>
                      VEEV ID Score reflects the strenght of your identity. The higher your ID score the stronger your identity. The more places your use your ID the higher your score.
                      </div>
                      <div style={{ width: '100%', paddingBottom: '0px', paddingRight: '20px'  }}> 
                      <h4><b>Earn with Your ID</b></h4>
                      The owner of the identity can earn money. The higher your ID score the more you can earm. Advertisers will come directly to you as versus paying a website to reach you. 
                      </div>           
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </section> */}

        {/* <section className="cta" id="access" style={{paddingTop: 40, paddingBottom: 40}}>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}> 
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veevid2022.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9" style={{ width: '100%', paddingLeft: '30px' }}>
                    <h className="title">Access</h>
                    <br></br>
                    VEEV proves "you are you" so you can gain access online and offline without handing over your personal data.
                    
                    <h4 style={{paddingTop: 25}}>Identity Verification</h4>
                    - Identity verification proving you are you so can gain access on and offline. Never again provide personal data.

                    <h4 style={{paddingTop: 25}}>Identity Protection</h4>
                    - Protect your identity from pay fraud, false charges, spam & phishing attacks.

                    <h4 style={{paddingTop: 25}}>Privacy & Security</h4>
                    - When you use VEEV your personal data is not used so third parties cannot sell your data and make money. 
                    
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="cta" id="connect" style={{paddingTop: 40, paddingBottom: 40}}>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_connect.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9" style={{ width: '100%', paddingLeft: '30px' }}>
                    <h className="title">Connect</h>
                    
                    <p className="title4" style={{paddingTop: 25}}>Connect Instantly</p>
                    - Scan and connect instantly. No more writing, paper or searching apps to exchange contacts.

                    <p className="title4" style={{paddingTop: 25}}>Pay and Send Money</p>
                    - Pay or send money from Instagram, Twitter, Snap, YouTube and social media.

                    <p className="title4" style={{paddingTop: 25}}>Identity Protection</p>
                    - Protects you identity against theft, fraud spam & phishing attacks.

                    <p className="title4" style={{paddingTop: 25}}>Security & Privacy</p>
                    - Interact without shaloop your private personal information. 

                    <h4 style={{paddingTop: 25}}>Build Your Brand</h4>
                    - All your social media and connections in one place.

                    <h4 style={{paddingTop: 25}}>Community</h4>
                    - Build your community of followers

                    <h4 style={{paddingTop: 25}}>Marketing & Promotions</h4>
                    - Promote and market your brand to your followers

                  </div> 
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cta" id="checkin" style={{paddingTop: 40, paddingBottom: 40}}>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_events_signup.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9" style={{ width: '100%', paddingLeft: '30px' }}>
                    <h className="title">Checkin</h>
                    
                    <p className="title4" style={{paddingTop: 25}}>Instant Access - Skip The Line</p>
                    - Quick scan and gain instant access at bars, clubs and events.

                    <p className="title4" style={{paddingTop: 25}}>Identity Verification</p>
                    - Prove you are "you' without giving your personal data at bars, clubs and events.

                    <p className="title4" style={{paddingTop: 25}}>Promotions</p>
                    - Receive promotions from bars, cafes, events, stores and more.
                    
                    <p className="title4" style={{paddingTop: 25}}>One Click Signup, No Forms & Passwords</p>
                    - No more creating accounts, filling forms, registeloop, signing up, downloading apps to attend events, enter bars and access websites.

                  </div> 
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cta" id="checkout" style={{paddingTop: 40, paddingBottom: 40}}>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_app_order.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9" style={{ width: '100%', paddingLeft: '30px' }}>
                    <h className="title">Checkout</h>
                    
                    <p className="title4" style={{paddingTop: 25}}>One Click Pay</p>
                    - No more creating accounts, filling forms, logging in, inputting your credit card, downloading apps to pay at bars, events, stores and websites.

                    <p className="title4" style={{paddingTop: 25}}>Eliminate Fraud & Spam</p>
                    - Eliminate pay fraud, false charges and charge backs paying at stores or online with VEEV Checkout.

                    <p className="title4" style={{paddingTop: 25}}>Promotions</p>
                    - Receive promotions from bars, cafes, events, stores and more.

                  </div> 
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="cta" id="crypto" style={{paddingTop: 40, paddingBottom: 40}}>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_nft.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9" style={{ width: '100%', paddingLeft: '30px' }}>
                    <h className="title">Crypto & NFT Rewards</h>
                    <br></br>
                    Earn crypto rewards for checkin, checkouts, likes, posts, comments and in-app actions. Claim NFTs for checkins, checkout or exchange for crypto rewards.

                    <h4 style={{paddingTop: 25}}>Eliminate Fraud & Spam</h4>
                    - Eliminate pay fraud, charges, spam & phishing attacks when paying at stores or online with VEEV Checkout.

                    <h4 style={{paddingTop: 25}}>Secure & Private</h4>
                    - Earn crypto rewards for checkin, checkouts, likes, posts, comments and in-app actions. Claim NFTs for checkins, checkout or exchange for crypto rewards.
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="text-center" style={{paddingTop: 30, paddingBottom: 40}} id="">
          <div className="container" style={{paddingTop: 30, paddingBottom: 0}}>
            <div className="col-lg-12 my-auto text-center"> 
              <div className="row">   
                <div className="col-lg-3">
                  <div className="device-mockup iphone6 portrait gold">
                    <div className="device">
                      <div className="screen">
                      <img src="https://staging.veev.app/img/veevid.png" className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="device-mockup iphone6 portrait gold">
                    <div className="device">
                      <div className="screen">
                      <img src="https://staging.veev.app/img/veev_events_signup.png" className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3"> 
                  <div className="device-mockup iphone6 portrait gold">
                    <div className="device">
                      <div className="screen">
                      <img src="https://staging.veev.app/img/veev_qr_activity1.png" className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </div>  
                <div className="col-lg-3">  
                  <div className="device-mockup iphone6 portrait gold">
                    <div className="device">
                      <div className="screen">
                      <img src="https://staging.veev.app/img/veev_app_receipt1.png" className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="text-center">
                <a className="badge-link" href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1380885775&mt=8"><br />
                <img className="wallet" src="https://staging.veev.app/img/app-store-badge.svg" style={{ marginTop: 20, height: 38 }} alt="VEEV Wallet" /></a>
              </div>		
            </div>
          </div>
        </section> */}

        {/* <section className="download bg-primary3 text-center" style={{paddingTop: 30, paddingBottom: 0}} id="checkin">
          <div className="container">   
            <div className="row">
              <div className="col-lg-12 content">  
                <p className="title">Privacy & Identity Protection</p>
              </div>
              <div className="col-lg-12 content">  
                <p className="title0white">$756,326,100,000</p>
              </div>
            </div>
            <div className="col-lg-12" style={{paddingTop: 0, paddingBottom: 0}}>
            <p className="title2white">Billion Made Selling Your Identity & Privacy</p>
              <div className="row"> 
                <div className="col-lg-4"> 
                  <p className="title4white">
                  Signup data, login & passwords<br/>
                  Social security number<br/>
                  Email address<br/>
                  Phone number
                  </p>
                </div>
                <div className="col-lg-4"> 
                  <p className="title4white">
                  Driver's license, race, dob information<br/>
                  Banking and income information<br/>  
                  Debit & credit card information<br/>
                  Home & employer address
                  </p>
                </div>
                <div className="col-lg-4"> 
                  <p className="title4white">
                  Analyzing mobile phone usage behavior<br/>
                  Viewing your photos, likes & shares<br/>
                  Parsing your text messages<br/>
                  Reading your emails
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12"> 
              <p className="title4white">Source: <a href="https://www.identitytheft.gov/#/Info-Lost-or-Stolen" target="_blank"> IdentityTheft.gov</a></p>
              <p className="title2white">never again hand over driver's license, credit card or personal data</p>
              </div>
            </div>  
          </div>
        </section> */}

        {/* <section className="cta text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
              <p className="title">One Click Verify</p>
                <div className="container-fluid">
                  <div className="row">   
                    <div className="col-lg-3 my-auto text-center">
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veevid.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <p>Checkin Verification</p>
                          Verified user checkin for bars, events, apartments
                    </div>
                    <div className="col-lg-2 dv-loadmoney">
                    </div>
                    <div className="col-lg-3 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veev_events_signup.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <p>Checkin Access</p>
                          Verified user access & payment for events, memberships, gyms
                    </div>
                    <div className="col-lg-1">
                    </div>
                    <div className="col-lg-3 text-center">
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veev_app_receipt1.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <p>Checkin Authentication</p>
                          Instant onboarding for websites & checkout online or at stores.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="cta" id="" style={{paddingTop: 40, paddingBottom: 40}}>
          <div className="">
            <div className="col-lg-12 my-auto text-center">
              <p className="title">One Click Signup, No Password Login & Checkout</p>
              <div className="row">   
                <div className="col-lg-6">   
                  <div className="section-heading text-center">
                  <img src="https://staging.veev.app/img/checkinsls.png" className="img-fluid" style={{paddingTop: 0, paddingBottom: 40}} alt="" />   
                  <h4>One Click Signup & No Passwords Login</h4>
                  Instantly sigu up to website and apps. No filling forms, creating accounts, inputting payment information or remembeloop passwords.
                  </div>
                </div>
                <div className="col-lg-6">   
                  <div className="section-heading text-center">
                  <img src="https://staging.veev.app/img/checkout_online.png" className="img-fluid" style={{paddingTop: 0, paddingBottom: 40}} alt="" />  
                  <h4>One Click Checkout</h4>
                  One click order and pay online using your mobile phone. No inputting card information, filling forms or creating accounts.
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="features-gray" id="" style={{paddingTop: 40, paddingBottom: 40}}>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}> 
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="https://staging.veev.app/img/veev_event_listings.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                  </div> 
                  <div className="col-lg-9" style={{ width: '100%', paddingLeft: '30px' }}>
                    <h className="title">Earn Crypto & NFTs</h>

                    <h4 style={{paddingTop: 25}}>Checkins & Checkouts</h4>
                    - Earn crypto rewards for checkin & checkouts

                    <h4 style={{paddingTop: 25}}>Likes, Posts, Comments etc</h4>
                    - Earn crypto rewards for likes, posts etc

                    <h4 style={{paddingTop: 25}}>NFTs</h4>
                    - Claim NFTs for checkins, checkout or exchange for crypto rewards<br />
                    
                  </div> 
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="text-center">
              <a className="badge-link" href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1380885775&mt=8"><br />
              <img className="wallet" src="https://staging.veev.app/img/app-store-badge.svg" style={{ marginTop: 20, height: 38 }} alt="VEEV Wallet" /></a>
            </div>		
          </div>
        </section> */}
      </div>
      </Styles>
      <FooterHome />
    </>
  );
};
export default Home;
