import styled from "styled-components";
import { Button } from "antd";

const SubmitButton = styled(Button)`
  min-width: 300px;
  min-height: 52px;
  text-transform: uppercase;
  border-radius: 52px;
  &.ant-btn-lg {
    border-radius: 52px;
  }
`

export  default SubmitButton
