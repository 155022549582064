import React, { useEffect, useMemo, useState } from "react";
import HeaderHome from "../../components/header/headerHomePage";
import Footer from '../../components/footer/footerPage'
import { Styles } from '../../layouts/style/homeStyle'

const Solutions = () => {
  const [slider, setSlider] = useState('')
  const sliders = useMemo(() => [
    "https://staging.veev.app/img/veev_qr_activity1.png",
    "https://staging.veev.app/img/veev_app_receipt1.png",
    "https://staging.veev.app/img/veev_qr_code1.png",
    // "../assets/img/veev_connect.png",
    //"https://staging.veev.app/img/veevid.png",
    //"https://staging.veev.app/img/veevnft1.png",
    // "https://staging.veev.app/img/veev_event_listings.png",
    //"https://staging.veev.app/img/veev_events_signup.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider(sliders[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders.length)
        index = 0
      setSlider(sliders[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders])

  const [slider2, setSlider2] = useState('')
  const sliders2 = useMemo(() => [
    "https://staging.veev.app/img/veev_qr_activity1.png",
    "https://staging.veev.app/img/veevid.png",
    "../assets/img/veev_connect.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider2(sliders2[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders2.length)
        index = 0
      setSlider2(sliders2[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders2])

  const [slider3, setSlider3] = useState('')
  const sliders3 = useMemo(() => [
    "https://staging.veev.app/img/veev_qr_activity1.png",
    "https://staging.veev.app/img/veevid.png",
    "../assets/img/veev_connect.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider3(sliders3[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders3.length)
        index = 0
      setSlider3(sliders3[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders3])

  const [slider4, setSlider4] = useState('')
  const sliders4 = useMemo(() => [
    "https://staging.veev.app/img/veev_sales.png",
    "https://staging.veev.app/img/veev_event_signups.png",
    "https://staging.veev.app/img/veev_marketing.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider4(sliders4[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders4.length)
        index = 0
      setSlider4(sliders4[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders4])

  const [slider5, setSlider5] = useState('')
  const sliders5 = useMemo(() => [
    "https://staging.veev.app/img/veev_checkin.png",
    "https://staging.veev.app/img/veev_event_signups.png",
    "https://staging.veev.app/img/veev_marketing.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider5(sliders5[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders5.length)
        index = 0
      setSlider5(sliders5[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders5])

  const [slider6, setSlider6] = useState('')
  const sliders6 = useMemo(() => [
    "https://staging.veev.app/img/veev_pos2.png",
    "https://staging.veev.app/img/veev_pos3.png",
    "https://staging.veev.app/img/veev_pos4.png",
    "https://staging.veev.app/img/veev_sales.png",
    "https://staging.veev.app/img/veev_pos5.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider6(sliders6[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders6.length)
        index = 0
      setSlider6(sliders6[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders6])

  return (
    <>
    <HeaderHome />
    <Styles>
      <div className="guide">

        <section className="cta text-center" style={{paddingTop: 100, paddingBottom: 50}} id="checkout">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <p className="title1">Checkin Solution For Every Enterprise</p>
                  {/* <p className="title3black text-center">Guide To Checkin Types</p><br></br><br></br>      */}
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3" style={{paddingTop: 33}}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/checkin_access.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                    <h4>ID Verification for Bars, Cafes, Apartments</h4>
                    </div>
                  </div>
                  <div className="col-lg-2 dv-loadmoney">
                  </div>
                  <div className="col-lg-3 dv-loadmoney" style={{paddingTop: 33}}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/checkin_event.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                    <h4>Signup, Registration & Payments for Events, Clubs, Venues</h4>
                    </div>
                  </div>
                  <div className="col-lg-1 dv-loadmoney">
                  </div>  
                  <div className="col-lg-3" style={{paddingTop: 33}}>   
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/checkin_connect.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                    <h4>CRM to Engage with Users, Fans & Followers</h4>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div>  
        </section>

      </div>
    </Styles>
    <Footer />
  </>  
  )
}
export default Solutions
